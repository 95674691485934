import { Inject, Injectable, Optional } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { ResponsesService } from '../../../../core/services/Responses.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { EnvironmentURL } from '../../../../core/services/Config';
import { JWTHandler } from '../../../../core/services/JWT.handler';

@Injectable()
export class ReportsService {
  protected basePath;
  public defaultHeaders: Headers = new Headers();

  constructor(
    protected http: Http,
    private responsesService: ResponsesService) {
    let urlProvider = new EnvironmentURL();
    this.basePath = urlProvider.GetDomainURL();
    
  }

  getReports(url, from_date, to_date, system_id?, game_id?){
    let path = this.basePath + '/statistics/' + url + '?from=' + from_date + '&to=' + to_date;
    if (system_id) {
      path = path + '&systemId=' + system_id;
    }
    if (game_id) {
      path = path + '&gameId=' + game_id;
    }
    let headers = new Headers(this.defaultHeaders.toJSON());
    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers
    });
    return this.http.request(path, requestOptions)
    .map(response => response.json());
  }

  getSystems(){
    const path = this.basePath + '/systems';
    let headers = new Headers(this.defaultHeaders.toJSON());
    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers
    });
    return this.http.request(path, requestOptions)
    .map(response => response.json());
  }

  getGames(){
    const path = this.basePath + '/games/catalog';
    let headers = new Headers(this.defaultHeaders.toJSON());
    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers
    });
    return this.http.request(path, requestOptions)
    .map(response => response.json());
  }
}
