import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { NewsFeed } from './../models/news-feed.model';
import { NewsFeedService } from './../services/news-feed.service';

@Component({
  selector: 'app-news-feed-delete',
  templateUrl: './news-feed-delete.component.html',
  styleUrls: ['./news-feed-delete.component.css']
})
export class NewsFeedDeleteComponent {
  public loading = false;

  constructor(
    private newsFeedService: NewsFeedService,
    public dialogRef: MatDialogRef<NewsFeedDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public newsFeed: NewsFeed
  ) {}

  public deleteNewsFeed(): void {
    this.loading = true;
    this.newsFeedService.deleteNewsFeed(this.newsFeed.id).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close({});
      },
      error => {
        this.loading = false;
      }
    );
  }
}
