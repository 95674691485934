import { Component, OnInit, Inject } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import * as Service from '../../../../../app/core/services/Service';
import * as Model from '../../../../../app/core/models/Model';

@Component({
  selector: 'app-applications-edit',
  templateUrl: './applications-edit.component.html',
  styleUrls: ['./applications-edit.component.css']
})

export class ApplicationsEditComponent implements OnInit {
  public application: Model.Application;
  public applicationBody: FormGroup;
  public validForm  = false;

  public loading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private applicationsService: Service.ApplicationsService,
    public dialogRef: MatDialogRef<ApplicationsEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.application = data;
    this.applicationBody = this.formBuilder.group({
      'name': [this.application.name, [
        Validators.required
      ]],
      'pathToLauncher': [this.application.pathToLauncher, [
        Validators.required
      ]],
      'pathToIcon': [this.application.pathToIcon, [
        Validators.required
      ]]
    });
  }

  public ngOnInit() {

  }

  public editApplication(): void {
    if(this.applicationBody.valid) {
      this.loading = !this.loading;
      this.applicationsService.putApplication(String(this.application.id), this.applicationBody.value).subscribe(
        data => {
          this.loading = !this.loading;
          this.dialogRef.close(data);
        },
        error => {
          this.loading = !this.loading;
        }
      );
    } else
      this.validForm = true;
  }
}
