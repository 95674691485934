import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ResponsesService } from '../../../../core/services/Responses.service';

import * as Model from '../../../../core/models/Model';
import * as Service from '../../../../core/services/Service';
import * as Module from '../Module';

@Component({
  selector: 'app-pc-master-key',
  templateUrl: './master-key.pc.component.html',
  styleUrls: ['./master-key.pc.component.scss']
})

export class PCMasterKeyComponent implements OnInit {

  actual_key = '';
  public masterKey: string;

  constructor(
    private timersService: Service.TimersService,
    public responsesService: ResponsesService
  ) {

  }

  public ngOnInit() {
    this.timersService.getMasterKey().subscribe(
      (data: Model.MasterKey) => {
        if (data) {
          this.masterKey = data.key;
          this.actual_key = data.key;
        }
      });
  }

  public updateKey(): void {
    this.timersService.putMasterKey({ key: this.actual_key, newKey: this.masterKey }).subscribe(
      data => {
        this.masterKey = data.key;
        this.actual_key = data.key;
      }
    );
  }
}
