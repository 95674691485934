import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../services/Reports.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { NotificationsService } from 'angular2-notifications';
@Component({
  selector: 'app-reports-main',
  templateUrl: './reports-main.component.html',
  styleUrls: ['./reports-main.component.scss']
})
export class ReportsMainComponent implements OnInit {
  searchForm: FormGroup;
  searchFormSubmitted = false;
  reportsList: any = [
    { name: 'Redenciones de productos de tiempo', tag: 'time_purchases' },
    { name: 'Horarios de mayor consumo', tag: 'purchase_count' },
    { name: 'Estación más jugada', tag: 'top_stations' },
    { name: 'Tiempo extra más requerido', tag: 'extra_time_purchases' },
    { name: 'Juegos más solicitados', tag: 'top_game_systems' },
    { name: 'Categorías más jugadas', tag: 'top_categories' },
    { name: 'Producto de tiempo más contratado', tag: 'top_time_purchases' },
    { name: 'Consolas más usadas', tag: 'top_systems' },
    { name: 'Movimiento de juegos', tag: 'games/by_system' },
    { name: 'Matches', tag: 'group_matches' }
  ];
  reports = [];
  reportType = '';
  systems = [];
  games = [];
  alreadySearched = false;
  one_year_ago = this.getOneYearAgoDate();
  yesterday = this.getYesterdayDate();
  csvExporter: Angular2Csv;
  exporterOptions: any = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    useBom: true,
    headers: []
  };
  searching = false;
  alert_options = {
    position: ['top', 'right'],
    timeOut: 5000,
    lastOnBottom: false
  }
  constructor(
    public formBuilder: FormBuilder,
    public reportsService: ReportsService,
    public notificationsService: NotificationsService) {}
  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      reportName: ['', [
        Validators.required
      ]],
      gameName: ['', [
      ]],
      system: ['', [
      ]],
      startDate: [{value: '', disabled: true}, [
        Validators.required
      ]],
      endDate: [{value: '', disabled: true}, [
        Validators.required
      ]]
    });
    this.reportsService.getGames().subscribe(
      response => {
        this.games = response;
      },
      error => {
        this.showErrorNotification(error.json().error.errors[0].message);
      }
    );
    this.reportsService.getSystems().subscribe(
      response => {
        this.systems = response;
      },
      error => {
        this.showErrorNotification(error.json().error.errors[0].message);
      }
    );
  }
  getYesterdayDate(){
    let date_aux = new Date();
    date_aux.setDate(date_aux.getDate() - 1);
    return date_aux;
  }
  shouldControlShowValidatorError(control_name, validator_name){
    return !this.isControlValid(control_name, validator_name) && this.shouldControlErrorBeVisible(control_name);
  }
  isControlValid(control_name, validator_name){
    return !this.searchForm.controls[control_name].hasError(validator_name);
  }
  shouldControlErrorBeVisible(control_name){
    return this.searchFormSubmitted || this.searchForm.controls[control_name].touched;
  }
  searchReports() {
    this.searchFormSubmitted = true;
    if (this.searchForm.valid) {
      this.searching = true;
      this.reportsService.getReports(this.searchForm.value.reportName, this.getTimeStamp(this.searchForm.value.startDate), this.getTimeStamp(this.searchForm.value.endDate), this.searchForm.value.system, this.searchForm.value.gameName).subscribe(
        response => {
          this.reportType = this.searchForm.value.reportName;
          this.reports = response;
          if (this.reportType === 'purchase_count' || this.reportType === 'top_stations' || this.reportType === 'extra_time_purchases' || this.reportType === 'top_game_systems' || this.reportType === 'top_categories' || this.reportType ===  'top_time_purchases' || this.reportType === 'top_systems') {
            this.sortReports();
          }
          this.alreadySearched = true;
          this.searching = false;
        },
        error => {
          this.showErrorNotification(error.json().error.errors[0].message);
          this.searching = false;
        }
      );
    }
  }
  sortReports(){
    this.reports.sort(function(a, b) {
      return b.usageCount - a.usageCount;
    });
  }
  getTimeStamp(date){
    let time_stamp = new Date(date).getTime() / 1000;
    return time_stamp;
  }
  shouldShowReportTable(report_name){
    return this.reports.length !== 0 && this.reportType === report_name;
  }
  getOneYearAgoDate(){
    let date_aux = new Date();
    date_aux.setFullYear((date_aux.getFullYear() - 1));
    return date_aux;
  }
  initCalendarsStates(){
    this.searchForm.controls.startDate.enable();
    this.searchForm.controls.endDate.enable();
  }
  exportDataToCSV() {
    var report_name = this.getActualReport();
    this.setCSVColumnHeaders();
    if (this.reportType === 'time_purchases') {
      let reports_aux = this.getTimePurchasesReportCSVArray();
      this.csvExporter = new Angular2Csv(reports_aux, report_name, this.exporterOptions);
    } else if (this.reportType === 'group_matches') {
      let reports_aux = this.getMatchesReportCSVArray();
      this.csvExporter = new Angular2Csv(reports_aux, report_name, this.exporterOptions);
    } else if (this.reportType === 'games/by_system') {
      let reports_aux = this.getGamesBySystemReportCSVArray();
      this.csvExporter = new Angular2Csv(reports_aux, report_name, this.exporterOptions);
    } else {
      this.csvExporter = new Angular2Csv(this.reports, report_name, this.exporterOptions);
    }
  }
  getActualReport() {
    for (var i = 0; i < this.reportsList.length; i++) {
      if (this.reportsList[i].tag === this.searchForm.value.reportName) {
        var time_aux = new Date();
        return this.reportsList[i].name + ' fecha ' + this.getDateFormat(Math.round(time_aux.getTime() / 1000)) + ' hora ' + this.getTimeFormat(Math.round(time_aux.getTime() / 1000));
      }
    }
    return '';
  }
  setCSVColumnHeaders() {
    switch (this.reportType) {
      case 'time_purchases':
        this.exporterOptions.headers = ['Concepto', 'Estación', 'Categoría', 'Fecha - Hora'];
        break;
      case 'purchase_count':
        this.exporterOptions.headers = ['Horario', 'Número de usos'];
        break;
      case 'top_stations':
        this.exporterOptions.headers = ['Nombre de la estación', 'Número de usos de la estación'];
        break;
      case 'extra_time_purchases':
        this.exporterOptions.headers = ['Tiempo adquirido', 'Categoría del producto', 'Usos'];
        break;
      case 'top_game_systems':
        this.exporterOptions.headers = ['Nombre del juego', 'Consola', 'Usos'];
        break;
      case 'top_categories':
        this.exporterOptions.headers = ['Categoría', 'Número de usos'];
        break;
      case 'top_time_purchases':
        this.exporterOptions.headers = ['Valor del producto de tiempo', 'Categoría', 'Usos'];
        break;
      case 'top_systems':
        this.exporterOptions.headers = ['Nombre de la consola', 'Número de usos'];
        break;
      case 'games/by_system':
        this.exporterOptions.headers = ['Nombre de juego', 'Consola', 'Stock', 'Disponibilidad', 'Estación', 'Asignó', 'Fecha y hora de asignación', 'Retiró', 'Fecha y hora de retiro'];
        break;
      case 'group_matches':
        this.exporterOptions.headers = ['Fecha y hora', 'Categorias', 'Tipo de evento', 'Minutos', 'Jugadores'];
        break;
      default:
        this.exporterOptions.headers = [];
    }
  }
  getTimePurchasesReportCSVArray(){
    let reports_aux = [];
    for (var i = 0; i < this.reports.length; i++) {
      reports_aux.push({
        timeProduct: this.reports[i].timeProduct,
        station: this.reports[i].station,
        category: this.reports[i].category,
        purchasedAt: this.getDateTimeFormat(this.reports[i].purchasedAt)
      });
    }
    return reports_aux;
  }
  getMatchesReportCSVArray(){
    let reports_aux = [];
    for (var i = 0; i < this.reports.length; i++) {
      for (var j = 0; j < this.reports[i].guestGroupMatches.breakdown.length; j++) {
        reports_aux.push({
          date: this.reports[i].date + ' ' + this.getTimeFormat(this.reports[i].guestGroupMatches.breakdown[j].createdAt),
          categories: this.getCategoriesStringList(this.reports[i].guestGroupMatches.breakdown[j].categories),
          match_type: 'Invitado',
          minutes: this.reports[i].guestGroupMatches.breakdown[j].minutes,
          players_quantity: this.reports[i].guestGroupMatches.breakdown[j].numberOfPlayers
        });
      }
    }
    for (var i = 0; i < this.reports.length; i++) {
      for (var j = 0; j < this.reports[i].playerGroupMatches.breakdown.length; j++) {
        reports_aux.push({
          date: this.reports[i].date + ' ' + this.getTimeFormat(this.reports[i].playerGroupMatches.breakdown[j].createdAt),
          categories: this.getCategoriesStringList(this.reports[i].playerGroupMatches.breakdown[j].categories),
          match_type: 'Registrado',
          minutes: this.reports[i].playerGroupMatches.breakdown[j].minutes,
          players_quantity: this.reports[i].playerGroupMatches.breakdown[j].numberOfPlayers
        });
      }
    }
    return reports_aux;
  }
  getGamesBySystemReportCSVArray(){
    let reports_aux = [];
    for (var  i = 0; i < this.reports.length; i++) {
      for (var  j = 0; j < this.reports[i].operations.length; j++) {
        reports_aux.push({
          name: this.reports[i].name,
          system_name: this.reports[i].system.name,
          stock: this.reports[i].stock,
          availableCopies: this.reports[i].availableCopies,
          stationName: this.reports[i].operations[j].stationName,
          assignedBy: this.reports[i].operations[j].assignedBy,
          assignedAt: this.getDateTimeFormat(this.reports[i].operations[j].assignedAt),
          removedBy: this.reports[i].operations[j].removedBy ? this.reports[i].operations[j].removedBy : 'Sistema',
          removedAt: this.getDateTimeFormat(this.reports[i].operations[j].removedAt)
        });
      }
    }
    return reports_aux;
  }
  getCategoriesStringList(categories){
    let categories_string = '';
    for (var i = 0; i < categories.length; i++) {
      categories_string = categories_string + categories[i];
      if (!(i === (categories.length - 1))) {
        categories_string = categories_string + ', '
      }
    }
    return categories_string;
  }
  getDateTimeFormat(time){
    return this.getDateFormat(time) + ' ' + this.getTimeFormat(time);
  }
  getTimeFormat(time){
    var date = new Date((+time) * 1000);
    var hours = date.getHours();
    var minutes = "0" + date.getMinutes();
    var formattedTime = hours + ':' + minutes.substr(-2);
    return formattedTime;
  }
  getDateFormat(time){
    var currentDate = new Date((+time) * 1000);
    var date = currentDate.getDate();
    var month = currentDate.getMonth();
    var year = currentDate.getFullYear();
    var dateString = date + "/" + (month + 1) + "/" + year;
    return dateString;
  }
  showErrorNotification(error_description){
    this.notificationsService['html'](this.customHTMLNotificationGenerator('error', 'Ha ocurrido un error', error_description), 'error',
      {
        showProgressBar: false,
        pauseOnHover: false,
        clickToClose: true
      }
    );
  }
  customHTMLNotificationGenerator(notificationType, title, body) {
    return '<section class="alert ' + notificationType + '">' +
      '<h1>' + title + '</h1>' +
      '<p>' + body + '</p>' +
      '<div class="close">CERRAR</div>' +
      '</section>';
  }
}