import { NgModule } from '@angular/core';
import { MatDialogModule, MatAutocompleteModule, MatIconModule, MatButtonModule, MatCardModule, MatInputModule, MatSelectModule, MatOptionModule, MatProgressSpinnerModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AuthenticationGuard } from '../../../core/services/authentication.guard';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import * as Module from './Module';

import * as Service from '../../../core/services/Service';
import { SimpleNotificationsModule } from '../../../../../node_modules/angular2-notifications';

@NgModule({
  declarations: [
    Module.AssistantCreationComponent,
    Module.AssistantDeactivationComponent,
    Module.AssistantEditComponent,
    Module.AssistantListComponent,
    Module.AssistantsRecoveryComponent
  ],
  entryComponents: [
    Module.AssistantCreationComponent,
    Module.AssistantDeactivationComponent,
    Module.AssistantEditComponent
  ],
  imports: [
    Ng2SmartTableModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    BrowserModule,
    FormsModule,
    SimpleNotificationsModule,
    ReactiveFormsModule,
    HttpModule,
    Module.AssistantRouting
  ],
  exports: [
    Module.AssistantCreationComponent,
    Module.AssistantDeactivationComponent,
    Module.AssistantEditComponent,
    Module.AssistantListComponent,
    Module.AssistantsRecoveryComponent
  ],
  providers: [
    AuthenticationGuard,
    Service.AssistantsService
  ]
})
export class AssistantsModule { }
