import { MatTableDataSource } from '@angular/material';
import { GameDeleteComponent } from './../game-delete/game-delete.component';
import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as Model from '../models/Model';
import { GameBySystemCreateComponent } from '../game-by-system-create/game-by-system-create.component';
import { GameEditComponent } from '../game-edit/game-edit.component';
import { GamesService } from '../services/games.service';


@Component({
  inputs: ['game', 'games', 'event'],
  selector: 'app-game-view',
  templateUrl: './game-view.component.html',
  styleUrls: ['./game-view.component.scss']
})
export class GameViewComponent implements OnInit {
  public displayedColumns = ['name', 'stock', 'isHighlight'];
  public game: Model.Game;
  public games: Model.Game[];
  public gamesChange: EventEmitter<Model.Game[]> = new EventEmitter();
  public localTime = null;

  @Input() public source: any;
  public dataSource;
  constructor(
    private gamesService: GamesService,
    private dialog: MatDialog
  ) { }

  public ngOnInit() { }

  public deleteGame(): void {
    const dialogRef = this.dialog.open(GameDeleteComponent, {
      data: this.game
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.games = this.games.filter((gameToFilter) => gameToFilter.id !== this.game.id);
          this.gamesChange.emit(this.games);
          this.closeMenu();
        }
      });
  }

  public editGame(game: Model.Game): void {
    const dialogRef = this.dialog.open(GameEditComponent, {
      data: game
    });
    dialogRef.afterClosed().subscribe(
      (data: Model.Game) => {
        if (data) {
          this.game = data;
          this.games.forEach(game => {
            if (game.id === this.game.id) {
              game = this.game;
              return;
            }
          })
          this.localTime = new Date().getTime();
          this.sendTime();
          this.games[this.games.findIndex(gameToFind => gameToFind.id === this.game.id)] = this.game;
          this.gamesChange.emit(this.games);
        }
      }
    );
  }

  sendTime(): void {
    this.gamesService.sendTime(this.localTime);
  }

  public addGameSystemsToGame(game: Model.Game): void {
    const dialogRef = this.dialog.open(GameBySystemCreateComponent, {
      data: game
    });
    dialogRef.afterClosed().subscribe((data: Model.Game) => {
      if (data) {
        let generalAvailability = 0;
        data.gameSystems.forEach(gameSystem => {
          generalAvailability += gameSystem.stock;
        });

        this.game = data;
        this.games[this.games.findIndex(gameToFind => game.id === this.game.id)] = data;
      }
    });
  }

  public closeMenu() {
    const table = document.querySelector('.tableGent');
    table.classList.remove('tableMin');
    const detail = document.querySelector('.detail');
    detail.classList.remove('visible');
    detail.classList.add('hidden');
  }
}
