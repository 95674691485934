import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthenticationGuard } from '../../../core/services/authentication.guard';

import * as Module from './Module';

const applicationRoutes: Routes = [
  { path: 'applications', component: Module.ApplicationsListComponent, canActivate: [AuthenticationGuard] }
];

@NgModule({
  imports: [
    RouterModule.forChild(applicationRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class ApplicationsRouting { }
