import { Inject, Injectable, Optional } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { ResponsesService } from './Responses.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { EnvironmentURL } from './Config';
import * as Model from '../models/Model';
import { JWTHandler } from './JWT.handler';

@Injectable()
export class TimeProductsService {
  protected basePath;
  public defaultHeaders: Headers = new Headers();
  

  constructor(
    private responsesService: ResponsesService,
    protected http: Http
  ) {
    let urlProvider = new EnvironmentURL();
    this.basePath = urlProvider.GetDomainURL();
    
  }

  public getTimeProducts(): Observable<Model.TimeProduct[]> {
    return this.getTimeProductsHttp()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public putTimeProduct(id: string, timeProductUpdate: Model.TimeProductUpdate): Observable<Model.TimeProduct> {
    return this.putTimeProductHttp(id, timeProductUpdate)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Producto de tiempo actualizado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  private getTimeProductsHttp(): Observable<Response> {
    const path = this.basePath + `/time_products`;
    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());


    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }


  private putTimeProductHttp(id: string, timeProductUpdate: Model.TimeProductUpdate): Observable<Response> {
    const path = this.basePath + '/time_products/' + id;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (id === null || id === undefined)
      throw new Error();

    if (timeProductUpdate === null || timeProductUpdate === undefined)
      throw new Error();
    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      body: timeProductUpdate == null ? '' : JSON.stringify(timeProductUpdate),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }
}
