import { Component, OnInit, Inject } from '@angular/core';
import 'rxjs/add/operator/switchMap';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LocalDataSource } from 'ng2-smart-table';

import { User, Location, LocationUpdate } from '../../../../core/models/Model';
import { LocationsService } from '../../../../core/services/Locations.service';
import { UsersService } from '../../../../core/services/Users.service';

@Component({
  selector: 'app-edit-manager',
  templateUrl: './edit-manager.location.component.html',
  styleUrls: ['./edit-manager.location.component.scss']
})

export class EditLocationManagerComponent implements OnInit {
  public currentUser: any;
  public location: Location;
  private locationUsers: User[];
  public loading: boolean = false;
  public source: LocalDataSource;
  public settings = {
    actions: false,
    columns: {
      name: {
        title: '',
        filter: false
      }
    }
  };

  constructor(
    private usersService: UsersService,
    private locationsService: LocationsService,
    public dialogRef: MatDialogRef<EditLocationManagerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public ngOnInit(): void {
    this.source = new LocalDataSource();
    this.location = this.data.location;
    this.GetLocationManagers();
  }

  private GetLocationManagers(): void {
    this.usersService.GetUsers(null, String(this.data.location.id)).subscribe(
      data => {
        this.locationUsers = data;
        for (let i = 0; i < this.locationUsers.length; i++) {
          this.source.add({ name: data[i].profile.name, userId: data[i].id }).then().catch();
        }
        this.source.refresh();
      },
      error => {

      }
    );
  }
  public onSelect(event: any) {
    this.currentUser = event.data;
  }

  public onSearch(query: string = '') {
    this.source.setFilter([
      {
        field: 'name',
        search: query
      }
    ], true);
  }

  private EditLocationManager(): void {
    this.loading = !this.loading;
    let updateLocation = {
      managerId: Number(this.currentUser.userId)
    } as LocationUpdate;
    this.locationsService.PutLocation(String(this.data.location.id), updateLocation).subscribe(
      data => {
        this.loading = !this.loading;
        this.dialogRef.close(data);
      },
      error => {
      }
    );
  }

  public SaveButton(): void {
    this.EditLocationManager();

  }
}
