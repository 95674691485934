import { NgModule } from '@angular/core';
import {
  MatDialogModule, MatIconModule, MatButtonModule,
  MatCardModule, MatInputModule, MatSelectModule,
  MatOptionModule, MatTableModule, MatPaginatorModule,
  MatDialogRef, MAT_DIALOG_DATA
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AuthenticationGuard } from '../../../core/services/authentication.guard';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import * as Module from './Module';
import * as Service from '../../../core/services/Service';

@NgModule({
  declarations: [
    Module.PCListComponent,
    Module.PCMasterKeyComponent,
    Module.PCActivationComponent,
    Module.PCDeactivationComponent
  ],
  entryComponents: [
    Module.PCActivationComponent,
    Module.PCDeactivationComponent
  ],
  imports: [
    Ng2SmartTableModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    Module.PCRouting
  ],
  exports: [
    Module.PCListComponent,
    Module.PCMasterKeyComponent,
    Module.PCActivationComponent,
    Module.PCDeactivationComponent
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    AuthenticationGuard,
    Service.TimersService
  ]
})
export class PCModule { }