import { Inject, Injectable, Optional } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { JWTHandler } from './JWT.handler';
import { ResponsesService } from './Responses.service';

import { EnvironmentURL, URLPath } from './Config';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import * as Model from '../models/Model';

@Injectable()
export class LocationsService {
  protected basePath;
  public defaultHeaders: Headers = new Headers();
  

  constructor(
    protected http: Http,
    private responsesService: ResponsesService
  ) {
    const urlProvider = new EnvironmentURL();
    this.basePath = URLPath;
    
  }

  public DeleteLocation(id: number): Observable<Model.Location> {
    return this.deleteLocationHttp(id)
      .map((response: Response) => {
        if (response.status === 204) {
          return undefined;
        } else {
          return response.json();
        }
      });
  }

  public GetLocationCode(code: string): Observable<Model.LocationAuth> {
    return this.getLocationCodeHttp(code)
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public GetLocations(name?: string, prefix?: string, city?: string, country?: string): Observable<Model.Location[]> {
    return this.getLocationsHttp(name, prefix, city, country)
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public PostLocations(locationBody: Model.LocationBody): Observable<Model.Location> {
    return this.postLocationsHttp(locationBody)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Sucursal creada.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public PutLocation(locationId: string, locationUpdate: Model.LocationUpdate): Observable<Model.Location> {
    return this.putLocationHttp(locationId, locationUpdate)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Sucursal actualizada.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.PostUserResponse(error));
      });
  }
  public ActivateLocation(locationId: string, locationUpdate: Model.LocationUpdate): Observable<Model.Location> {
    return this.putLocationHttp(locationId, locationUpdate)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Exito', 'Sucursal activada.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }
  private deleteLocationHttp(id: number): Observable<Response> {
    const path = this.basePath + `/locations`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (id === null || id === undefined)
      throw new Error();

    if (id !== undefined)
      queryParameters.set('id', <any>id);

    
    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Delete,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private getLocationCodeHttp(code: string): Observable<Response> {
    const path = this.basePath + '/locations/access_keys/' + code;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());
    if (code === null || code === undefined)
      throw new Error();

    
    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private getLocationsHttp(name?: string, prefix?: string, city?: string, country?: string): Observable<Response> {
    const path = this.basePath + `/locations`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (name) {
      queryParameters.set('name', name);
    }

    if (prefix) {
      queryParameters.set('prefix', prefix);
    }

    if (city) {
      queryParameters.set('city', city);
    }

    if (country) {
      queryParameters.set('country', country);
    }

    

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  validateExistence(value, value2) {
    value = (value != undefined || value != null) ? true : false;
    value2 = (value2 != undefined || value2 != null) ? true : false;
    return (value && value2);
  }

  private postLocationsHttp(locationBody: Model.LocationBody): Observable<Response> {
    const path = this.basePath + `/locations`;
    locationBody.phone1 = (this.validateExistence(locationBody.phone1, locationBody.lada1)) ? locationBody.lada1 + ' ' + locationBody.phone1 : '';
    locationBody.phone2 = (this.validateExistence(locationBody.phone1, locationBody.lada2)) ? locationBody.lada2 + ' ' + locationBody.phone2 : '';
    locationBody.phone3 = (this.validateExistence(locationBody.phone2, locationBody.lada3)) ? locationBody.lada3 + ' ' + locationBody.phone3 : '';

    if (locationBody.phone2 === ' ') {
      delete locationBody.phone2;
    }
    if (locationBody.phone3 === ' ') {
      delete locationBody.phone3;
    }

    locationBody.wsCinemaId = Number(locationBody.wsCinemaId);

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (locationBody === null || locationBody === undefined)
      throw new Error();

    headers.set('Content-Type', 'application/json');

    
    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: locationBody == null ? '' : JSON.stringify(locationBody),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private putLocationHttp(locationId: string, locationUpdate: Model.LocationUpdate): Observable<Response> {
    const path = this.basePath + `/locations/` + locationId;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (locationUpdate === null || locationUpdate === undefined)
      throw new Error();

    

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      body: locationUpdate == null ? '' : JSON.stringify(locationUpdate),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }
}
