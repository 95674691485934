import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';

import { Testimonial } from '../models/testimonial.model';
import { TestimonialsService } from '../services/testimonials.service';
import { TestimonialsCreateComponent } from '../testimonials-create/testimonials-create.component';
import { TestimonialsDeleteComponent } from '../testimonials-delete/testimonials-delete.component';
import { TestimonialsEditComponent } from 'app/AppModules/WebModules/TestimonialsModule/testimonials-edit/testimonials-edit.component';

@Component({
  selector: 'app-testimonials-list',
  templateUrl: './testimonials-list.component.html',
  styleUrls: ['./testimonials-list.component.scss']
})
export class TestimonialsListComponent implements OnInit {

  @ViewChild(MatPaginator) public paginator: MatPaginator;
  public localTime = null;

  public testimonialsList: MatTableDataSource<Testimonial>;
  public displayedColumns: string[] = ['picture', 'by', 'content', 'edit', 'delete'];
  public isTestimonialsLengthValid = false;

  private _testimonials: Testimonial[];
  public get testimonials(): Testimonial[] {
    return this._testimonials;
  }
  public set testimonials(value: Testimonial[]) {
    this.testimonialsService.getTestimonials().subscribe((data: Testimonial[]) => {
      this._testimonials = data;
      this.isTestimonialsLengthValid = (data.length >= 5) ? true : false;
      this.testimonialsList = new MatTableDataSource<Testimonial>(this._testimonials);
      this.testimonialsList.paginator = this.paginator;
    });
  }

  constructor(
    private testimonialsService: TestimonialsService,
    private dialog: MatDialog
  ) { }

  public ngOnInit() {
    this.testimonials = [];
  }

  public createTestimonial(): void {
    const dialogRef = this.dialog.open(TestimonialsCreateComponent, {
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((data: Testimonial) => {
      if (data) {
        this.testimonials.push(data);
        this.testimonialsList = new MatTableDataSource<Testimonial>(this.testimonials);
        this.testimonialsList.paginator = this.paginator;
      }
    });
  }

  public deleteTestimonial(testimonial: Testimonial): void {
    const dialogRef = this.dialog.open(TestimonialsDeleteComponent, {
      disableClose: true,
      data: testimonial
    });
    dialogRef.afterClosed().subscribe((data: Testimonial) => {
      if (data) {
        this.testimonials = this.testimonials.filter(
          testimonialElement => testimonial.id !== testimonialElement.id
        );
      }
    });
  }

  public editTestimonial(testimonialId: Testimonial): void {
    const dialogRef = this.dialog.open(TestimonialsEditComponent, {
      disableClose: true,
      data: testimonialId
    });
    dialogRef.afterClosed().subscribe((data: Testimonial) => {
      if (data) {
        let index = 0;
        this.testimonials.forEach(testimonial => {
          if (testimonial.id === testimonialId.id) {
            this.testimonials[index] = data;
          } 
          index++;
        });
        this.localTime = new Date().getTime();
        this.testimonialsList = new MatTableDataSource<Testimonial>(this.testimonials);  
        this.testimonialsList.paginator = this.paginator;  
      }
    });
  }

}
