import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthenticationGuard } from '../../../core/services/authentication.guard';

import { UserListComponent, UserRecoveryComponent, UserViewComponent, UserCreationComponent } from './Module';

const usersRoutes: Routes = [
  { path: 'users', component: UserListComponent, canActivate: [AuthenticationGuard] },
  { path: 'users/:token', component:  UserRecoveryComponent},
  { path: 'users/create', component: UserCreationComponent, canActivate: [AuthenticationGuard] },
  { path: 'user/:id', component: UserViewComponent, canActivate: [AuthenticationGuard] },
];

@NgModule({
  imports: [
    RouterModule.forChild(usersRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class UsersRouting { }
