import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-reports-time-purchases',
  templateUrl: './reports-time-purchases.component.html',
  styleUrls: ['./reports-time-purchases.component.scss']
})
export class ReportsTimePurchasesComponent {
  @Input() reports:any = [];
  page_size = 10;
  selected_page = 0;
  constructor() {}
  getDateTimeFormat(time){
    return this.getDateFormat(time) + ' - ' + this.getTimeFormat(time);
  }
  getTimeFormat(time){
    var date = new Date((+time) * 1000);
    var hours = date.getHours();
    var minutes = "0" + date.getMinutes();
    var seconds = "0" + date.getSeconds();
    var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    return formattedTime;
  }
  getDateFormat(time){
    var currentDate = new Date((+time) * 1000);
    var date = currentDate.getDate();
    var month = currentDate.getMonth();
    var year = currentDate.getFullYear();
    var dateString = date + "/" + (month + 1) + "/" + year;
    return dateString;
  }
  shouldShowReport(index){
    return (index >= (this.selected_page * this.page_size) && (index <= ((this.selected_page * this.page_size) + (this.page_size - 1))));
  }
  setSelectedPage(event){
    this.selected_page = event;
  }
}