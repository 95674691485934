import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-reports-extra-time-purchases',
  templateUrl: './reports-extra-time-purchases.component.html',
  styleUrls: ['./reports-extra-time-purchases.component.css']
})
export class ReportsExtraTimePurchasesComponent {
  @Input() reports: any = [];
  page_size = 10;
  selected_page = 0;
  constructor() { }
  shouldShowReport(index){
    return (index >= (this.selected_page * this.page_size) && (index <= ((this.selected_page * this.page_size) + (this.page_size - 1))));
  }
  setSelectedPage(event){
    this.selected_page = event;
  }
}