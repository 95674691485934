import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';

import * as Model from '../../../../core/models/Model';
import * as Service from '../../../../core/services/Service';
import * as Module from '../Module';

@Component({
  selector: 'app-locationInfo-body',
  templateUrl: './body.locationInfo.component.html',
  styleUrls: ['./body.locationInfo.component.scss']
})

export class LocationInfoBodyComponent implements OnInit {
  constructor() { }
  ngOnInit() {
  }
}