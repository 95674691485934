import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  MAT_DIALOG_DATA,
  MatButtonModule,
  MatCardModule,
  MatDialogRef,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatGridListModule,
  MatAutocompleteModule
} from '@angular/material';

import { LocationEditComponent } from './location-edit/location-edit.component';
import { LocationListComponent } from './location-list/location-list.component';
import { LocationViewComponent } from './location-view/location-view.component';
import { LocationWebService } from './services/location-web.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatInputModule,
    MatMenuModule,
    MatTabsModule,
    MatGridListModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatAutocompleteModule
  ],
  exports: [
    LocationEditComponent,
    LocationListComponent,
    LocationViewComponent
  ],
  declarations: [
    LocationEditComponent,
    LocationListComponent,
    LocationViewComponent
  ],
  entryComponents: [
    LocationEditComponent
  ],
  providers: [
    LocationWebService,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ]
})
export class LocationModule { }
