import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { CarouselService } from './../services/carousel.service';

@Component({
  selector: 'app-carousel-delete',
  templateUrl: './carousel-delete.component.html',
  styleUrls: ['./carousel-delete.component.css']
})
export class CarouselDeleteComponent {
  public loading = false;

  constructor(
    private carouselService: CarouselService,
    public dialogRef: MatDialogRef<CarouselDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) private pictureId: number
  ) {}

  public deletePicture(): void {
    this.loading = true;
    this.carouselService.deletePicture(this.pictureId).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close({});
      },
      error => {
        this.loading = false;
      }
    );
  }
}
