import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/map';

import { EnvironmentURL } from '../../../../core/services/Config';
import { JWTHandler, ResponsesService } from '../../../../core/services/Service';

import { Avatar } from '../models/Avatar.model';
import { AvatarBody } from '../models/AvatarBody.model';

@Injectable()
export class AvatarsService {
  protected basePath;
  public defaultHeaders: Headers = new Headers();

  constructor(
    protected http: Http,
    private responsesService: ResponsesService
  ) {
    let urlProvider = new EnvironmentURL();
    this.basePath = urlProvider.GetDomainURL();
  }

  public getAvatars(): Observable<Avatar[]> {
    return this.getAvatarsWithHttpInfo()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public postAvatar(avatar: AvatarBody): Observable<Avatar> {
    return this.postAvatarWithHttpInfo(avatar)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Avatar creado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public getAvatarsWithHttpInfo(): Observable<Response> {
    const path = this.basePath + `/avatars`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());


    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });
    return this.http.request(path, requestOptions);
  }

  private postAvatarWithHttpInfo(avatar: AvatarBody): Observable<Response> {
    const path = this.basePath + `/avatars`;

    const queryParameters = new URLSearchParams();
    let formData: FormData = new FormData();
    const headers = new Headers(this.defaultHeaders.toJSON());
    let formParams = new URLSearchParams();

    if (avatar === null || avatar === undefined)
      throw new Error();

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    formData.append('name', avatar.name);
    formData.append('file', avatar.file);


    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers
    });

    return this.http.post(path, formData, requestOptions);
  }
}
