import { environment } from './../../../../../../environments/environment';
import { TournamentBody } from './../models/tournamentBody.model';
import { Inject, Injectable, Optional } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { ResponsesService } from '../../../../../core/services/Responses.service';
import { EnvironmentURL } from '../../../../../core/services/Config';

import { Observable } from 'rxjs/Observable';
import { URLOnsite, URLPath } from '../../../../../core/services/Config';
import 'rxjs/add/operator/map';

import { System } from '../../../../../core/models/Model';
import { JWTHandler } from '../../../../../core/services/Service';
import * as Model from '../models/index';

@Injectable()
export class TournamentsService {
  protected offsitePath = URLPath;
  protected onsitePath = URLOnsite;
  public defaultHeaders: Headers = new Headers();

  constructor(protected http: Http, private responsesService: ResponsesService) {
    const urlProvider = new EnvironmentURL();
  }

  public getSystems(locationDomain: string): Observable<System[]> {
    return this.getSystemsWithHttpInfo(locationDomain)
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public getTournamentProducts(locationId?: number): Observable<Model.TournamentProduct[]> {
    return this.getTournamentProductsWithHttpInfo(locationId)
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public getTournaments(from?: number, to?: number): Observable<Model.Tournament[]> {
    return this.getTournamentsWithHttpInfo(from, to)
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public getTournamentTeamStatistics(tournamentId: number): Observable<any[]> {
    return this.getTournamentTeamStatisticsWithHttpInfo(tournamentId)
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public postTournament(tournamentBody: TournamentBody): Observable<Model.Tournament> {
    return this.postTournamentWithHttpInfo(tournamentBody)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Torneo creado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public putTournament(tournamentId: number, tournamentBody: Model.TournamentBody): Observable<Model.Tournament> {
    return this.putTournamentTemplateWithHttpInfo(tournamentId, tournamentBody)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Torneo actualizado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public publishTournament(tournamentId: number): Observable<Model.Tournament> {
    return this.publishTournamentWithHttpInfo(tournamentId)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Torneo publicado correctamente.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  private getTournamentProductsWithHttpInfo(locationId?: number): Observable<Response> {
    const path = this.offsitePath + `/tournament_products`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (locationId) {
      queryParameters.set('location_id', String(locationId));
    }

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private getTournamentsWithHttpInfo(from?: number, to?: number): Observable<Response> {
    const path = this.offsitePath + `/tournaments`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (from !== undefined) {
      queryParameters.set('from', <any>from);
    }
    if (to !== undefined) {
      queryParameters.set('to', <any>to);
    }

    queryParameters.set('include_teams', 'yes');

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private postTournamentWithHttpInfo(tournamentBody: TournamentBody): Observable<Response> {
    const path = this.offsitePath + `/tournaments`;

    const queryParameters = new URLSearchParams();
    const formData: FormData = new FormData();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (tournamentBody === null || tournamentBody === undefined) {
      throw new Error('Required parameter tournament was null or undefined when calling postTournamentBody.');
    }


    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    if (tournamentBody.name !== null || tournamentBody.name !== "")
      formData.append('name', tournamentBody.name);
    if (tournamentBody.banner !== null || tournamentBody.banner !== "")
      formData.append('banner', tournamentBody.banner);
    if (tournamentBody.cover !== null || tournamentBody.cover !== "")
      formData.append('cover', tournamentBody.cover);
    if (tournamentBody.maxTeams !== null || tournamentBody.maxTeams !== undefined)
      formData.append('maxTeams', String(tournamentBody.maxTeams));
    if (tournamentBody.minTeams !== null || tournamentBody.minTeams !== undefined)
      formData.append('minTeams', String(tournamentBody.minTeams));
    if (tournamentBody.rules !== null || tournamentBody.rules !== "")
      formData.append('rules', tournamentBody.rules);
    if (tournamentBody.scheduledAt !== null || tournamentBody.scheduledAt !== undefined) {
      const date = new Date(tournamentBody.scheduledAt).getTime();
      formData.append('scheduledAt', String(Math.trunc(date / 1000)));
    }
    if (tournamentBody.setup !== null || tournamentBody.setup !== "")
      formData.append('setup', tournamentBody.setup);
    if (tournamentBody.systemId !== null || tournamentBody.systemId !== undefined)
      formData.append('systemId', String(tournamentBody.systemId));
    if (tournamentBody.systemName !== null || tournamentBody.systemName !== "")
      formData.append('systemName', String(tournamentBody.systemName));
    if (tournamentBody.isAvailable !== null || tournamentBody.isAvailable !== undefined)
      formData.append('isAvailable', String(tournamentBody.isAvailable));
    if (tournamentBody.tournamentProductId !== null || tournamentBody.tournamentProductId !== undefined)
      formData.append('tournamentProductId', String(tournamentBody.tournamentProductId));
    if (tournamentBody.templateId !== null || tournamentBody.templateId !== undefined)
      formData.append('templateId', String(tournamentBody.templateId));
    if (tournamentBody.locationId !== null || tournamentBody.locationId !== undefined)
      formData.append('locationId', String(tournamentBody.locationId));

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers
    });

    return this.http.post(path, formData, requestOptions);
  }

  private putTournamentTemplateWithHttpInfo(tournamentId: number, tournamentBody: Model.TournamentBody): Observable<Response> {
    const path = this.offsitePath + `/tournaments/${tournamentId}`;

    const queryParameters = new URLSearchParams();
    const formData: FormData = new FormData();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    if (tournamentBody.name !== null || tournamentBody.name !== "")
      formData.append('name', tournamentBody.name);
    if (tournamentBody.banner !== null || tournamentBody.banner !== "")
      formData.append('banner', tournamentBody.banner);
    if (tournamentBody.cover !== null || tournamentBody.cover !== "")
      formData.append('cover', tournamentBody.cover);
    if (tournamentBody.maxTeams !== null || tournamentBody.maxTeams !== undefined)
      formData.append('maxTeams', String(tournamentBody.maxTeams));
    if (tournamentBody.minTeams !== null || tournamentBody.minTeams !== undefined)
      formData.append('minTeams', String(tournamentBody.minTeams));
    if (tournamentBody.rules !== null || tournamentBody.rules !== "")
      formData.append('rules', tournamentBody.rules);
    if (tournamentBody.scheduledAt !== null || tournamentBody.scheduledAt !== undefined) {
      const date = new Date(tournamentBody.scheduledAt).getTime();
      formData.append('scheduledAt', String(date));
    }
    if (tournamentBody.setup !== null || tournamentBody.setup !== "")
      formData.append('setup', tournamentBody.setup);

    if (tournamentBody.tournamentProductId !== null || tournamentBody.tournamentProductId !== undefined)
      formData.append('tournamentProductId', String(tournamentBody.tournamentProductId));

    formData.append('isAvailable', 'false');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers
    });

    return this.http.put(path, formData, requestOptions);
  }

  private publishTournamentWithHttpInfo(tournamentId: number): Observable<Response> {
    const path = this.offsitePath + `/tournaments/` + tournamentId;

    const queryParameters = new URLSearchParams();
    const formData: FormData = new FormData();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      body: name == null ? '' : JSON.stringify({ isAvailable: true }),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private getSystemsWithHttpInfo(locationDomain: string): Observable<Response> {
    const path = environment.prefix + locationDomain + `/systems`;
    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private getTournamentTeamStatisticsWithHttpInfo(tournamentId: number): Observable<Response> {
    const path = this.offsitePath + `/tournaments/${tournamentId}/teams_statistics`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }
}
