import { TournamentsCreateComponent } from './../tournaments-create/tournaments-create.component';
import { TournamentTemplate } from './../../../TournamentSiteModule/TournamentTemplatesModule/models/tournamentTemplate.model';
import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { LocationsService } from "./../../../../../core/services/Locations.service";
import { MatTableDataSource, MatDialog, MatPaginator } from '@angular/material';
import { TournamentsService } from './../services/tournaments.service';
import { TournamentTemplatesService } from "./../../../TournamentSiteModule/TournamentTemplatesModule/services/tournamentTemplates.service";
import { Tournament } from './../models/tournament.model';
import { Location } from "./../../../../../core/models/Location.model";
import { TournamentCreateComponent } from '../../../TournamentSiteModule/TournamentTemplatesModule/tournament-create/tournament-create.component';

@Component({
  selector: 'app-tournaments-list',
  templateUrl: './tournaments-list.component.html',
  styleUrls: ['./tournaments-list.component.css']
})
export class TournamentsListComponent implements OnInit {

  public displayedColumns: string[] = ['name', 'game', 'mode', 'location', 'isAvailable'];
  public tournamentList: MatTableDataSource<Tournament>;
  public selectedTournament: Tournament;
  public selected = false;

  @ViewChild(MatPaginator) public paginator: MatPaginator;

  private _locations: Location[];
  public get locations(): Location[] {
    return this._locations;
  }
  public set locations(locations: Location[]) {
    this.locationsService
      .GetLocations()
      .subscribe((data: Location[]) => (this._locations = data));
  }

  private _tournamentTemplates: TournamentTemplate[];
  public get tournamentTemplates(): TournamentTemplate[] {
    return this._tournamentTemplates;
  }
  public set tournamentTemplates(tournamentTemplate: TournamentTemplate[]) {
    this.tournamentTemplatesService
      .getTournamentTemplates()
      .subscribe((data: TournamentTemplate[]) => (this._tournamentTemplates = data));
  }

  private _tournaments: Tournament[];
  public get tournaments(): Tournament[] {
    return this._tournaments;
  }
  public set tournaments(tournaments: Tournament[]) {
    this.tournamentsService.getTournaments().subscribe(
      (data: Tournament[]) => {
        this._tournaments = data;
        this.tournamentList = new MatTableDataSource(data);
        this.tournamentList.paginator = this.paginator;
      });
  }

  constructor(
    private locationsService: LocationsService,
    private tournamentTemplatesService: TournamentTemplatesService,
    private tournamentsService: TournamentsService,
    public dialog: MatDialog
  ) { }

  public ngOnInit(): void {
    this.locations = [];
    this.tournamentTemplates = [];
    this.tournaments = [];
  }

  public createTournament(): void {
    const dialogRef = this.dialog.open(TournamentsCreateComponent, {
      data: {
        locations: this.locations,
        tournamentTemplates: this.tournamentTemplates
      }
    });
    dialogRef.afterClosed().subscribe(
      (data: Tournament) => {
        if (data) {
          this.tournaments.push(data);
          this.tournamentList = new MatTableDataSource<Tournament>(this.tournaments);
          this.tournamentList.paginator = this.paginator;
        }
      }
    );
  }

  public getLocation(locationId: number) : Location {
    return this.locations.find(location => location.id === locationId);
  }

  public onSelect(tournamentTemplate: Tournament) {
    this.selectedTournament = tournamentTemplate;
    this.selected = true;
    const table = document.querySelector('.tableGent');
    table.classList.add('tableMin');
    const detail = document.querySelector('.detail');
    detail.classList.add('visible');
    detail.classList.remove('hidden');
  }

}
