import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-tournaments-search',
  templateUrl: './tournaments-search.component.html',
  styleUrls: ['./tournaments-search.component.css']
})
export class TournamentsSearchComponent implements OnInit {
  public maxDate: Date = new Date();
  public minDate: Date = new Date();
  public searchForm: FormGroup;
  public dataSource: any;

  private parameters: any = {
    startDate: '',
    endDate: '',
    reportName: ''
  };
  constructor(
    private formBuilder: FormBuilder
  ) {
    this.searchForm = this.formBuilder.group({
      'startDate': new FormControl(),
      'endDate': new FormControl()
    });
  }

  public ngOnInit(): void {
    this.minDate.setMonth(-12);
    this.maxDate.setHours(-24);
  }

  public selectReportType(): void {
    this.parameters.endDate = (new Date(this.searchForm.value.endDate).getTime()) / 1000;
    this.parameters.startDate = (new Date(this.searchForm.value.startDate).getTime()) / 1000;
  }
}

