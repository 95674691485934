import { UserBody } from './../../../../core/models/UserBody.model';
import { Inject, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import * as Service from '../../../../core/services/Service';
import * as Model from '../../../../core/models/Model';

@Component({
  selector: 'app-user-edition',
  templateUrl: './edit.users.component.html',
  styleUrls: ['./edit.users.component.scss']
})

export class UserEditionComponent {
  public model: Model.UserUpdate = {} as Model.UserUpdate;
  public loading = false;
  public locations: Model.Location[];
  public user: Model.User;
  public userBody: FormGroup;
  public validForm = false;


  public roles = [
    { id: 'super', name: 'Super Administrator' },
    { id: 'onsite', name: 'Onsite Administrator' },
    { id: 'tournament', name: 'Tournament Administrator' },
    { id: 'web', name: 'Web Administrator' }
  ];

  constructor(
    private usersService: Service.UsersService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<UserEditionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.locations = this.data.locations;
    this.user = data.user;
    const userLocationId = (this.user.belongsTo) ? this.locations.find(location => location.name === this.user.belongsTo).id : '';

    this.userBody = this.formBuilder.group({
      'role': [this.user.role, []],
      'position': [this.user.position, []],
      'address': [this.user.profile.address, []],
      'phone': [this.user.profile.phone, [
        Validators.maxLength(15),
        Validators.minLength(10)
      ]],
      'locationId': [(this.user.belongsTo) ? userLocationId : '',
      []]
    });
  }

  public EditUser(): void {
    if (this.userBody.valid) {
      this.loading = !this.loading;
      const userBody: UserBody = this.userBody.value;
      if (userBody.role !== 'onsite') {
        delete userBody['locationId'];
        delete userBody['role'];
      }

      this.usersService.ModifyUser(String(this.user.id), this.userBody.value).subscribe(
        data => {
          this.loading = false;
          this.dialogRef.close(data);
        },
        error => {
          this.loading = false;
        });
    } else
      this.validForm = true;
  }
}
