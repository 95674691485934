import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-reports-paginator',
  templateUrl: './reports-paginator.component.html',
  styleUrls: ['./reports-paginator.component.scss']
})
export class ReportsPaginatorComponent implements OnInit{
  @Input() page_size = 0;
  @Input() reports_length = 0;
  @Output() page_selected_event = new EventEmitter();
  total_pages = [];
  selected_page = 0;
  ngOnInit() {
    for (var i = 0; i < Math.ceil(this.reports_length / this.page_size); i++) {
      this.total_pages.push(i);
    }
    this.selected_page = 0;
    this.emitSelectedPage();
  }
  setSelectedPage(page){
    this.selected_page = page;
    this.emitSelectedPage();
  }
  setFirstPageAsSelected(){
    this.setSelectedPage(0);
  }
  setPreviousPageAsSelected(){
    if (this.selected_page > 0) {
      let previous_page = this.selected_page - 1;
      this.setSelectedPage(previous_page);
    }
  }
  setLastPageAsSelected(){
    let last_page = this.total_pages.length - 1;
    this.setSelectedPage(last_page);
  }
  setNextPageAsSelected(){
    if (this.selected_page < (this.total_pages.length - 1)) {
      let next_page = this.selected_page + 1;
      this.setSelectedPage(next_page);
    }
  }
  emitSelectedPage(){
    this.page_selected_event.emit(this.selected_page);
  }
}