import { Component, Inject, OnInit } from '@angular/core';

import * as Model from '../../../../core/models/Model';
import * as Module from '../Module';
import * as Service from '../../../../core/services/Service';

@Component({
  selector: 'app-locationInfo-timetable',
  templateUrl: './timetable.locationInfo.component.html',
  styleUrls: ['./timetable.locationInfo.component.scss']
})

export class LocationInfoTimetable {
  public timetable = {
    opening: null,
    closing: null
  };
  public loading: boolean = false;
  constructor(
    private locationInfoService: Service.LocationInfoService
  ) { }

  public ShowTimeTable(): void {
    this.locationInfoService.getTimeTable().subscribe(
      data => {
        this.setOpeningTime(this.timeFormat(data.opening));
        this.setClosingTime(this.timeFormat(data.closing));
      },
      error => {

      }
    );
  }
  public ngOnInit() {
    this.ShowTimeTable();
  }

  private timeFormat(time: String): String {
    let timeToChar = Array.from(time);
    if (timeToChar.length === 4)
      return String(timeToChar[0] + timeToChar[1] + ':' + timeToChar[2] + timeToChar[3]);
  }

  setOpeningTime(time){
    //The date parameters doesn't matter
    this.timetable.opening = new Date(2018, 1, 1, +(time.substring(0, 2)), +(time.substring(3)));
  }

  setClosingTime(time){
    this.timetable.closing = new Date(2018, 1, 1, +(time.substring(0, 2)), +(time.substring(3)));
  }

  public updateTimeTable(): void {
    this.loading != this.loading;
    let time_table_aux = {
      opening: this.militarFormat(this.timetable.opening.getHours(),this.timetable.opening.getMinutes()),
      closing: this.militarFormat(this.timetable.closing.getHours(),this.timetable.closing.getMinutes())
    };
    this.locationInfoService.putTimeTable(time_table_aux).subscribe(
      data => {
        this.loading != this.loading;
      },
      error => {
      });
  }

  militarFormat(hours, minutes) {
   return ('0' + hours).slice(-2) +
    ('0' + minutes).slice(-2);
  }
}

