import { LocationsService } from './../../core/services/Locations.service';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { MatDialogModule, MatCardModule, MatInputModule, MatButtonModule, MatProgressSpinnerModule } from '@angular/material';

import { SimpleNotificationsModule } from 'angular2-notifications';
import { AuthenticationGuard } from '../../core/services/authentication.guard';
import { LoginComponent } from './login.component';
import { LoginRouting } from './login.routing';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { PasswordRecoveryAlertComponent } from './password-recovery-alert/password-recovery-alert.component';
import { AuthenticationService } from '../../core/services/Authentication.service';
import { AlertsService } from '../../core/services/Alerts.service';
import { ResponsesService } from '../../core/services/Responses.service';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    LoginComponent,
    PasswordRecoveryComponent,
    PasswordRecoveryAlertComponent
  ],
  entryComponents: [
    PasswordRecoveryComponent,
    PasswordRecoveryAlertComponent
  ],
  imports: [
    HttpModule,
    CommonModule,
    ReactiveFormsModule,
    LoginRouting,
    FormsModule,
    MatDialogModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    SimpleNotificationsModule
  ],
  exports: [
    LoginComponent,
    PasswordRecoveryComponent,
    PasswordRecoveryAlertComponent
  ],
  providers: [
    AuthenticationService,
    AlertsService,
    LocationsService,
    AuthenticationGuard,
    ResponsesService
  ]
})
export class LoginModule { }
