import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule, MatIconModule, MatButtonModule, MatCardModule, MatInputModule, MatSelectModule, MatOptionModule } from '@angular/material';
import { AuthenticationGuard } from '../../../core/services/authentication.guard';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CountriesService } from '../../../core/services/Service';
import { CountryListComponent } from './countries-list/list.countries.component';
import { CountriesRouting } from './countries.routing';


@NgModule({
  declarations: [
    CountryListComponent
  ],
  imports: [
    Ng2SmartTableModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    BrowserModule,
    FormsModule,
    HttpModule,
    CountriesRouting
  ],
  exports: [
    CountryListComponent
  ],
  providers: [
    AuthenticationGuard,
    CountriesService
  ]
})
export class CountriesModule { }
