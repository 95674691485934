import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as Model from '../../../../core/models/Model';
import * as Service from '../../../../core/services/Service';

@Component({
  selector: 'app-user-recovery',
  templateUrl: './user-recovery.component.html',
  styleUrls: ['./user-recovery.component.scss']
})
export class UserRecoveryComponent {
  private token: string;
  public loading = false;
  public isPasswordSetted = false;
  public doPasswordsMatch = false;

  public options: any = {
    position: ['top', 'right'],
    timeOut: 5000,
    lastOnBottom: true
  } as any;

  public _confirmPassword;
  public get confirmPassword(): string {
    return this._confirmPassword;
  }
  public set confirmPassword(value: string) {
    this._confirmPassword = value;
    this.doPasswordsMatch = (value === this.userPassword.password) ? true : false;
  }

  public userPassword: Model.UserPassword = {
    password: ''
  };

  password_type = 'password';
  password_confirmation_type = 'password';

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private usersService: Service.UsersService
  ) {
    this.route.queryParams.map(params => params.token).subscribe(
      params => {
        this.token = params;
      });
  }

  public setPassword(): void {
    this.loading = true;
    this.usersService.SetUserPassword(this.userPassword, this.token).subscribe(
      data => {
        this.loading = false;
        if (data) {
          this.isPasswordSetted = true;
        }
      },
      error => {
        this.loading = false;
      }
    );
  }

  switchPasswordType(){
    this.password_type = this.password_type === 'password' ? 'text' : 'password';
  }

  switchPasswordConfirmationType(){
    this.password_confirmation_type = this.password_confirmation_type === 'password' ? 'text' : 'password';
  }
}
