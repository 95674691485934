import { ValidationService } from './../../../../core/services/ValidationForms.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material';

import * as Model from '../models/Model';
import { GameBySystemCreateComponent } from '../game-by-system-create/game-by-system-create.component';
import { GamesService } from '../services/games.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';

@Component({
  selector: 'app-game-create',
  templateUrl: './game-create.component.html',
  styleUrls: ['./game-create.component.scss']
})
export class GameCreateComponent implements OnInit {
  public gameBody: FormGroup;
  public formArray: FormArray;
  public loading = false;
  public highlightM = false;
  public coverM = false;
  public screenshot0M = false;
  public screenshot1M = false;
  public screenshot2M = false;
  public validForm  = false;

  public imageLoaded: any = {
    cover: { loaded: false, file: '' },
    highlight: { loaded: false, file: '' },
    screenshot0: { loaded: false, file: '' },
    screenshot1: { loaded: false, file: '' },
    screenshot2: { loaded: false, file: '' }
  };

  private _tags: Model.GameTag[];
  public get tags(): Model.GameTag[] {
    return this._tags;
  }
  public set tags(value: Model.GameTag[]) {
    this.gamesService.getTags().subscribe(
      (data: Model.GameTag[]) =>
        (data) ? this._tags = data : this._tags = []
    );
  }

  constructor(
    private gamesService: GamesService,
    public dialogRef: MatDialogRef<GameCreateComponent>,
    public dialog: MatDialog
  ) {
    this.formArray = new FormArray([
      new FormGroup({
        name: new FormControl('', [
          Validators.required
        ]),
        maxPlayers: new FormControl('', [
          Validators.required
        ]),
        esrb: new FormControl('', [
          Validators.required
        ]),
        description: new FormControl('', [
          Validators.required
        ]),
        tagIds: new FormControl('', [
          Validators.required
        ])
      }),
      new FormGroup({
        cover: new FormControl('', [
          Validators.required,
          ValidationService.imageSizeValidator(512000)
        ]),
        highlight: new FormControl('', [
          Validators.required,
          ValidationService.imageSizeValidator(512000)
        ]),
        screenshot0: new FormControl('', [
          Validators.required,
          ValidationService.imageSizeValidator(512000)
        ]),
        screenshot1: new FormControl('', [
          Validators.required,
          ValidationService.imageSizeValidator(512000)
        ]),
        screenshot2: new FormControl('', [
          Validators.required,
          ValidationService.imageSizeValidator(512000)
        ])
      })
    ]);
    this.gameBody = new FormGroup({ formArray: this.formArray });
  }

  public get cover(): AbstractControl { return this.formArray.at(1).get('cover'); }
  public get highlight(): AbstractControl { return this.formArray.at(1).get('highlight'); }
  public get screenshot0(): AbstractControl { return this.formArray.at(1).get('screenshot0'); }
  public get screenshot1(): AbstractControl { return this.formArray.at(1).get('screenshot1'); }
  public get screenshot2(): AbstractControl { return this.formArray.at(1).get('screenshot2'); }

  public ngOnInit() {
    this.tags = [];
  }

  public createGame(): void {
    if(this.gameBody.valid) {
      this.loading = !this.loading;
      const game: any = Object.assign(this.formArray.value[0], this.formArray.value[1]);
  
      game.screenshots = [];
      game.screenshots.push(game.screenshot0);
      game.screenshots.push(game.screenshot1);
      game.screenshots.push(game.screenshot2);
  
      delete game['screenshot0'];
      delete game['screenshot1'];
      delete game['screenshot2'];
      
      this.gamesService.postGame(game, this.tags).subscribe(
        (gameData: Model.Game) => {
          this.loading = !this.loading;
          if (gameData !== null || gameData !== undefined) {
  
            const dialogRef = this.dialog.open(GameBySystemCreateComponent, {
              data: gameData
            });
  
            this.dialogRef.close(gameData);
  
            dialogRef.afterClosed().subscribe(
              (data) => {
                if (data) {
                }
              }
            );
  
          }
        },
        (error) => {
          this.loading = !this.loading;
        }
      );
    } else
      this.validForm = true;
  }

  public onAdd(tag) {
    if (tag.id === undefined) {
      this.gamesService.postTag(tag.value).subscribe(
        (data: Model.GameTag) => {
          this.tags.push(data);
        },
        (error: Error) => {
        });
    }
  }

  public onAdding(tagToAdd: any): Observable<any> {
    return Observable
      .of(tagToAdd)
      .filter(() => (tagToAdd !== undefined || tagToAdd !== null) ? true : false);
  }

  public handleImageLoad(property: string) {
    this.imageLoaded[property].loaded = true;
  }

  public handleInputChange(propertyName: string, event) {
    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this, propertyName);
    reader.readAsDataURL(file);
    this.formArray.at(1).get(propertyName).patchValue(file);
  }

  public _handleReaderLoaded(property, e) {
    const reader = e.target;
    this.imageLoaded[property].file = reader.result;

    const image = new Image();
    image.src = reader.result;
    image.onload = () => {
      switch(property){
        case 'highlight': {
        if(image.width === 1060 && image.height === 520) {
          this.highlightM = false;
          } else {
          this.highlightM = true;
          }
        break;
        }
        case 'cover': {
        if(image.width === 330 && image.height === 380) {
          this.coverM = false;
          } else {
          this.coverM = true;
          }
        break;
        }
        case 'screenshot0': {
        if(image.width === 1640 && image.height === 870) {
          this.screenshot0M = false;
          } else {
          this.screenshot0M = true;
          }
        break;
        }
        case 'screenshot1': {
        if(image.width === 1640 && image.height === 870) {
          this.screenshot1M = false;
          } else {
          this.screenshot1M = true;
          }
        break;
        }
        case 'screenshot2': {
        if(image.width === 1640 && image.height === 870) {
          this.screenshot2M = false;
          } else {
          this.screenshot2M = true;
          }
        break;
        }
      }
    }
  }


}
