import { Inject, Injectable, Optional } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { ResponsesService } from './Responses.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { EnvironmentURL } from './Config';
import * as Model from '../models/Model';
import { JWTHandler } from './JWT.handler';


@Injectable()
export class TimersService {
  protected basePath;
  public defaultHeaders: Headers = new Headers();
  

  constructor(
    private responsesService: ResponsesService,
    protected http: Http
  ) {
    let urlProvider = new EnvironmentURL();
    this.basePath = urlProvider.GetDomainURL();
    
  }

  public deleteTimer(subject: string): Observable<Model.Timer> {
    return this.deleteTimerWithHttpInfo(subject)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Timer creado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public getTimerMatch(extraHttpRequestParams?: any): Observable<Model.PlayerMatch> {
    return this.getTimerMatchWithHttpInfo(extraHttpRequestParams)
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public getTimerTokenStatus(extraHttpRequestParams?: any): Observable<Model.TimerToken> {
    return this.getTimerTokenStatusWithHttpInfo(extraHttpRequestParams)
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public getTimers(name?: string, subject?: string, isPc?: boolean, acceptancePending?: boolean): Observable<Model.Timer[]> {
    return this.getTimersWithHttpInfo(name, subject, isPc, acceptancePending)
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public postTimers(timerInfo: Model.TimerInfo): Observable<Model.TimerCredentials> {
    return this.postTimersWithHttpInfo(timerInfo)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Timer creado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public putTimer(subject: string): Observable<Model.Timer> {
    return this.putTimerWithHttpInfo(subject)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Timer activado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public getMasterKey(): Observable<Model.MasterKey> {
    return this.getMasterKeyWithHttpInfo()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public putMasterKey(masterKey) {
    return this.putMasterKeyWithHttpInfo(masterKey)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Master key actualizada.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public putMasterKeyWithHttpInfo(masterKey) {
    const path = this.basePath + `/master_key`;

    let headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    let requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      body: masterKey == null ? '' : JSON.stringify(masterKey),
    });

    return this.http.request(path, requestOptions);
  }

  private getMasterKeyWithHttpInfo(): Observable<Response> {
    const path = this.basePath + `/master_key`;

    let queryParameters = new URLSearchParams();
    let headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    let requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private deleteTimerWithHttpInfo(subject: string): Observable<Response> {
    const path = this.basePath + `/timers/${subject}`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (subject === null || subject === undefined)
      throw new Error();

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Delete,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private getTimerMatchWithHttpInfo(extraHttpRequestParams?: any): Observable<Response> {
    const path = this.basePath + `/timers/playable_match`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private getTimerTokenStatusWithHttpInfo(extraHttpRequestParams?: any): Observable<Response> {
    const path = this.basePath + `/timers/token_status`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());
    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private getTimersWithHttpInfo(name?: string, subject?: string, isPc?: boolean, acceptancePending?: boolean): Observable<Response> {
    const path = this.basePath + `/timers`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (name !== undefined)
      queryParameters.set('name', <any>name);

    if (subject !== undefined)
      queryParameters.set('subject', <any>subject);

    if (isPc !== undefined)
      queryParameters.set('is_pc', <any>isPc);

    if (acceptancePending !== undefined)
      queryParameters.set('acceptance_pending', <any>acceptancePending);

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private postTimersWithHttpInfo(timerInfo: Model.TimerInfo): Observable<Response> {
    const path = this.basePath + `/timers`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (timerInfo === null || timerInfo === undefined) {
      throw new Error();
    }
    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: timerInfo == null ? '' : JSON.stringify(timerInfo),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private putTimerWithHttpInfo(subject: string): Observable<Response> {
    const path = this.basePath + `/timers/${subject}`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (subject === null || subject === undefined) {
      throw new Error();
    }

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

}
