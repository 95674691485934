import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-reports-games-by-system',
  templateUrl: './reports-games-by-system.component.html',
  styleUrls: ['./reports-games-by-system.component.scss']
})
export class ReportsGamesBySystemComponent {
  @Input() reports: any = [];
  report_detail = null;
  page_size = 10;
  selected_page = 0;
  constructor() { }
  showReportDetail(report){
    this.report_detail = report;
  }
  hideReportDetail(){
    this.report_detail = null;
  }
  shouldShowReport(index){
    return (index >= (this.selected_page * this.page_size) && (index <= ((this.selected_page * this.page_size) + (this.page_size - 1))));
  }
  setSelectedPage(event){
    this.selected_page = event;
  }
}