import { NgModule } from '@angular/core';
import { MatDialogModule, MatMenuModule, MatAutocompleteModule, MatIconModule, MatButtonModule, MatCardModule, MatInputModule, MatSelectModule, MatOptionModule, MatProgressSpinnerModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AuthenticationGuard } from '../../../core/services/authentication.guard';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import * as Module from './Module';

import * as Service from '../../../core/services/Service';
import { SimpleNotificationsModule } from '../../../../../node_modules/angular2-notifications';

@NgModule({
  declarations: [
    Module.UserListComponent,
    Module.UserViewComponent,
    Module.UserCreationComponent,
    Module.UserEditionComponent,
    Module.UserDeactivationComponent,
    Module.UserRecoveryComponent
  ],
  entryComponents: [
    Module.UserEditionComponent,
    Module.UserCreationComponent,
    Module.UserDeactivationComponent

  ],
  imports: [
    Ng2SmartTableModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatAutocompleteModule,
    BrowserModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    Module.UsersRouting,
    SimpleNotificationsModule
  ],
  exports: [
    Module.UserListComponent,
    Module.UserViewComponent,
    Module.UserCreationComponent,
    Module.UserEditionComponent,
    Module.UserDeactivationComponent,
    Module.UserRecoveryComponent
  ],
  providers: [
    AuthenticationGuard,
    Service.UsersService,
    Service.LocationsService,
    Service.AlertsService
  ]
})
export class UsersModule { }
