import { TournamentGamesService } from './services/tournamentGames.service';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { MatTableModule } from '@angular/material/table';
import {
  MatDialogModule,
  MatDialogRef,
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatInputModule,
  MAT_DIALOG_DATA,
  MatPaginatorModule
} from '@angular/material';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TournamentGamesListComponent } from './tournament-games-list/tournament-games-list.component';
import { TournamentGamesCreateComponent } from './tournament-games-create/tournament-games-create.component';
import { TournamentGamesEditComponent } from './tournament-games-edit/tournament-games-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatTableModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    MatProgressSpinnerModule,
    MatPaginatorModule
  ],
  declarations: [TournamentGamesListComponent, TournamentGamesCreateComponent, TournamentGamesEditComponent],
  exports: [TournamentGamesListComponent, TournamentGamesCreateComponent, TournamentGamesEditComponent],
  entryComponents: [TournamentGamesCreateComponent, TournamentGamesEditComponent],
  providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }, { provide: MatDialogRef, useValue: {} }, TournamentGamesService]
})
export class TournamentGamesModule {}
