import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import * as Model from '../../../../core/models/Model';
import * as Service from '../../../../core/services/Service';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { UserCreationComponent } from '../Module';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-user-list',
  templateUrl: './list.users.component.html',
  styleUrls: ['./list.users.component.scss']
})
export class UserListComponent implements OnInit {
  public source: LocalDataSource;
  private users: Model.User[];
  public locations: Model.Location[];
  public currentUser: Model.User;
  private userId: number = 0;
  private createUser: boolean = false;
  private event: any;
  userList: Model.UserList[] = [];
  settings = {
    actions: false,
    columns: {
      name: {
        title: 'Usuario',
        filter: false
      },
      role: {
        title: 'Rol',
        filter: false
      },
      position: {
        title: 'Cargo',
        filter: false
      },
      location: {
        title: 'Sucursal',
        filter: false
      }
    }
  };
  roles = [
    { id: '', name: 'Todos los Roles' },
    { id: 'super', name: 'Super Administrator' },
    { id: 'onsite', name: 'Onsite Administrator' },
    { id: 'tournament', name: 'Tournament Administrator' }
  ];

  constructor(private userService: Service.UsersService, private locationsService: Service.LocationsService, private dialog: MatDialog) { }

  getUsers(): void {
    this.userService.GetUsers().subscribe(
      usersFromHttp => {
        this.users = usersFromHttp;
        for (let i = 0; i < usersFromHttp.length; i++) {
          this.userList.push({
            id: usersFromHttp[i].id,
            name: usersFromHttp[i].profile.name + ' ' + usersFromHttp[i].profile.lastName,
            role: usersFromHttp[i].role,
            position: usersFromHttp[i].position,
            location: usersFromHttp[i].belongsTo
          });
        }
        this.source.load(this.userList);
      },
      error => { }
    );
  }

  getLocations(): void {
    this.locationsService.GetLocations().subscribe(
      locationsFromHttp => {
        if (locationsFromHttp.length > 0) this.locations = locationsFromHttp;
      },
      error => { }
    );
  }

  ngOnInit(): void {
    this.source = new LocalDataSource();
    this.getLocations();
    this.getUsers();
    this.source.load(this.userList);
  }

  public onSearch(query: string = '') {
    this.source.setFilter(
      [
        {
          field: 'name',
          search: query
        }
      ],
      true
    );
  }

  public onSearchRole(query) {
    this.source.addFilter(
      {
        field: 'role',
        search: query
      },
      true
    );
  }
  public onSearchLocation(query) {
    this.source.addFilter(
      {
        field: 'location',
        search: query
      },
      true
    );
  }

  public onSelect(event) {
    this.event = event.data;
    this.currentUser = this.users.find(userToFind => userToFind.id === event.data.id);
    const table = document.querySelector('.tableGent');
    table.classList.add('tableMin');
    const detail = document.querySelector('.detail');
    detail.classList.add('visible');
    detail.classList.remove('hidden');
  }

  public CreateUser() {
    const dialogRef = this.dialog.open(UserCreationComponent, {
      data: {
        locations: this.locations
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.users.push(data);
        this.source
          .add({
            id: data.id,
            name: data.profile.name + ' ' + data.profile.lastName,
            location: data.belongsTo,
            position: data.position,
            role: data.role
          })
          .then()
          .catch();
        this.source.refresh();
      }
    });
  }
}
