import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';

import * as Service from '../../../core/services/Service';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent {
  public passwordRecoveryBody: FormGroup;
  public loading = false;

  constructor(
    public dialogRef: MatDialogRef<PasswordRecoveryComponent>,
    private formBuilder: FormBuilder,
    private authenticationService: Service.AuthenticationService,
  ) {
    this.passwordRecoveryBody = this.formBuilder.group({
      email: ['', [Service.ValidationService.emailValidator]]
    });
  }

  public PasswordRecoveryService(): void {
    this.loading = true;
    this.authenticationService.UserPasswordRecovery(this.passwordRecoveryBody.value).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close();
      },
      error => {
        this.loading = false;
      }
    );
  }
}
