import { ProductListComponent } from './products-list/list.products.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthenticationGuard } from '../../../core/services/authentication.guard';

const timeProductsRoute: Routes = [
  { path: 'products', component: ProductListComponent, canActivate: [AuthenticationGuard] },
];

@NgModule({
  imports: [
    RouterModule.forChild(timeProductsRoute)
  ],
  exports: [
    RouterModule
  ]
})

export class ProductsRouting { }
