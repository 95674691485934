import { AuthAccessURL } from './../../core/models/AuthAccessURL.model';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { Credentials } from '../../core/models/Model';
import { AlertsService } from '../../core/services/Alerts.service';
import { AuthenticationService } from '../../core/services/Authentication.service';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { PasswordRecoveryAlertComponent } from './password-recovery-alert/password-recovery-alert.component';
import { LocationsService } from '../../core/services/Service';

import { Observable } from 'rxjs/Observable';
import { ResponsesService } from '../../core/services/Responses.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public model: any = {};
  public loading = false;
  public returnUrl: string;

  public options = {
    position: ['top', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    theClass: 'alert'
  };

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private locationService: LocationsService,
    private alertService: AlertsService,
    private router: Router,
    public dialog: MatDialog,
    public responsesService: ResponsesService
  ) { }

  public ngOnInit() {
    if (localStorage.getItem('user')) {
      this.authenticationService.UserLogout().subscribe(
        data => { },
        error => {
          this.alertService.error(error);
        }
      );
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  public loginButtonAction() {
    this.loading = true;

    localStorage.clear();

    const userData: Credentials = {
      password: this.model.password,
      email: this.model.email
    } as Credentials;

    let validatedCredentials: any = {} as any;

    this.authenticationService.UserLogin(userData).subscribe(
      data => {
        validatedCredentials = data ? data : {};
        this.loading = false;

        const authorization: AuthAccessURL = {
          userAuthorization: validatedCredentials.role,
          locationName: '',
          locationId: null,
          locationDomain: ''
        } as AuthAccessURL;

        if (validatedCredentials.belongsTo) {
          let userLocation: any;
          this.locationService.GetLocations(validatedCredentials.belongsTo).subscribe(
            data => {
              userLocation = data && data.length > 0 ? data[0] : {}
              authorization.locationName = userLocation.name;
              authorization.locationId = userLocation.id;
              authorization.locationDomain = userLocation.domain;
              localStorage.setItem('authorization', JSON.stringify(authorization));
              this.router.navigate(['/']);
            }
          );
        } else {
          localStorage.setItem('authorization', JSON.stringify(authorization));
          this.router.navigate(['/']);
        }
      },
      error => {
        this.loading = false;
        if (JSON.parse(error._body).error.message === 'En proceso de recuperación') {
          const dialogRef = this.dialog.open(PasswordRecoveryAlertComponent);
          dialogRef.componentInstance.email = this.model.email;
        }else{
          Observable.throw(this.responsesService.PostUserResponse(error, 'Conflicto', JSON.parse(error._body).error.message))
        }
      }
    );
  }

  public passwordRecovery(): void {
    this.dialog.open(PasswordRecoveryComponent, {
      disableClose: true
    });
  }
}
