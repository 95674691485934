import { Component, OnInit } from '@angular/core';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { MatDialog, MatDialogRef } from '@angular/material';

import * as Model from '../../../../core/models/Model';
import * as Service from '../../../../core/services/Service';
import * as Module from '../Module';

@Component({
  selector: 'app-applications-list',
  templateUrl: './applications-list.component.html',
  styleUrls: ['./applications-list.component.css']
})

export class ApplicationsListComponent implements OnInit {
  public source: LocalDataSource;
  private applications: Model.Application[];
  private isLoadingData: boolean = false;
  private event: any;
  public selected: boolean = false;
  private currentApplication: Model.Application;
  public settings: any = {
    actions: false,
    columns: {
      name: {
        title: 'Nombre',
        filter: false
      },
      path: {
        title: 'Ruta',
        filter: false
      },
      icon: {
        title: 'Ícono',
        filter: false
      }
    }
  }

  constructor(
    private applicationsService: Service.ApplicationsService,
    private dialog: MatDialog
  ) {
    this.source = new LocalDataSource();
  }

  public ngOnInit() {
    this.getAvaliableApplications();
  }

  private getAvaliableApplications(): void {
    this.isLoadingData = true;
    this.source.empty().then().catch();

    this.applicationsService.getApplications().subscribe(
      data => {
        this.applications = data;
        for (let i = 0; i < data.length; i++) {
          this.source.add({
            applicationId: data[i].id,
            name: data[i].name,
            path: data[i].pathToLauncher,
            icon: data[i].pathToIcon
          }).then().catch();
        }
        this.source.refresh();
        this.isLoadingData = true;
      },
      error => {

      });
  }

  public createApplication(): void {
    const dialogRef = this.dialog.open(Module.ApplicationsCreationComponent, {
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.applications.push(data);
          this.source.add({
            applicationId: data.id,
            name: data.name,
            path: data.pathToLauncher,
            icon: data.pathToIcon
          });
          this.source.refresh();
        }
      });
  }

  public onSearch(query) {
    this.source.setFilter([
      {
        field: 'name',
        search: query
      }
    ], true);
  }

  public onSelect(event) {
    this.event = event.data;
    this.selected = true;
    this.currentApplication = this.applications.find(application => application.id === event.data.applicationId);
    const table = document.querySelector('.tableGent');
    table.classList.add('tableMin');
    const detail = document.querySelector('.detail');
    detail.classList.add('visible');
    detail.classList.remove('hidden');
  }

}
