import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from '../../../core/services/authentication.guard';
import { CountryListComponent } from './countries-list/list.countries.component';

const countriesRoutes: Routes = [
  { path: 'countries', component: CountryListComponent, canActivate: [AuthenticationGuard] }
    ];

@NgModule({
  imports: [
    RouterModule.forChild(countriesRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class CountriesRouting { }
