import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { MatDialogModule, MatProgressSpinnerModule, MatMenuModule, MatAutocompleteModule, MatIconModule, MatButtonModule, MatCardModule, MatInputModule, MatSelectModule, MatOptionModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AuthenticationGuard } from '../../../core/services/authentication.guard';

import { LocationActivationComponent } from './location-activate/activate.location.component';
import { LocationCreationComponent } from './location-create/create.location.component';
import { LocationDeactivationComponent } from './location-deactivate/deactivate.location.component';
import { EditLocationManagerComponent } from './location-edit-manager/edit-manager.location.component';
import { LocationEditionComponent } from './location-edit/edit.location.component';
import { LocationListComponent } from './location-list/list.location.component';
import { LocationViewComponent } from './location-view/view.location.component';
import { LocationsRouting } from './locations.routing';

import { CountriesService } from '../../../core/services/Countries.service';
import { LocationsService } from '../../../core/services/Locations.service';
import { UsersService } from '../../../core/services/Users.service';

@NgModule({
  declarations: [
    LocationListComponent,
    LocationViewComponent,
    LocationCreationComponent,
    LocationEditionComponent,
    EditLocationManagerComponent,
    LocationDeactivationComponent,
    LocationActivationComponent
  ],
  entryComponents: [
    LocationEditionComponent,
    EditLocationManagerComponent,
    LocationDeactivationComponent,
    LocationActivationComponent
  ],
  imports: [
    MatAutocompleteModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    Ng2SmartTableModule,
    LocationsRouting,
    MatDialogModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule
  ],
  exports: [
    LocationListComponent,
    LocationViewComponent,
    LocationCreationComponent,
    LocationEditionComponent,
    EditLocationManagerComponent,
    LocationDeactivationComponent,
    LocationActivationComponent
  ],
  providers: [
    LocationsService,
    CountriesService,
    UsersService,
    AuthenticationGuard
  ]
})
export class LocationsModule { }
