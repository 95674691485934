import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { MatDialog } from '@angular/material';

import * as Model from '../../../../core/models/Model';
import * as Module from '../Module';

@Component({
  inputs: ['application', 'applications', 'source', 'event'],
  selector: 'app-applications-view',
  templateUrl: './applications-view.component.html',
  styleUrls: ['./applications-view.component.css']
})

export class ApplicationsViewComponent implements OnInit {
  public application: Model.Application;
  private applications: Model.Application[];
  private event: any;
  private applicationMenu: boolean = false;
  public source: LocalDataSource;

  constructor(
    private dialog: MatDialog

  ) { }

  public editApplication(): void {
    const dialogRef = this.dialog.open(Module.ApplicationsEditComponent, {
      data: this.application
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.application = data;
          this.applications[this.applications.findIndex((application) => application.id === data.id)] = data;
          this.source.update(this.event, {
            name: data.name,
            path: data.pathToLauncher,
            icon: data.pathToIcon
          }).then().catch();
        }
      },
      error => {

      });
  }

  public deleteApplication(): void {
    const dialogRef = this.dialog.open(Module.ApplicationsDeleteComponent, {
      data: this.application
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.applications = this.applications.filter((application) => application.id !== this.application.id);
          this.source.remove(this.event).catch();
          this.source.refresh();
          this.closeMenu();
        }
      },
      error => {

      }
    );
  }

  public closeMenu(): void {
    this.applicationMenu = !this.applicationMenu;
    const table = document.querySelector('.tableGent');
    table.classList.remove('tableMin');
    const detail = document.querySelector('.detail');
    detail.classList.remove('visible');
    detail.classList.add('hidden');
  }

  public ngOnInit() {

  }

}
