import { Inject, Injectable, Optional } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { ResponsesService } from '../../../../../core/services/Responses.service';
import { EnvironmentURL } from '../../../../../core/services/Config';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { URLPath } from '../../../../../core/services/Config';

import * as Model from '../models/index';
import { JWTHandler } from '../../../../../core/services/Service';

@Injectable()
export class TournamentModesService {
  protected offsitePath = URLPath;

  public defaultHeaders: Headers = new Headers();
  

  constructor(protected http: Http, private responsesService: ResponsesService) {
    const urlProvider = new EnvironmentURL();
    
  }

  public getTournamentModes(): Observable<Model.Mode[]> {
    return this.getTournamentModesWithHttpInfo()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public postTournamentMode(modeBody: Model.ModeBody): Observable<Model.Mode> {
    return this.postTournamentModeWithHttpInfo(modeBody)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Modo de torneo registrado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public putTournamentMode(mode: Model.Mode): Observable<Model.Mode> {
    return this.putTournamentModeWithHttpInfo(mode)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Modo de torneo actualizado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  private getTournamentModesWithHttpInfo(): Observable<Response> {
    const path = this.offsitePath + `/modes`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  public postTournamentModeWithHttpInfo(modeBody: Model.ModeBody): Observable<Response> {
    const path = this.offsitePath + `/modes`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (modeBody === null || modeBody === undefined) {
      throw new Error('Required parameter mode was null or undefined when calling postTournamentBody.');
    }

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: name == null ? '' : JSON.stringify(modeBody),
      search: queryParameters
    });
    return this.http.request(path, requestOptions);
  }

  private putTournamentModeWithHttpInfo(mode: Model.Mode): Observable<Response> {
    const path = this.offsitePath + `/modes/` + mode.id;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (mode === null || mode === undefined) {
      throw new Error('Required parameter mode was null or undefined when calling postTournamentBody.');
    }

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      body: name == null ? '' : JSON.stringify({ name: mode.name }),
      search: queryParameters
    });
    return this.http.request(path, requestOptions);
  }
}
