import { LocalDataSource } from 'ng2-smart-table';
import { Component, Inject, OnInit } from '@angular/core';
import { MatStepper, MatStepperNext } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormArray, FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import * as Model from '../../models/index';
import { TournamentTemplatesService } from '../../services/tournamentTemplates.service';

@Component({
  selector: 'app-tournament-tie-breaker-create',
  templateUrl: './tournament-tie-breaker-create.component.html',
  styleUrls: ['./tournament-tie-breaker-create.component.css']
})
export class TournamentTieBreakerCreateComponent {
  public message = false;
  public tieBreakerBody: FormGroup;
  public loading = false;
  public validForm = false;

  constructor(
    private formBuilder: FormBuilder,
    private tournamentsSiteSerive: TournamentTemplatesService ,
    public dialogRef: MatDialogRef<TournamentTieBreakerCreateComponent>
  ) {
    this.tieBreakerBody = formBuilder.group({
      name: ['', [Validators.required]],
      isPercentage: ['', [Validators.required]],
      maxValue: ['', []]
    });
  }

  public createTieBreaker(): void {
    if(this.tieBreakerBody.valid){
      const tieBreakerBody: Model.CriteriaBody = this.tieBreakerBody.value;
  
      if (tieBreakerBody.isPercentage) {
        delete tieBreakerBody['maxValue'];
      }
      
      if(!tieBreakerBody.isPercentage && tieBreakerBody.maxValue === '')
        this.message = true;
      else {
        this.message = false;
        this.loading = !this.loading;
        this.tournamentsSiteSerive.postCriteria(tieBreakerBody).subscribe(
          (data: Model.Criteria) => {
            this.loading = !this.loading;
            this.dialogRef.close(data);
          },
          (error: Error) => {
            this.loading = !this.loading;
          }
        ); 
      }
    } else
      this.validForm = true;
  }
}
