import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { RechargesService } from './../services/Recharges.service';

import { Recharge } from './../models/Recharge.model';

@Component({
  selector: 'app-recharges-list',
  templateUrl: './recharges-list.component.html',
  styleUrls: ['./recharges-list.component.css']
})
export class RechargesListComponent implements OnInit {
  public source: LocalDataSource;
  public isLoadingRecharges = false;
  private recharges: Recharge[];
  public settings = {
    mode: 'external',
    actions: {
      add: false,
      delete: false,
      edit: false,
      position: 'right',
      columnTitle: ''
    },
    columns: {
      name: {
        title: 'Ares',
        filter: false
      },
      price: {
        title: 'Costo',
        filter: false
      }
    }
  };

  constructor(private rechargesService: RechargesService) { }

  public ngOnInit() {
    this.source = new LocalDataSource();
    this.getRecharges();
  }

  public getRecharges(): void {
    this.isLoadingRecharges = true;
    this.recharges = null;
    this.rechargesService.getRechargeProducts().subscribe(
      data => {
        this.recharges = data;
        for (let recharge = 0; recharge < data.length; recharge++) {
          for (let rechargeCost = 0; rechargeCost < data[recharge].cost.length; rechargeCost++) {
            this.source
              .add({
                rechargeId: data[recharge].id,
                rechargeCostId: data[recharge].cost[rechargeCost].id,
                name: data[recharge].coins,
                type: 'Recarga',
                country: data[recharge].cost[rechargeCost].country,
                price: data[recharge].cost[rechargeCost].amountCents / 100 + ' ' + data[recharge].cost[rechargeCost].isoCode,
                value: data[recharge].coins
              })
              .catch();
          }
        }
        this.source.refresh();
        this.isLoadingRecharges = false;
      },
      error => { }
    );
  }
}
