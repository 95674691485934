import { Component, Inject } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PhoneCodes } from '../../../../core/files/PhoneCodes';

import * as Service from '../../../../core/services/Service';

@Component({
  selector: 'app-assistant-creation',
  templateUrl: './creation.assistants.component.html',
  styleUrls: ['./creation.assistants.component.scss']
})

export class AssistantCreationComponent {
  public source: LocalDataSource;
  public loading = false;
  public validForm  = false;
  public assistantBody: FormGroup;
  private phoneCodes = PhoneCodes;
  public filteredCodes: any;
  public codeCtrl: FormControl;

  constructor(
    private assistantsService: Service.AssistantsService,
    public dialogRef: MatDialogRef<AssistantCreationComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.assistantBody = new FormGroup({
      'name': new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(2)
      ]),
      'lastName': new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(2)
      ]),
      'username': new FormControl('', [
        Validators.required,
        Validators.maxLength(25),
        Validators.minLength(4)
      ]),
      'position': new FormControl('', [
        Validators.required
      ]),
      'email': new FormControl('', [
        Validators.required,
        Service.ValidationService.emailValidator
      ]),
      'phone': new FormControl('', [
        Validators.required,
        Validators.maxLength(13),
        Validators.minLength(10)
      ])
    });
    this.codeCtrl = new FormControl();
    this.filteredCodes = this.codeCtrl.valueChanges
      .startWith(null)
      .map((country: any) => {
        return this.filterCodes(country);
      });
  }

  public CreateAssistant(): void {
    if (this.assistantBody.valid) {
      this.loading = !this.loading;
      this.assistantsService.postAssistant(this.assistantBody.value, this.codeCtrl.value).subscribe(
        data => {
          this.dialogRef.close(data);
          this.loading = !this.loading;
        },
        error => {

          this.loading = !this.loading;
        });
    } else 
      this.validForm = true;
  }

  public filterCodes(countryName: string): any {
    if (countryName != null || countryName != undefined) {
      return this.phoneCodes
        .filter((country: any) => {
          return country.name.toLowerCase().indexOf(countryName.toLowerCase()) === 0;
        });
    } else {
      return this.phoneCodes;
    }
  }
}
