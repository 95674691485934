import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import * as Model from '../../../../core/models/Model';
import * as Module from '../Module';
import * as Service from '../../../../core/services/Service';
import { PhoneCodes } from '../../../../core/files/PhoneCodes';

@Component({
  selector: 'app-assistant-edit',
  templateUrl: './edit.assistants.component.html',
  styleUrls: ['./edit.assistants.component.scss']
})

export class AssistantEditComponent {
  private model: Model.AssistantUpdate = <Model.AssistantUpdate>{};
  private assistant: any;
  public assistantBody: FormGroup;
  private phoneCodes = PhoneCodes;
  public filteredCodes: any;
  public loading: boolean = false;
  public validForm  = false;

  constructor(
    private assistantsService: Service.AssistantsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AssistantEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.assistant = data;
    this.assistantBody = this.formBuilder.group({
      'phone': [this.assistant.phone, [
        Validators.required,
        Validators.maxLength(17),
        Validators.minLength(13)
      ]]
    });
  }

  public EditAssistant(): void {
    if(this.assistantBody.valid) {
      this.loading = !this.loading;
      this.assistantsService.putAssistant(this.data.assistantId, this.assistantBody.value).subscribe(
        data => {
          if (data) {
            this.dialogRef.close(data);
            this.loading = !this.loading;
          }
        },
        error => {
  
        }
      );
    } else 
      this.validForm = true;
  }

  public filterCodes(countryName: string): any {
    if (countryName != null || countryName != undefined) {
      return this.phoneCodes
        .filter((country: any) => {
          return country.name.toLowerCase().indexOf(countryName.toLowerCase()) === 0;
        });
    } else {
      return this.phoneCodes;
    }
  }
}
