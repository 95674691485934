import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Model from '../../../../core/models/Model';
import * as Service from '../../../../core/services/Service';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { MatDialog } from '@angular/material';
import * as Module from '../Module';

@Component({
  selector: 'app-zones-list',
  templateUrl: './list.zones.component.html',
  styleUrls: ['./list.zones.component.scss']
})

export class ZonesListComponent implements OnInit {
  public source: LocalDataSource;
  public zones: Model.Zone[];

  constructor(
    private router: Router,
    private zonesService: Service.ZonesService,
    public dialog: MatDialog
  ) { }

  settings = {
    mode: 'external',
    actions: {
      add: false,
      delete: true,
      edit: false,
      position: 'right',
      columnTitle: '',
    },
    columns: {
      name: {
        title: 'Nombre',
        filter: false
      }
    },
    delete: {
      deleteButtonContent: "<i class='material-icons clearBtn'>clear</i>",
    }
  };

  private GetZones(): void {
    this.zonesService.getZones().subscribe(
      data => {
        this.zones = data;
        for (let zone = 0; zone < data.length; zone++)
          this.source.add({
            zoneId: data[zone].id,
            name: data[zone].name
          }).catch();
        this.source.refresh();
      },
      error => {

      }
    );
  }

  public ngOnInit(): void {
    this.source = new LocalDataSource();
    this.GetZones();
  }

  public onSearch(query: string = '') {
    this.source.setFilter([
      {
        field: 'name',
        search: query
      }
    ], true);
  }

  public DeleteZone(event): void {
    const dialogRef = this.dialog.open(Module.ZoneDeleteComponent, {
      data: event.data
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data != undefined || data != null)
          this.source.remove(event.data).then().catch();
      },
      error => {

      }
    );
  }

  public CreateZone(): void {
    const dialogRef = this.dialog.open(Module.ZoneCreateComponent, {
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data != undefined || data != null) {
          this.source.add({
            zoneId: data.id,
            name: data.name
          }).catch();
          this.zones.push(data);
          this.source.refresh();
        }
      },
      error => {

      }
    );
  }
}
