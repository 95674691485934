import { NgModule } from '@angular/core';

import { CarouselModule } from './CarouselModule/carousel.module';
import { LocationModule } from './LocationModule/location.module';
import { NewsFeedModule } from './NewsFeedModule/news-feed.module';
import { TestimonialsModule } from 'app/AppModules/WebModules/TestimonialsModule/testimonials.module';

@NgModule({
  imports: [
    CarouselModule,
    NewsFeedModule,
    LocationModule,
    TestimonialsModule
  ],
  exports: [
    CarouselModule,
    NewsFeedModule,
    LocationModule,
    TestimonialsModule
  ]
})
export class WebModule { }
