import { Inject, Injectable, Optional } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { ResponsesService } from './Responses.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { EnvironmentURL } from './Config';
import * as Model from '../models/Model';
import { JWTHandler } from './JWT.handler';


@Injectable()
export class RechargesService {
  protected basePath;
  public defaultHeaders: Headers = new Headers();
  

  constructor(
    private responsesService: ResponsesService,
    protected http: Http
  ) {
    let urlProvider = new EnvironmentURL();
    this.basePath = urlProvider.GetDomainURL();
    
  }

  public getRechargeProducts(): Observable<Model.Recharge[]> {
    return this.getRechargeProductsHttp()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public putRechargeProduct(id: string, rechargeUpdate: Model.RechargeUpdate): Observable<Model.Recharge> {
    return this.putRechargeProductHttp(id, rechargeUpdate)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Costo de recarga actualizado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  private getRechargeProductsHttp(): Observable<Response> {
    const path = this.basePath + `/recharges`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private putRechargeProductHttp(id: string, rechargeUpdate: Model.RechargeUpdate): Observable<Response> {
    const path = this.basePath + `/recharges/${id}`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (id === null || id === undefined)
      throw new Error();

    if (rechargeUpdate === null || rechargeUpdate === undefined)
      throw new Error();

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      body: rechargeUpdate == null ? '' : JSON.stringify(rechargeUpdate),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

}
