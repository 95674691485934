import { Component, Input } from '@angular/core';

@Component({
  inputs: ['startDate', 'endDate'],
  selector: 'app-reports-top-stations',
  templateUrl: './reports-top-stations.component.html',
  styleUrls: ['./reports-top-stations.component.css']
})
export class ReportsTopStationsComponent {
  @Input() reports:any = [];
  page_size = 10;
  selected_page = 0;
  constructor() {}
  shouldShowReport(index){
    return (index >= (this.selected_page * this.page_size) && (index <= ((this.selected_page * this.page_size) + (this.page_size - 1))));
  }
  setSelectedPage(event){
    this.selected_page = event;
  }
}
