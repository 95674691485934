import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';

import * as Model from '../../../../core/models/Model';
import * as Service from '../../../../core/services/Service';

@Component({
  selector: 'app-locationInfo-productsList',
  templateUrl: './productsList.locationInfo.component.html',
  styleUrls: ['./productsList.locationInfo.component.scss']
})

export class LocationInfoProductListComponent implements OnInit {
  public firstTime = true;
  public source: LocalDataSource;
  private timeProducts: Model.TimeProduct[];
  public isLoadingProducts = false;
  public settings = {
    mode: 'external',
    actions: {
      add: false,
      delete: false,
      edit: false,
      position: 'right',
      columnTitle: '',
    },
    columns: {
      name: {
        title: 'Tiempo',
        filter: false
      },
      category: {
        title: 'Categoría',
        filter: false
      },
      price: {
        title: 'Costo',
        filter: false
      }
    }
  };

  constructor(
    private locationInfo: Service.LocationInfoService
  ) { }

  public getTimeProducts(forceUpdate?: boolean): void {
    this.isLoadingProducts = true;
    this.timeProducts = null;
    this.source.empty().catch();
    this.locationInfo.getTimeProducts(forceUpdate, this.firstTime).subscribe(
      data => {
        this.timeProducts = data;
        for (let product = 0; product < data.length; product++){
          this.source.add({
            name: this.timeToString(data[product].minutes),
            category: data[product].category.name,
            price: data[product].costCoins + ' Ares'
          }).catch();
        }
        this.source.refresh();
        this.firstTime = false;
        this.isLoadingProducts = false;
      },
      error => {
        this.firstTime = false;
        this.isLoadingProducts = false;
      }
    );
    this.firstTime = false;
    this.isLoadingProducts = false;
  }

  public ngOnInit(): void {
    this.source = new LocalDataSource();
    this.getTimeProducts();
  }

  private timeToString(time: number) {
    let text: string;
    switch (time) {
      case 30:
        text = '30 min';
        break;
      case 60:
        text = '1 hora';
        break;
      case 180:
        text = '3 horas';
        break;
      case 1440:
        text = '1 día'
        break;
      default:
        text = String(time);
        break;
    }
    return text;
  }
}
