import { Pipe, PipeTransform } from '@angular/core';
import { Avatar } from './models/Avatar.model';

@Pipe({
  name: 'avatarsFilter',
  pure: false
})

export class AvatarFilterPipe implements PipeTransform {
  public transform(items: Avatar[], filter: Avatar): Avatar[] {
    if (!items || !filter) {
      return items;
    }

    return items.filter((item: Avatar) => this.applyFilter(item, filter));
  }

  public applyFilter(avatar: Avatar, filter: Avatar): boolean {
    for (const field in filter) {
      if (filter[field]) {
        if (typeof filter[field] === 'string') {
          if (avatar[field].toLowerCase().indexOf(filter[field].toLowerCase()) === -1) {
            return false;
          }
        } else if (typeof filter[field] === 'number') {
          if (avatar[field] !== filter[field]) {
            return false;
          }
        }
      }
    }
    return true;
  }
}
