import { Component, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';

import { LocationEditComponent } from './../location-edit/location-edit.component';
import { LocationWebInfo } from './../models/location-web-info.model';

@Component({
  selector: 'app-location-view',
  templateUrl: './location-view.component.html',
  styleUrls: ['./location-view.component.scss']
})
export class LocationViewComponent {
  @Input() public location: LocationWebInfo;
  @Input() public locations: LocationWebInfo[];
  @Input() public source: MatTableDataSource<LocationWebInfo[]>;
  @Output() public sourceChange = new EventEmitter<MatTableDataSource<LocationWebInfo>>();

  constructor(
    private dialog: MatDialog
  ) { }

  public editLocation(location: LocationWebInfo): void {
    const dialogRef = this.dialog.open(LocationEditComponent, {
      data: this.location,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        let index = 0;
        this.location = data;
        this.locations.forEach((selectedLocation) => {
          if (selectedLocation.id === data.id) {
            this.location[index] = data;
          }
          index++;
        });
        this.sourceChange.emit(new MatTableDataSource<LocationWebInfo>(this.locations));
      }
    });
  }

  public closeMenu() {
    const table = document.querySelector('.tableGent');
    table.classList.remove('tableMin');
    const detail = document.querySelector('.detail');
    detail.classList.remove('visible');
    detail.classList.add('hidden');
  }

}
