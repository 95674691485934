import { Component, Inject } from '@angular/core';

import { GamesService } from '../services/games.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-game-delete',
  templateUrl: './game-delete.component.html',
  styleUrls: ['./game-delete.component.scss']
})
export class GameDeleteComponent {
  public loading: boolean;

  constructor(
    private gamesService: GamesService,
    public dialogRef: MatDialogRef<GameDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public deleteGame(): void {
    this.loading = true;
    this.gamesService.deleteGame(this.data.id).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close({});
      },
      error => {
        this.loading = false;
      });
  }
}
