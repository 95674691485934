import { Component, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Location, Country } from '../../../../core/models/Model';
import { LocationsService } from '../../../../core/services/Locations.service';
import { AlertsService } from '../../../../core/services/Alerts.service';
import { ValidationService } from '../../../../core/services/ValidationForms.service';

@Component({
  selector: 'app-location-edition',
  templateUrl: './edit.location.component.html',
  styleUrls: ['./edit.location.component.scss']
})

export class LocationEditionComponent {
  public countries: Country[];
  public location: Location;
  public locationBody: FormGroup;
  public loading = false;
  public validForm = false;

  constructor(
    private locationsService: LocationsService,
    private alertService: AlertsService,
    public dialogRef: MatDialogRef<LocationEditionComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.location = this.data.location;
    this.countries = this.data.countries;
    this.locationBody = this.formBuilder.group({
      'address': [this.location.locationInfo.address, [
        Validators.required]],
      'phone1': [this.location.locationInfo.phone1, [
        Validators.maxLength(16),
        Validators.minLength(10)
      ]],
      'phone2': [this.location.locationInfo.phone2, [
        Validators.maxLength(16),
        Validators.minLength(10)
      ]],
      'phone3': [this.location.locationInfo.phone3, [
        Validators.maxLength(16),
        Validators.minLength(10)
      ]],
      'email': [this.location.locationInfo.email, [
        ValidationService.emailValidator
      ]]
    });
  }

  public EditLocation(): void {
    if(this.locationBody.valid) {
      this.loading = !this.loading;
  
      this.locationsService.PutLocation(String(this.location.id), this.locationBody.value).subscribe(
        data => {
          this.loading = false;
          this.dialogRef.close(data);
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        });
    } else
      this.validForm = true;
  }
}
