import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as Model from '../../../../core/models/Model';
import * as Service from '../../../../core/services/Service';

@Component({
  selector: 'app-assistants-recovery',
  templateUrl: './assistants-recovery.component.html',
  styleUrls: ['./assistants-recovery.component.scss']
})
export class AssistantsRecoveryComponent {
  private token: string;
  public loading = false;
  public doPasswordsMatch = false;
  public isPasswordSetted = false;

  public options: any = {
    position: ['top', 'right'],
    timeOut: 5000,
    lastOnBottom: true
  } as any;

  public _confirmPassword;
  public get confirmPassword(): string {
    return this._confirmPassword;
  }
  public set confirmPassword(value: string) {
    this._confirmPassword = value;
    this.doPasswordsMatch = value === this.assistantPassword.password ? true : false;
  }
  public assistantPassword: Model.AssistantPassword = {
    password: ''
  };

  constructor(private route: ActivatedRoute, private router: Router, private assistantsService: Service.AssistantsService) {
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
    });
  }

  public setPassword(): void {
    this.loading = true;
    this.assistantsService.postAssistantPassword(this.assistantPassword, this.token).subscribe(
      data => {
        this.loading = false;
        this.isPasswordSetted = true;
      },
      error => {
        this.loading = false;
      }
    );
  }
}
