import { MatTableDataSource, MatPaginator } from "@angular/material";
import { TournamentsService } from "./../../TournamentsModule/services/tournaments.service";
import { Tournament } from "./../../TournamentsModule/models/tournament.model";
import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { TournamentHandlerService } from "../services/tournamentHandler.service";

@Component({
  selector: "app-tournaments-historical",
  templateUrl: "./tournaments-historical.component.html",
  styleUrls: ["./tournaments-historical.component.css"]
})
export class TournamentsHistoricalComponent implements OnInit {
  @Input() private startDate: number;
  @Input() private endDate: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public tournamentList: MatTableDataSource<Tournament>;
  public selectedTournament: Tournament;

  public displayedColumns: string[] = ["name", "game", "date", "location"];
  public selected = false;

  public tournamentsExtended: any;

  private _tournaments: any[];
  public get tournaments(): any[] {
    return this._tournaments;
  }
  public set tournaments(value: any[]) {
    this.tournmanetsService
      .getTournaments(this.startDate, this.endDate)
      .subscribe(
        (data: Tournament[]) => this._tournaments = data,
        (error: Error) => { },
        () => {
          const availableHandlerTournaments = this.tournaments.map(
            tournament => tournament.id
          );
          this.tournamentHandlerService
            .getTournaments(this.startDate, this.endDate)
            .subscribe((data) => {
              this.tournamentsExtended = data.filter(tournament =>  availableHandlerTournaments.includes(tournament.xid));
              this.tournamentList = new MatTableDataSource(this.tournamentsExtended);
              this.tournamentList.paginator = this.paginator;
            });
        });
  }

  constructor(
    private tournamentHandlerService: TournamentHandlerService,
    private tournmanetsService: TournamentsService
  ) { }

  public ngOnInit() {
    this.tournaments = [];
  }

  public onSelect(row: any): void {
    this.selected = true;
    this.selectedTournament = row;

    const table = document.querySelector(".tableGent");
    table.classList.add("tableMin");
    const detail = document.querySelector(".detail");
    detail.classList.add("visible");
    detail.classList.remove("hidden");
  }
}
