import { TournamentReportsModule } from './TournamentOnsiteModule/TournamentReportsModule/tournament-reports.module';
import { TournamentTemplatesModule } from './TournamentSiteModule/TournamentTemplatesModule/tournament-templates.module';
import { TournamentGamesModule } from './TournamentSiteModule/TournamentGamesModule/tournament-games.module';
import { TournamentModesModule } from './TournamentSiteModule/TournamentModesModule/tournament-modes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TournamentsModule } from './TournamentOnsiteModule/TournamentsModule/tournaments-module.module';

@NgModule({
  imports: [
    CommonModule,
    TournamentModesModule,
    TournamentGamesModule,
    TournamentTemplatesModule,
    TournamentReportsModule,
    TournamentsModule
  ],
  exports: [
    TournamentModesModule,
    TournamentGamesModule,
    TournamentReportsModule,
    TournamentTemplatesModule,
    TournamentsModule
  ]
})
export class TournamentModule { }
