import { NewsFeedEditComponent } from './../news-feed-edit/news-feed-edit.component';
import { NewsFeedCreateComponent } from './../news-feed-create/news-feed-create.component';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { MatDialog } from '@angular/material';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NewsFeedService } from '../services/news-feed.service';
import { NewsFeed } from '../models/news-feed.model';
import { NewsFeedDeleteComponent } from '../news-feed-delete/news-feed-delete.component';

@Component({
  selector: 'app-news-feed-list',
  templateUrl: './news-feed-list.component.html',
  styleUrls: ['./news-feed-list.component.scss']
})
export class NewsFeedListComponent implements OnInit {

  @ViewChild(MatPaginator) public paginator: MatPaginator;
  public imageLoaded = false;
  public localTime = null;
  public newsFeedList: MatTableDataSource<NewsFeed>;

  private _newsFeed: NewsFeed[];
  public get newsFeed(): NewsFeed[] {
    return this._newsFeed;
  }
  public set newsFeed(value: NewsFeed[]) {
    this.newsFeedService.getNewsFeed().subscribe((data: NewsFeed[]) => {
      this._newsFeed = data;
      this.newsFeedList = new MatTableDataSource<NewsFeed>(this._newsFeed);
      this.newsFeedList.paginator = this.paginator;
    });
  }

  public displayedColumns: string[] = ['picture', 'title', 'url', 'description', 'edit', 'delete'];

  constructor(
    private newsFeedService: NewsFeedService,
    private dialog: MatDialog
  ) { }

  public ngOnInit() {
    this.newsFeed = [];
  }

  public redirectToData(imageUrl: string): void {
    let url = '';
    if (!/^http[s]?:\/\//.test(imageUrl)) {
      url += 'http://';
    }

    url += imageUrl;
    window.open(url, '_blank');
  }

  public createNewsFeed(): void {
    const dialogRef = this.dialog.open(NewsFeedCreateComponent, {
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((data: NewsFeed) => {
      if (data) {
        this.newsFeed.push(data);
        this.newsFeedList = new MatTableDataSource<NewsFeed>(this.newsFeed);
        this.newsFeedList.paginator = this.paginator;
      }
    });
  }

  public editNewsFeed(newsFeed: NewsFeed): void {
    const dialogRef = this.dialog.open(NewsFeedEditComponent, {
      disableClose: true,
      data: newsFeed
    });
    dialogRef.afterClosed().subscribe((data: NewsFeed) => {
      if (data) {
        let index = 0;
        this.newsFeed.forEach(newsFeedObject => {
          if (newsFeedObject.id === data.id) {
            this.newsFeed[index] = data;
          }
          index++;
        });
        this.localTime = new Date().getTime();
        this.newsFeedList = new MatTableDataSource<NewsFeed>(this.newsFeed);
        this.newsFeedList.paginator = this.paginator;
      }
    });
  }

  public deleteNewsFeed(newsFeed: NewsFeed): void {
    const dialogRef = this.dialog.open(NewsFeedDeleteComponent, {
      disableClose: true,
      data: newsFeed
    });
    dialogRef.afterClosed().subscribe((data: NewsFeed) => {
      if (data) {
        this.newsFeed = this.newsFeed.filter(news => newsFeed.id !== news.id);
        this.newsFeedList = new MatTableDataSource<NewsFeed>(this.newsFeed);
        this.newsFeedList.paginator = this.paginator;
      }
    });
  }

}
