import { Inject, Injectable, Optional } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { JWTHandler } from './JWT.handler';
import { ResponsesService } from './Responses.service';

import { EnvironmentURL } from './Config';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import * as Model from '../models/Model';

@Injectable()
export class ApplicationsService {
  protected basePath;
  protected baseOffsite;
  public defaultHeaders: Headers = new Headers();
  


  constructor(
    protected http: Http,
    private responsesService: ResponsesService
  ) {
    let urlProvider = new EnvironmentURL();
    this.basePath = urlProvider.GetDomainURL();
    this.baseOffsite = urlProvider.GetDomainURL();

    
  }

  public getApplications(): Observable<Model.Application[]> {
    return this.getApplicationsWithHttpInfo()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public deleteApplication(id: string): Observable<Model.Application> {
    return this.deleteApplicationWithHttpInfo(id)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Aplicación eliminada.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public postApplication(stationBody: Model.ApplicationBody): Observable<Model.Application> {
    return this.postApplicationWithHttpInfo(stationBody)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Aplicación creada.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public putApplication(id: string, applicationUpdate: Model.ApplicationBody): Observable<Model.Application> {
    return this.putApplicationWithHttpInfo(id, applicationUpdate)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Aplicación actualizada.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  private getApplicationsWithHttpInfo(): Observable<Response> {
    const path = this.baseOffsite + `/applications`;
    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);

  }

  private deleteApplicationWithHttpInfo(id: string): Observable<Response> {
    const path = this.basePath + `/applications/${id}`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());
    if (id === null || id === undefined)
      throw new Error();

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Delete,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private postApplicationWithHttpInfo(applicationBody: Model.ApplicationBody): Observable<Response> {
    const path = this.basePath + `/applications`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());
    if (applicationBody === null || applicationBody === undefined) {
      throw new Error();
    }
    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: applicationBody == null ? '' : JSON.stringify(applicationBody),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private putApplicationWithHttpInfo(id: string, applicationBody: Model.ApplicationBody): Observable<Response> {
    const path = this.basePath + `/applications/${id}`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());
    if (id === null || id === undefined)
      throw new Error();
    if (applicationBody === null || applicationBody === undefined)
      throw new Error();
    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      body: applicationBody == null ? '' : JSON.stringify(applicationBody),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }
}