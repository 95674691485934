import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

import { AvatarCreationComponent } from '../avatar-creation/creation.avatars.component';
import { Avatar } from '../models/Avatar.model';
import { AvatarsService } from '../services/Avatars.service';

@Component({
  selector: 'app-avatars-list',
  templateUrl: './list.avatars.component.html',
  styleUrls: ['./list.avatars.component.scss']
})
export class AvatarListComponent implements OnInit {
  public filter: Avatar = {} as Avatar;

  private _avatars: Avatar[];
  public get avatars(): Avatar[] {
    return this._avatars;
  }
  public set avatars(value: Avatar[]) {
    this.avatarsService.getAvatars().subscribe(data => {
      this._avatars = data;
    });
  }

  constructor(private avatarsService: AvatarsService, private dialog: MatDialog) {}

  public UploadAvatar(): void {
    const dialogRef = this.dialog.open(AvatarCreationComponent, {
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.avatars.push(data);
      }
    });
  }

  public ngOnInit(): void {
    this.avatars = [];
  }
}
