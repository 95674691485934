import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  MAT_DIALOG_DATA,
  MatButtonModule,
  MatCardModule,
  MatDialogRef,
  MatIconModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatTableModule
} from '@angular/material';

import { CarouselCreateComponent } from './carousel-create/carousel-create.component';
import { CarouselDeleteComponent } from './carousel-delete/carousel-delete.component';
import { CarouselEditComponent } from './carousel-edit/carousel-edit.component';
import { CarouselListComponent } from './carousel-list/carousel-list.component';
import { CarouselService } from './services/carousel.service';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatTableModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    CarouselListComponent,
    CarouselCreateComponent,
    CarouselDeleteComponent,
    CarouselEditComponent
  ],
  exports: [
    CarouselListComponent,
    CarouselCreateComponent,
    CarouselDeleteComponent,
    CarouselEditComponent
  ],
  entryComponents: [
    CarouselCreateComponent,
    CarouselDeleteComponent,
    CarouselEditComponent
  ],
  providers: [
    CarouselService,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ]
})
export class CarouselModule { }
