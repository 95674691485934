import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response } from '@angular/http';
import { ResponsesService } from '../../../../../core/services/Responses.service';
import { EnvironmentURL } from '../../../../../core/services/Config';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { URLPath, URLTournament } from '../../../../../core/services/Config';

// import * as Model from '../models/index';
import { JWTHandler } from '../../../../../core/services/Service';

@Injectable()
export class TournamentHandlerService {
  protected tournamentHandlerPath = URLTournament;

  public defaultHeaders: Headers = new Headers();
  

  constructor(protected http: Http, private responsesService: ResponsesService) {
    const urlProvider = new EnvironmentURL();
    
  }

  public getTournaments(from?: number, to?: number): Observable<any> {
    return this.getTournamentsWithHttpInfo(from, to)
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }
  public getTournamentStatistics(xid: number) : Observable<any> {
    return this.getTournamentStatisticsWithHttpInfo(xid)
    .map((response: Response) => {
      return this.responsesService.GenericGetResponse(response).json();
    })
    .catch((error: any) => {
      error._body = JSON.parse(error._body).error;
      return Observable.throw(this.responsesService.GenericGetResponse(error));
    });
  }
  private getTournamentsWithHttpInfo(from?: number, to?: number): Observable<Response> {
    const path = this.tournamentHandlerPath + `/tournaments`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (from !== undefined)
      queryParameters.set('from', <any>from);
    if (to !== undefined)
      queryParameters.set('to', <any>to);

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private getTournamentStatisticsWithHttpInfo(xid: number): Observable<Response> {
    const path = this.tournamentHandlerPath + `/tournaments/${xid}/teams_statistics`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

}
