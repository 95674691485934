import { NgModule } from '@angular/core';
import { MatDialogModule, MatIconModule, MatButtonModule, MatCardModule, MatInputModule, MatSelectModule, MatOptionModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AuthenticationGuard } from '../../../core/services/authentication.guard';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import * as Module from './Module';
import * as Service from '../../../core/services/Service';

@NgModule({
  declarations: [
    Module.ZoneCreateComponent,
    Module.ZonesListComponent,
    Module.ZoneDeleteComponent
  ],
  entryComponents: [
    Module.ZoneCreateComponent,
    Module.ZoneDeleteComponent
  ],
  imports: [
    Ng2SmartTableModule,
    MatDialogModule,
    MatIconModule, 
    MatButtonModule, 
    MatCardModule, 
    MatInputModule, 
    MatSelectModule, 
    MatOptionModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    FormsModule,
    HttpModule,
    Module.ZonesRouting
  ],
  exports: [
    Module.ZoneCreateComponent,
    Module.ZonesListComponent,
    Module.ZoneDeleteComponent
  ],
  providers: [
    AuthenticationGuard,
    Service.ZonesService
  ]
})
export class ZonesModule { }