import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TournamentTemplatesService } from './../../TournamentSiteModule/TournamentTemplatesModule/services/tournamentTemplates.service';
import { LocationsService } from './../../../../core/services/Locations.service';
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { TournamentsListComponent } from "./tournaments-list/tournaments-list.component";
import { TournamentsCreateComponent } from "./tournaments-create/tournaments-create.component";
import { TournamentsEditComponent } from "./tournaments-edit/tournaments-edit.component";
import { TournamentsViewComponent } from "./tournaments-view/tournaments-view.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {
  MatDialogModule,
  MatTabsModule,
  MatDatepickerModule,
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatStepperModule,
  MatInputModule,
  MatMenuModule,
  MatSelectModule,
  MatOptionModule,
  MatNativeDateModule,
  MatAutocompleteModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatListModule,
  MatTableModule,
  MatPaginatorModule,
  MatRadioModule,
  MatProgressSpinnerModule,
  MatCheckboxModule
} from "@angular/material";
import { TournamentsService } from './services/tournaments.service';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatTabsModule,
    MatTabsModule,
    MatDatepickerModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatStepperModule,
    MatCheckboxModule,
    MatOptionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    PdfViewerModule,
    MatListModule,
    MatTableModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatPaginatorModule,
    MatRadioModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    TournamentsListComponent,
    TournamentsCreateComponent,
    TournamentsEditComponent,
    TournamentsViewComponent
  ],
  exports: [
    TournamentsListComponent,
    TournamentsCreateComponent,
    TournamentsEditComponent,
    TournamentsViewComponent
  ],
  entryComponents: [
    TournamentsCreateComponent,
    TournamentsEditComponent
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} }, { provide: MatDialogRef, useValue: {} },
    LocationsService,
    TournamentsService,
    TournamentTemplatesService
  ]
})

export class TournamentsModule { }
