import { Component } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import 'rxjs/add/operator/switchMap';

import { MatDialog } from '@angular/material';
import { Country, Location } from '../../../../core/models/Model';
import { EditLocationManagerComponent } from '../location-edit-manager/edit-manager.location.component';

import { LocationActivationComponent } from '../location-activate/activate.location.component';
import { LocationDeactivationComponent } from '../location-deactivate/deactivate.location.component';
import { LocationEditionComponent } from '../location-edit/edit.location.component';


@Component({
  selector: 'app-location-view',
  inputs: ['location', 'countries', 'source', 'event'],
  templateUrl: './view.location.component.html',
  styleUrls: ['./view.location.component.scss']
})


export class LocationViewComponent {
  public location: Location;
  public countries: Country[];
  public source: LocalDataSource;
  private event: any;

  constructor(
    private dialog: MatDialog
  ) { }

  public EditLocation(): void {
    const dialogRef = this.dialog.open(LocationEditionComponent, {
      data: {
        location: this.location,
        countries: this.countries
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.location = data;
      }
    });
  }

  public EditLocationManager(): void {
    const dialogRef = this.dialog.open(EditLocationManagerComponent, {
      data: {
        location: this.location
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.location = data;
      }
    });
  }

  public DeactivateLocation(): void {
    const dialogRef = this.dialog.open(LocationDeactivationComponent);
  }

  public ActivateLocation(): void {
    const dialogRef = this.dialog.open(LocationActivationComponent, {
      data: {
        location: this.location
      }
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.location.acceptedAt = data.acceptedAt;
          this.source.update(this.event.data, { status: (data.acceptedAt > 0) ? 'Validada' : 'Por Validar' }).then().catch();
        }
      });
  }

  public closeMenu() {
    const table = document.querySelector('.tableGent');
    table.classList.remove('tableMin');
    const detail = document.querySelector('.detail');
    detail.classList.remove('visible');
    detail.classList.add('hidden');
  }
}
