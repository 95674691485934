import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import 'rxjs/add/operator/switchMap';
import { LocalDataSource } from 'ng2-smart-table';


import { UsersService } from '../../../../core/services/Users.service';
import * as Model from '../../../../core/models/Model';
import * as Module from '../Module';

@Component({
  selector: 'app-station-view',
  inputs: ['station', 'stations', 'source', 'event'],
  templateUrl: './view.stations.component.html',
  styleUrls: ['./view.stations.component.css']
})

export class StationViewComponent {
  station: any;
  stations: Model.Station[];
  editUser: boolean;
  event: any;
  source: LocalDataSource;


  constructor(
    public location: Location,
    public dialog: MatDialog
  ) { }

  goBack(): void {
    this.location.back();
  }

  public EditStation(): void {
    const dialogRef = this.dialog.open(Module.StationEditionComponent, {
      data: this.station
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.stations[this.stations.findIndex(station => station.id == this.station.id)] = data;
        this.station = data;
        let new_station_data = {
            stationId: data.id,
            name: data.name,
            screen: data.screen ? data.screen : 'No aplica',
            category: data.category ? data.category.name : '',
            zone: data.zone ? data.zone.name : '',
            status: data.match && data.match.isTimeRunning ? 'Activo' : 'Inactivo'
        }
        this.source.update(this.event, new_station_data);
      }
    });
  }

  public DeactivateStation(): void {
    const dialogRef = this.dialog.open(Module.StationDeactivationComponent, {
      data: this.station
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        delete this.station['match'];
        this.stations[this.stations.findIndex(station => station.id == this.station.id)] = this.station;
        this.source.update(this.event, { status: 'Inactivo' }).then().catch();
      }
    });
  }

  public DeleteStation(): void {
    const dialogRef = this.dialog.open(Module.StationDeleteComponent, {
      data: this.station
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.source.remove(this.event.data);
        this.closeMenu();
      }
    },
      error => {
      });
  }

  public closeMenu() {
    this.editUser = !this.editUser;
    const table = document.querySelector('.tableGent');
    table.classList.remove('tableMin');
    const detail = document.querySelector('.detail');
    detail.classList.remove('visible');
    detail.classList.add('hidden');
  }

}
