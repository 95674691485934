import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationItem } from '../../core/models/NavigationItem.model';
import { UserAuthenticated, AuthAccessURL, User } from '../../core/models/Model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public selectedItem: NavigationItem;
  public openedMenu: number;
  public isSuperUser = false;

  public menuItems: any = [
    { itemName: 'Usuarios', routerLink: 'users' },
    { itemName: 'Sucursales', routerLink: 'locations' },
    { itemName: 'Países', routerLink: 'countries' },
    { itemName: 'Catálogo de precios', routerLink: 'products' },
    { itemName: 'Avatares', routerLink: 'avatars' }
  ];

  public onsiteMenuItems: any = [
    { itemName: 'Información', routerLink: 'locationInfo' },
    { itemName: 'Asistentes', routerLink: 'assistants' },
    { itemName: 'Kioskos', routerLink: 'kiosks' },
    { itemName: 'PCs', routerLink: 'pcs' },
    { itemName: 'Estaciones', routerLink: 'stations' },
    { itemName: 'Timers', routerLink: 'timers' },
    { itemName: 'Juegos', routerLink: 'games' },
    { itemName: 'Aplicaciones', routerLink: 'applications' },
    { itemName: 'Zonas', routerLink: 'zones' },
    { itemName: 'Reportes', routerLink: 'reports' }
  ];

  public tournamentMenuItems: any = [
    { itemName: 'Plantillas', routerLink: 'tournamentTemplates', allow: 'tournament' },
    { itemName: 'Juegos', routerLink: 'tournamentGames', allow: 'tournament' },
    { itemName: 'Modos de torneo', routerLink: 'tournamentModes', allow: 'tournament' },
    { itemName: 'Torneos', routerLink: 'tournaments', allow: 'onsite' }
  ];

  public webMenuItems: any = [
    { itemName: 'Carrusel', routerLink: 'carousel' },
    { itemName: 'Noticias', routerLink: 'newsFeed' },
    { itemName: 'Sucursales', routerLink: 'web-locations' },
    { itemName: 'Testimonios', routerLink: 'testimonials' }

  ];

  public options: any;
  public userAccess: string;
  public user: UserAuthenticated;

  constructor(public router: Router) { }

  public ngOnInit() {
    this.options = {
      position: ['top', 'right'],
      timeOut: 5000,
      lastOnBottom: true
    };

    this.getLoggedUserName();
    this.isSuperUser = this.user.role === 'super' ? true : false;
  }

  public onSelect(item: NavigationItem): void {
    this.selectedItem = item;
  }

  public getLoggedUserName(): string {
    const token = localStorage.getItem('user');
    if (token === null || token === undefined || token === '') {
      this.logOut();
    }
    this.user = JSON.parse(token);
    const authorization: AuthAccessURL = JSON.parse(localStorage.getItem('authorization'));
    this.userAccess =
      (
        authorization.userAuthorization === 'onsite' ||
        authorization.userAuthorization === 'super' ||
        authorization.userAuthorization === 'tournament' ||
        authorization.userAuthorization === 'web') ? authorization.userAuthorization : undefined;
    let userName: User;

    if (token) {
      userName = JSON.parse(token);
    }

    if (authorization.userAuthorization === 'tournament' && this.user.role !== 'super') {
      this.tournamentMenuItems = this.tournamentMenuItems.filter(
        tournamentUser => tournamentUser.allow === authorization.userAuthorization
      );
    }

    return userName.profile.name + ' ' + userName.profile.lastName;
  }

  public showThisMenu(menuIndex): void {
    if (this.openedMenu === undefined) {
      this.openedMenu = menuIndex;
      return;
    }
    if (this.openedMenu === menuIndex) {
      this.openedMenu = undefined;
      return;
    }
    this.openedMenu = undefined;
    setTimeout(() => {
      this.openedMenu = menuIndex;
      return;
    }, 600);
  }

  public logOut(): void {
    this.router.navigate(['/login']);
  }
}
