import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';

import { LocationWebInfo } from './../models/location-web-info.model';
import { LocationWebService } from './../services/location-web.service';

@Component({
  selector: 'app-web-locations-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss']
})
export class LocationListComponent implements OnInit {

  @ViewChild(MatPaginator) public paginator: MatPaginator;
  public selected = false;
  public selectedLocation: LocationWebInfo;
  public webLocationsList: MatTableDataSource<LocationWebInfo>;

  private _locations: LocationWebInfo[];
  public get locations(): LocationWebInfo[] {
    return this._locations;
  }
  public set locations(value: LocationWebInfo[]) {
    this.locationsWebService.getWebLocations().subscribe((data: LocationWebInfo[]) => {
      this._locations = data;
      this.webLocationsList = new MatTableDataSource<LocationWebInfo>(this._locations);
      this.webLocationsList.paginator = this.paginator;
    });
  }

  public displayedColumns: string[] = ['name', 'domain', 'country', 'city', 'schedules'];

  constructor(
    private locationsWebService: LocationWebService
  ) { }

  public ngOnInit() {
    this.locations = [];
  }

  public onSelect(location: LocationWebInfo) {
    this.selected = true;
    this.selectedLocation = location;
    const table = document.querySelector('.tableGent');
    table.classList.add('tableMin');
    const detail = document.querySelector('.detail');
    detail.classList.add('visible');
    detail.classList.remove('hidden');
  }

}
