import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tournament-view-winners',
  templateUrl: './tournament-view-winners.component.html',
  styleUrls: ['./tournament-view-winners.component.css']
})
export class TournamentViewWinnersComponent {
  @Input() public tournament: any;

}
