import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import * as Module from '../Module';
import * as Model from '../../../../core/models/Model';
import * as Service from '../../../../core/services/Service';

@Component({
  selector: 'app-timer-activation',
  templateUrl: './activate.timers.component.html',
  styleUrls: ['./activate.timers.component.scss']

})

export class TimerActivationComponent {
  loading = false;

  constructor(
    private timersService: Service.TimersService,
    public dialogRef: MatDialogRef<TimerActivationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public ActivateTimer(): void {
    this.loading = true;
    this.timersService.putTimer(this.data.subject).subscribe(
      data => {
        this.loading = !this.loading;
        this.dialogRef.close(data);
      },
      error => {
        this.loading = false;
      });
  }
}
