import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import * as Model from '../../../../core/models/Model';
import * as Service from '../../../../core/services/Service';
import * as Module from '../Module';

@Component({
  selector: 'app-zone-create',
  templateUrl: './create.zones.component.html',
  styleUrls: ['./create.zones.component.scss']
})
export class ZoneCreateComponent {
  public loading = false;
  public model: Model.Zone = {} as Model.Zone;
  public validForm  = false;
  public zonesBody: FormGroup;
  
  constructor(
    private formBuilder: FormBuilder,
    private zonesService: Service.ZonesService,
    public dialogRef: MatDialogRef<ZoneCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.zonesBody = this.formBuilder.group({
      'name': ['', [
        Validators.required
      ]]
    });
  }

  public CreateZone(): void {
    if(this.zonesBody.valid) {
      this.loading = true;
  
      this.zonesService.postZones(this.zonesBody.value.name).subscribe(
        data => {
          this.loading = false;
          this.dialogRef.close(data);
        },
        error => {
          this.loading = false;
        }
      );
    } else
      this.validForm = true;
  }
}
