import { NgModule } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';

import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatDialogModule, MatStepperIntl, MatMenuModule, MatIconModule,
  MatButtonModule, MatCardModule, MatInputModule, MatSelectModule,
  MatOptionModule, MatProgressSpinnerModule
} from '@angular/material';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AuthenticationGuard } from '../../../core/services/authentication.guard';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CommonModule } from '@angular/common';
import { GameEditComponent } from './game-edit/game-edit.component';
import { GameCreateComponent } from './game-create/game-create.component';
import { GameDeleteComponent } from './game-delete/game-delete.component';
import { GameListComponent } from './game-list/game-list.component';
import { GameViewComponent } from './game-view/game-view.component';
import { GamesService } from './services/games.service';
import { GamesBySystemService } from './services/gamesBySystem.service';

import { TagInputModule } from 'ngx-chips';
import { GameBySystemCreateComponent } from './game-by-system-create/game-by-system-create.component';

@NgModule({
  entryComponents: [
    GameDeleteComponent,
    GameCreateComponent,
    GameEditComponent,
    GameBySystemCreateComponent
  ],
  imports: [
    Ng2SmartTableModule,
    MatDialogModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatOptionModule,
    TagInputModule,
    BrowserModule,
    FormsModule,
    HttpModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    CommonModule
  ],
  declarations: [
    GameEditComponent,
    GameCreateComponent,
    GameDeleteComponent,
    GameListComponent,
    GameEditComponent,
    GameViewComponent,
    GameBySystemCreateComponent
  ],
  exports: [
    GameListComponent,
    GameDeleteComponent,
    GameViewComponent,
    GameEditComponent,
    GameCreateComponent,
    GameBySystemCreateComponent
  ],
  providers: [
    GamesService,
    GamesBySystemService
  ]
})
export class GamesModule { }
