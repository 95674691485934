import { Component, Inject } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PhoneCodes } from '../../../../core/files/PhoneCodes';
import * as Service from '../../../../core/services/Service';
import * as Model from '../../../../core/models/Model';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/map';
import { UserBody } from '../../../../core/models/Model';

@Component({
  selector: 'app-user-creation',
  templateUrl: './creation.users.component.html',
  styleUrls: ['./creation.users.component.scss']
})
export class UserCreationComponent {
  public source: LocalDataSource;
  public loading = false;
  public userBody: FormGroup;
  public phoneBody: FormGroup;
  public phoneCodes = PhoneCodes;
  public filteredCodes: any;
  public codeCtrl: FormControl;
  public validForm  = false;

  public roles = [
    { id: 'super', name: 'Super Administrator' },
    { id: 'onsite', name: 'Onsite Administrator' },
    { id: 'tournament', name: 'Tournament Administrator' },
    { id: 'web', name: 'CMS Administrador' }
  ];

  constructor(
    private usersService: Service.UsersService,
    public dialogRef: MatDialogRef<UserCreationComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.codeCtrl = new FormControl();
    this.phoneBody = this.formBuilder.group({
      prefix: this.codeCtrl,
      phone: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(10)]]
    });
    this.userBody = this.formBuilder.group({
      name: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      role: ['', [Validators.required]],
      position: ['', [Validators.required]],
      address: ['', [Validators.required]],
      email: ['', [Validators.required, Service.ValidationService.emailValidator]],
      phone: this.phoneBody,
      locationId: ['', []]
    });
    this.filteredCodes = this.codeCtrl.valueChanges.startWith(null).map((country: any) => {
      return this.filterCodes(country);
    });
  }

  public filterCodes(countryName: string): any {
    if (countryName != null || countryName != undefined) {
      return this.phoneCodes.filter((country: any) => {
        return country.name.toLowerCase().indexOf(countryName.toLowerCase()) === 0;
      });
    } else {
      return this.phoneCodes;
    }
  }

  public createUser(): void {
    if(this.userBody.valid) {
      this.loading = !this.loading;
      const userBody: UserBody = this.userBody.value;
  
      if (String(userBody.locationId) === '') {
        delete userBody['locationId'];
      }
  
      this.usersService.CreateUser(this.userBody.value).subscribe(
        data => {
          this.loading = !this.loading;
          this.dialogRef.close(data);
        },
        error => {
          this.loading = !this.loading;
        }
      );
    } else
      this.validForm = true;
  }
}
