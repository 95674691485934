import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-reports-group-match-view',
  templateUrl: './reports-group-match-view.component.html',
  styleUrls: ['./reports-group-match-view.component.scss']
})
export class ReportsGroupMatchViewComponent {
  @Input() report: any = null;
  @Output() close_detail = new EventEmitter();
  constructor() { }
  closeDetail(){
    this.close_detail.emit();
  }
  getTimeFormat(time){
    var date = new Date((+time) * 1000);
    var hours = date.getHours();
    var minutes = "0" + date.getMinutes();
    var formattedTime = hours + ':' + minutes.substr(-2);
    return formattedTime;
  }
}