import { TournamentModesService } from './../../TournamentModesModule/services/tournamentModes.service';
import { TournamentGamesService } from './../../TournamentGamesModule/services/tournamentGames.service';
import { MatDialog } from '@angular/material';
import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';

import * as TemplateModels from '../models/index';
import * as GameModels from '../../TournamentGamesModule/models/index';
import * as ModeModels from '../../TournamentModesModule/models/index';

import { TournamentCreateComponent } from '../tournament-create/tournament-create.component';
import { TournamentTemplatesService } from '../services/tournamentTemplates.service';

@Component({
  selector: 'app-tournament-templates-list',
  templateUrl: './tournaments-list.component.html',
  styleUrls: ['./tournaments-list.component.css']
})
export class TournamentsListComponent implements OnInit {
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  public selected = false;
  public selectedTemplate: TemplateModels.TournamentTemplate;
  public tournamentList: MatTableDataSource<TemplateModels.TournamentTemplate>;

  public displayedColumns: string[] = ['name', 'game', 'mode', 'maxPlayers', 'minPlayers'];

  private _tournamentTemplates: TemplateModels.TournamentTemplate[];
  public get tournamentTemplates(): TemplateModels.TournamentTemplate[] {
    return this._tournamentTemplates;
  }
  public set tournamentTemplates(value: TemplateModels.TournamentTemplate[]) {
    this.tournamentsSiteService.getTournamentTemplates().subscribe(
      (data: TemplateModels.TournamentTemplate[]) => {
        this._tournamentTemplates = data;
        this.tournamentList = new MatTableDataSource<TemplateModels.TournamentTemplate>(data);
        this.tournamentList.paginator = this.paginator;
      });
  }

  private _tournamentMode: ModeModels.Mode[];
  public get tournamentModes(): ModeModels.Mode[] {
    return this._tournamentMode;
  }
  public set tournamentModes(tournamentModes: ModeModels.Mode[]) {
    this.tournamentModesService.getTournamentModes().subscribe(
      (data: ModeModels.Mode[]) => {
        this._tournamentMode = data;
      });
  }

  public _tournamentGames: GameModels.Game[];
  public get tournamentGames(): GameModels.Game[]{
    return this._tournamentGames;
  }
  public set tournamentGames(games: GameModels.Game[]){
    this.tournamentGamesService.getTournamentGames().subscribe(
      (data: GameModels.Game[]) => {
        this._tournamentGames = data;
      }
    );
  }

  constructor(
    private dialog: MatDialog,
    private tournamentsSiteService: TournamentTemplatesService,
    private tournamentGamesService: TournamentGamesService,
    private tournamentModesService: TournamentModesService
  ) { }

  public ngOnInit(): void {
    this.tournamentGames = [];
    this.tournamentModes = [];
    this.tournamentTemplates = [];
  }

  public createTournamentTemplate() {
    const dialogRef = this.dialog.open(TournamentCreateComponent, {
      data: {
        tournamentGames: this.tournamentGames,
        tournamentModes: this.tournamentModes
      }
    });
    dialogRef.afterClosed().subscribe(
      (data: TemplateModels.TournamentTemplate) => {
      if (data) {
        this.tournamentTemplates.push(data);
        this.tournamentList = new MatTableDataSource<TemplateModels.TournamentTemplate>(this.tournamentTemplates);
        this.tournamentList.paginator = this.paginator;
      }
    });
  }

  public onSelect(tournamentTemplate: TemplateModels.TournamentTemplate) {
    this.selectedTemplate = tournamentTemplate;
    this.selected = true;
    const table = document.querySelector('.tableGent');
    table.classList.add('tableMin');
    const detail = document.querySelector('.detail');
    detail.classList.add('visible');
    detail.classList.remove('hidden');
  }


}
