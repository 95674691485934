import { Component } from '@angular/core';
import { AvatarsService } from '../services/Avatars.service';

import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ValidationService } from '../../../../core/services/Service';
import { AvatarCreationConfirmationComponent } from 'app/AppModules/OffsiteModules/AvatarsModule/avatar-creation-confirmation/avatar-creation-confirmation.component';

@Component({
  selector: 'app-avatar-creator',
  templateUrl: './creation.avatars.component.html',
  styleUrls: ['./creation.avatars.component.scss']
})
export class AvatarCreationComponent {
  public avatarBody: FormGroup;
  public loaded = false;
  public loading = false;
  public imageLoaded = false;
  public imageSrc = '';
  public message = false;
  public validForm = false;

  constructor(
    private avatarsService: AvatarsService,
    public dialogRef: MatDialogRef<AvatarCreationComponent>,
    private dialog: MatDialog
  ) {
    this.avatarBody = new FormGroup({
      'name': new FormControl('', [
        Validators.required
      ]),
      'file': new FormControl('', [
        Validators.required,
        ValidationService.imageSizeValidator(1000000)
      ]),
    });
  }

  public get file(): AbstractControl { return this.avatarBody.get('file'); }

  public UploadAvatar() {
    if(this.avatarBody.valid){
      const dialogRef = this.dialog.open(AvatarCreationConfirmationComponent);
      dialogRef.afterClosed().subscribe((confirmation) => {
        if (confirmation) {
          this.loading = true;
          this.avatarsService.postAvatar(this.avatarBody.value).subscribe(
            data => {
              this.loading = false;
              this.dialogRef.close(data);
            },
            error => {
              this.loading = false;
            });
        }
      });
    } else
    this.validForm = true;
  }

  public handleImageLoad() {
    this.imageLoaded = true;
  }

  public handleInputChange(e) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    this.avatarBody.get('file').setValue(e.target.files[0]);
    const reader = new FileReader();
    this.loaded = false;
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  public _handleReaderLoaded(e) {
    const reader = e.target;
    this.imageSrc = reader.result;
    this.loaded = true;

    const image = new Image();
    image.src = reader.result;
    image.onload = () => {
      if(image.width === 800 && image.height === 800){
        this.message = false;
      } else {
        this.message = true;
      }
    }
  }
}