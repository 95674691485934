import { Http, Headers, URLSearchParams } from '@angular/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { ResponsesService } from '../../../../core/services/Responses.service';

import 'rxjs/add/operator/map';
import { Observable, Subject } from 'rxjs';
import { EnvironmentURL } from '../../../../core/services/Config';
import { URLOnsite } from '../../../../core/services/Config';
import { GameBody } from './../../../TournamentModules/TournamentSiteModule/TournamentGamesModule/models/gameBody.model';
import { Game } from './../models/game.model';

import { JWTHandler } from '../../../../core/services/Service';
import * as Model from '../models/Model';

@Injectable()
export class GamesService {
  protected basePath = URLOnsite;
  public defaultHeaders: Headers = new Headers();
  private subject = new Subject<any>();

  constructor(protected http: Http, private responsesService: ResponsesService) {
    const urlProvider = new EnvironmentURL();
    this.basePath = urlProvider.GetDomainURL();
  }

  sendTime(time: Date) {
    this.subject.next(time);
  }

  getTime(): Observable<any> {
    return this.subject.asObservable();
  }

  public getGames(): Observable<Model.Game[]> {
    return this.getGamesWithHttpInfo()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public getTags(): Observable<Model.GameTag[]> {
    return this.getTagsWithHttpInfo()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public getGamesBySystem(systemName?: string, excludeSystem?: string): Observable<Model.GameBySystem[]> {
    return this.getGamesBySystemWithHttpInfo(systemName, excludeSystem)
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public postCover(id: number, cover: any): Observable<Model.Game> {
    return this.postCoverWithHttpInfo(id, cover)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Portada añadida.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public postGame(gameBody: Model.GameBody, gameTags: Model.GameTag[]): Observable<Model.Game> {
    return this.postGameWithHttpInfo(gameBody, gameTags)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Juego creado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public postTag(name: string): Observable<Model.GameTag> {
    return this.postTagWithHttpInfo(name)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Tag creado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public postGameBySystem(id: number, gameSystemBody: Model.GameSystemBody): Observable<Model.GameSystem> {
    return this.postGameBySystemWithHttpInfo(id, gameSystemBody)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Juego por consola creado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public postScreenshot(id: number, screenshot: any): Observable<Model.Game> {
    return this.postScreenshotWithHttpInfo(id, screenshot)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Capturas de pantalla añadidas.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public putGame(gameId: number, gameBody: Model.GameBody, tags: Model.GameTag[]): Observable<Model.Game> {
    return this.putGameWithHttpInfo(gameId, gameBody, tags)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Juego editado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public putGameBySystem(id: number, gameSystemBody: Model.GameSystemBody): Observable<Model.GameBySystem> {
    return this.putGameBySystemWithHttpInfo(id, gameSystemBody)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Juego por consola editado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public deleteGame(id: number): Observable<Model.Game> {
    return this.deleteGameWithHttpInfo(id)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Juego eliminado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  private getGamesWithHttpInfo(): Observable<Response> {
    const path = this.basePath + `/games`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private getTagsWithHttpInfo(): Observable<Response> {
    const path = this.basePath + `/tags`;
    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private getGamesBySystemWithHttpInfo(systemName?: string, excludeSystem?: string): Observable<Response> {
    const path = this.basePath + `/games/by_system`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private postCoverWithHttpInfo(id: number, cover: any): Observable<Response> {
    const path = this.basePath + `/games/${id}/cover`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());
    let formParams = new URLSearchParams();

    if (id === null || id === undefined) throw new Error();

    if (cover === null || cover === undefined) throw new Error();

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/x-www-form-urlencoded');

    if (cover !== undefined) {
      formParams.set('cover', <any>cover);
    }

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      body: formParams.toString(),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private postGameWithHttpInfo(gameBody: Model.GameBody, gameTags: Model.GameTag[]): Observable<Response> {
    const path = this.basePath + `/games`;

    const queryParameters = new URLSearchParams();
    const formData: FormData = new FormData();
    const headers = new Headers(this.defaultHeaders.toJSON());

    const tagList: Model.GameTag[] = gameTags;

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    formData.append('name', gameBody.name);
    formData.append('maxPlayers', String(gameBody.maxPlayers));
    formData.append('esrb', gameBody.esrb);
    formData.append('description', gameBody.description);
    formData.append(
      'tagIds',
      JSON.stringify(
        gameBody.tagIds.map(tag => {
          if (!tag.id) {
            tag = tagList.find((tagToFind: Model.GameTag) => {
              return tag.name === tagToFind.display;
            });
          }
          return tag.id;
        })
      )
    );
    formData.append('cover', gameBody.cover);
    formData.append('highlight', gameBody.highlight);
    formData.append('screenshot', gameBody.screenshots[0]);
    formData.append('screenshot', gameBody.screenshots[1]);
    formData.append('screenshot', gameBody.screenshots[2]);

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers
    });
    return this.http.post(path, formData, requestOptions);
  }

  private postGameBySystemWithHttpInfo(id: number, gameSystemBody: Model.GameSystemBody): Observable<Response> {
    const path = this.basePath + `/games/${id}/game_systems`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (id === null || id === undefined) throw new Error('Required parameter id was null or undefined when calling postGameBySystem.');

    if (gameSystemBody === null || gameSystemBody === undefined)
      throw new Error('Required parameter gameSystemBody was null or undefined when calling postGameBySystem.');

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: gameSystemBody == null ? '' : JSON.stringify(gameSystemBody),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private postScreenshotWithHttpInfo(id: number, screenshot: any): Observable<Response> {
    const path = this.basePath + `/games/${id}/screenshots`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());
    let formParams = new URLSearchParams();

    if (id === null || id === undefined) throw new Error();

    if (screenshot === null || screenshot === undefined) throw new Error();

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/x-www-form-urlencoded');

    if (screenshot !== undefined) {
      formParams.set('screenshot', <any>screenshot);
    }

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      body: formParams.toString(),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private putGameWithHttpInfo(gameId: number, gameBody: Model.GameBody, tags: Model.GameTag[]): Observable<Response> {
    const path = this.basePath + `/games/${gameId}`;

    const headers = new Headers(this.defaultHeaders.toJSON());
    const formData: FormData = new FormData();

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    const screenshots = new Array<string>();
    for (const property in gameBody) {
      if (property && gameBody[property]) {
        if (property === 'tagIds') {
          formData.append('tagIds', JSON.stringify(
            gameBody.tagIds.map(tag => {
              if (!tag.id) {
                tag = tags.find((tagToFind: Model.GameTag) => tag.name === tagToFind.display);
              }
              return tag.id;
            })
          )
          );
        } else if (property.includes('screenshot')) {
          if (gameBody[property]) {
            screenshots.push(gameBody[property]);
          }
        } else {
          formData.append(property, gameBody[property]);
        }
      }
    }
    if (screenshots.length > 0) {
      formData.append('screenshot', screenshots[0]);
      formData.append('screenshot', screenshots[1]);
      formData.append('screenshot', screenshots[2]);
    }

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers
    });

    return this.http.put(path, formData, requestOptions);
  }

  private postTagWithHttpInfo(tagName: string): Observable<Response> {
    const path = this.basePath + `/tags`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (tagName === null || tagName === undefined) {
      throw new Error('Required parameter assistantInfo was null or undefined when calling postAssistant.');
    }

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const prueba: any = {
      name: tagName
    };

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: name == null ? '' : JSON.stringify(prueba),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private putGameBySystemWithHttpInfo(id: number, gameSystemBody: Model.GameSystemBody): Observable<Response> {
    const path = this.basePath + `/game_systems/${id}`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (id === null || id === undefined) throw new Error();

    if (gameSystemBody === null || gameSystemBody === undefined) throw new Error();

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      body: gameSystemBody == null ? '' : JSON.stringify(gameSystemBody),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private deleteGameWithHttpInfo(id: number): Observable<Response> {
    const path = this.basePath + `/games/${id}`;

    const headers = new Headers(this.defaultHeaders.toJSON());

    if (id === null || id === undefined) throw new Error();

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Delete,
      headers: headers
    });

    return this.http.request(path, requestOptions);
  }
}
