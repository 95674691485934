import { FormControl, ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

import 'rxjs/add/operator/map';

export class ValidationService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      required: 'Required',
      invalidCreditCard: 'Is invalid credit card number',
      invalidEmailAddress: 'Invalid email address',
      invalidPassword: 'Invalid password. Password must be at least 6 characters long, and contain a number.',
      minlength: `Minimum length ${validatorValue.requiredLength}`
    };

    return config[validatorName];
  }

  static locationPrefixValidator(control: FormControl) {
    if (control.value.match(/^[A-Za-z\d]+$/)) {
      return null;
    } else {
      return { 'Prefijo inválido.': true };
    }
  }

  static locationDomainValidator(control: FormControl) {
    if (control.value.match(/^[a-zA-Z\d][a-zA-Z\d\-_]*[a-zA-Z\d]$/)) {
      return null;
    } else {
      return { 'Dominio inválido.': true };
    }
  }

  static urlValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const re = /http[s]?:\/\/[a-zA-Z0-9.-/]+/;
      return re.test(control.value) ? null : { isNotHttp: { value: control.value } };
    }
  }

  static prefixValidator(control: FormControl) { }

  static integerValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const re = /^-?[0-9]+$/;
      // const isIntegerValue = !isNaN(control.value) && parseInt(control.value) == control.value && !isNaN(parseInt(control.value, 10));
      return re.test(control.value) ? null : { isNotInteger: { value: control.value } };
    }
  }

  static emailValidator(control: FormControl) {
    if (
      control.value.match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      )
    ) {
      return null;
    } else {
      return { 'Email inválido. ': true };
    }
  }

  static minValue(minValue: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const isValidValue = control.value >= minValue;
        return isValidValue ? null : { isInvalidValue: { value: control.value } };
      }
    };
  }

  static higherThan(value: number) : ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const isValidValue = control.value >= value;
        return isValidValue ? null : { isInvalidValue: { value: control.value } };
      }
    };
  }

  // static passwordValidator(control: FormControl) {
  //   if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
  //     return null;
  //   } else {
  //     return { invalidPassword: true };
  //   }
  // }

  static imageSizeValidator(maximumFileSize: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const isSizeValid = control.value.size <= maximumFileSize;
        return isSizeValid ? null : { isInvalidSize: { value: control.value } };
      }
    };
  }

  static imageOrientationValidator(position: string) : ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        return null;
      };
    }
  }

  static getOrientation(position: string, control: AbstractControl): boolean {
    
    const isValidValue = this.getWidthHeight(control, function(width, height) {
      let ww;
          if(width === height) {
            ww = "Square" === position;
          } else if (width < height) {
            ww = "Portrait" === position;
          } else {
            ww = "Landscape" === position;
          }
          return ww;
    });
    return isValidValue;
  }

  static getWidthHeight(control: AbstractControl, callback): any {
    const tempFile = new FileReader();
    tempFile.readAsDataURL(control.value);

    tempFile.onload = () => {
      const image = new Image();
      image.src = tempFile.result;
      image.onload = () => {
        callback(image.width, image.height);
      }
    }
  }

  static imageRatioValidator(ratio: string) : ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        return this.getRatio(ratio, control);
      };
    }
  }

  static getRatio(ratio: string, control: AbstractControl) : any {
    const tempFile = new FileReader();
    tempFile.readAsDataURL(control.value);
    
    tempFile.onload = () => {
      const image = new Image();
      image.src = tempFile.result;
      image.onload = () => {
        let isValidValue = false;
      }
    }
    return { isInvalidRatio: { value: control.value } };
  }

  public getGCD(width, height){
    return (height == 0) ? width : this.getGCD (height, width%height);
  };

  static passwordValidator(registrationFormGroup: FormGroup) {
    const password = registrationFormGroup.controls.password.value;
    const repeatPassword = registrationFormGroup.controls.repeatPassword.value;

    if (repeatPassword.length <= 0) {
      return null;
    }

    if (repeatPassword !== password) {
      return {
        doesMatchPassword: true
      };
    }
    return null;
  }

}

export interface IImageValidationParams {
  minHeight: number;
  maxHeight: number;

  minWidth: number;
  maxWidth: number;

  orientation: string;

  ratio: string;
}
