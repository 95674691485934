import { TournamentModesService } from './../services/tournamentModes.service';
import { TournamentModesCreateComponent } from './../tournament-modes-create/tournament-modes-create.component';
import { TournamentModesEditComponent } from './../tournament-modes-edit/tournament-modes-edit.component';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ViewChild, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as Model from '../models/index';

@Component({
  selector: 'app-tournament-modes-list',
  templateUrl: './tournament-modes-list.component.html',
  styleUrls: ['./tournament-modes-list.component.css']
})
export class TournamentModesListComponent implements OnInit {
  public tournamentModeList: MatTableDataSource<Model.Mode>;
  public displayedColumns: string[] = ['name', 'edit'];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  private _tournamentMode: Model.Mode[];
  public get tournamentModes(): Model.Mode[] {
    return this._tournamentMode;
  }
  public set tournamentModes(tournamentModes: Model.Mode[]) {
    this.tournamentModesService.getTournamentModes().subscribe(
      (data: Model.Mode[]) => {
        this._tournamentMode = data;
        this.tournamentModeList = new MatTableDataSource(data);
        this.tournamentModeList.paginator = this.paginator;
      });
  }

  constructor(
    private dialog: MatDialog,
    private tournamentModesService: TournamentModesService
  ) { }

  public ngOnInit(): void {
    this.tournamentModes = [];
  }

  public createTournamentMode(): void {
    const dialogRef = this.dialog.open(TournamentModesCreateComponent);
    dialogRef.afterClosed().subscribe(
      (data: Model.Mode) => {
        if (data) {
          this.tournamentModes.push(data);
          this.tournamentModeList = new MatTableDataSource(this.tournamentModes);
          this.tournamentModeList.paginator = this.paginator;
        }
      });
  }

  public editTournamentMode(tournamentMode: Model.Mode): void {
    let aux = tournamentMode;
    const dialogRef = this.dialog.open(TournamentModesEditComponent, {
      data: aux
    });

    dialogRef.afterClosed().subscribe(
      (data: Model.Mode) => {
        if (data) {
          this.tournamentModes.find((ModeToFind: Model.Mode) => {
            if (ModeToFind.id === data.id) {
              ModeToFind.name = data.name;
            }
            return ModeToFind.id === data.id;
          });
          this.tournamentModeList = new MatTableDataSource(this.tournamentModes);
          this.tournamentModeList.paginator = this.paginator;
        }
      });
  }

  public onSearch(query: string = '') {
    query = query.trim();
    query = query.toLowerCase();
    this.tournamentModeList.filter = query;
  }

}
