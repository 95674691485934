import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '../../../core/services/authentication.guard';
import { AvatarListComponent } from './avatar-list/list.avatars.component';

const avatarsRoutes: Routes = [
  { path: 'avatars', component: AvatarListComponent, canActivate: [AuthenticationGuard] }
];

@NgModule({
  imports: [
    RouterModule.forChild(avatarsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AvatarsRouting { }
