import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  MAT_DIALOG_DATA,
  MatButtonModule,
  MatCardModule,
  MatDialogRef,
  MatIconModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatTableModule,
} from '@angular/material';

import { TestimonialsCreateComponent } from './testimonials-create/testimonials-create.component';
import { TestimonialsDeleteComponent } from './testimonials-delete/testimonials-delete.component';
import { TestimonialsEditComponent } from './testimonials-edit/testimonials-edit.component';
import { TestimonialsListComponent } from './testimonials-list/testimonials-list.component';
import { TestimonialsService } from 'app/AppModules/WebModules/TestimonialsModule/services/testimonials.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule
  ],
  declarations: [
    TestimonialsListComponent,
    TestimonialsCreateComponent,
    TestimonialsEditComponent,
    TestimonialsDeleteComponent
  ],
  exports: [
    TestimonialsListComponent,
    TestimonialsCreateComponent,
    TestimonialsEditComponent,
    TestimonialsDeleteComponent
  ],
  entryComponents: [
    TestimonialsCreateComponent,
    TestimonialsEditComponent,
    TestimonialsDeleteComponent
  ],
  providers: [
    TestimonialsService,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ]
})
export class TestimonialsModule { }
