import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDialogModule, MatDatepickerModule, MatIconModule, MatButtonModule,
  MatCardModule, MatInputModule, MatSelectModule, MatOptionModule,
  MatNativeDateModule, MatTableModule, MatPaginatorModule, MAT_DATE_LOCALE, MatProgressSpinnerModule
} from '@angular/material';
import { MatTabsModule } from '@angular/material/tabs';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AuthenticationGuard } from '../../../core/services/authentication.guard';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { ReportsService } from './services/Reports.service';

import { ReportsGamesBySystemComponent } from './reports-games-by-system/reports-games-by-system.component';
import { ReportsGamesBySystemViewComponent } from './reports-games-by-system/reports-games-by-system-view/reports-games-by-system-view.component';
import { ReportsPaginatorComponent } from './reports-paginator/reports-paginator.component';
import { ReportsMainComponent, ReportsTimePurchasesComponent, ReportsPurchaseCountComponent, ReportsTopStationsComponent, ReportsExtraTimePurchasesComponent, ReportsTopGameSystemsComponent, ReportsTopCategoriesComponent, ReportsTopTimePurchasesComponent, ReportsTopSystemsComponent } from './Module';
import { ReportsGroupMatchComponent } from './reports-group-match/reports-group-match.component';
import { ReportsGroupMatchViewComponent } from './reports-group-match/reports-group-match-view/reports-group-match-view.component';

import { SimpleNotificationsModule } from 'angular2-notifications';

@NgModule({
  imports: [
    CommonModule,
    Ng2SmartTableModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatOptionModule,
    MatTableModule,
    MatPaginatorModule,
    BrowserModule,
    HttpModule,
    MatProgressSpinnerModule,
    SimpleNotificationsModule
  ],
  declarations: [
    ReportsMainComponent,
    ReportsTimePurchasesComponent,
    ReportsPurchaseCountComponent,
    ReportsTopStationsComponent,
    ReportsExtraTimePurchasesComponent,
    ReportsTopGameSystemsComponent,
    ReportsTopCategoriesComponent,
    ReportsTopTimePurchasesComponent,
    ReportsTopSystemsComponent,
    ReportsGamesBySystemComponent,
    ReportsGamesBySystemViewComponent,
    ReportsGroupMatchComponent,
    ReportsGroupMatchViewComponent,
    ReportsPaginatorComponent
  ],
  exports: [
    ReportsMainComponent,
    ReportsTimePurchasesComponent,
    ReportsPurchaseCountComponent,
    ReportsTopStationsComponent,
    ReportsExtraTimePurchasesComponent,
    ReportsTopGameSystemsComponent,
    ReportsTopCategoriesComponent,
    ReportsTopTimePurchasesComponent,
    ReportsTopSystemsComponent,
    ReportsGamesBySystemComponent,
    ReportsGamesBySystemViewComponent,
    ReportsPaginatorComponent
  ],
  providers: [
    AuthenticationGuard,
    ReportsService,
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ]
})
export class ReportsModule { }
