import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

import * as Model from '../models/index';
import { TournamentGamesService } from '../services/tournamentGames.service';

@Component({
  selector: 'app-tournament-games-edit',
  templateUrl: './tournament-games-edit.component.html',
  styleUrls: ['./tournament-games-edit.component.css']
})
export class TournamentGamesEditComponent {
  public loading = false;
  @Input() public gameBody: Model.Game;
  public formArray: FormArray;
  public tournamentGameBody: FormGroup;

  constructor(
    private tournamentGamesService: TournamentGamesService,
    public dialogRef: MatDialogRef<TournamentGamesEditComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    if(data){
      this.gameBody = data;
    }
    this.tournamentGameBody = new FormGroup({
        'name': new FormControl(this.gameBody.name, [
          Validators.required
        ])
      });
   }

  public editTournamentGame(): void {
    this.loading = !this.loading;

    this.tournamentGamesService.putTournamentGame(this.gameBody).subscribe(
      (data: Model.Game) => {
        this.loading != this.loading;
        this.dialogRef.close(data);
      },
      (error: Error) => {
        this.loading = !this.loading;
      });
  }
}
