import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import 'rxjs/add/operator/switchMap';
import { LocalDataSource } from 'ng2-smart-table';


import { UsersService } from '../../../../core/services/Users.service';
import * as Model from '../../../../core/models/Model';
import * as Module from '../Module';

@Component({
  selector: 'user-view',
  inputs: ['user', 'locations', "users", "source", "event"],
  templateUrl: './view.users.component.html',
  styleUrls: ['./view.users.component.css']
})

export class UserViewComponent {
  locations: Model.Location[];
  user: Model.User;
  users: Model.User[];
  editUser: boolean;
  event: any;
  source: LocalDataSource;


  constructor(
    private usersService: UsersService,
    public location: Location,
    public dialog: MatDialog
  ) { }

  goBack(): void {
    this.location.back();
  }

  EditUser(): void {
    const dialogRef = this.dialog.open(Module.UserEditionComponent, {
      data: {
        user: this.user,
        locations: this.locations
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.user = data;
        this.users[this.users.findIndex(user => data.id == this.user.id)] = data;
        this.source.update(this.event, {
          role: data.role,
          position: data.position,
          location: data.belongsTo
        });
      }
    });
  }

  DeactivateUser(): void {
    const dialogRef = this.dialog.open(Module.UserDeactivationComponent, {
      data: this.user
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        // delete this.users[this.users.findIndex(user => data.id == this.user.id)];
        this.source.remove(this.event);
        this.closeMenu();
      }
    });
  }

  public closeMenu() {
    // this.editUser = !this.editUser;
    const table = document.querySelector('.tableGent');
    table.classList.remove('tableMin');
    const detail = document.querySelector('.detail');
    detail.classList.remove('visible');
    detail.classList.add('hidden');
  }

}
