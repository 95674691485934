import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import * as Model from '../../../../../core/models/Model';
import * as Service from '../../../../../core/services/Service';

@Component({
  selector: 'app-location-cash-closing-confirmation',
  templateUrl: './location-cash-closing-confirmation.component.html',
  styleUrls: ['./location-cash-closing-confirmation.component.scss']
})
export class LocationCashClosingConfirmationComponent {
  public loading = false;

  constructor(
    private locationInfoService: Service.LocationInfoService,
    public dialogRef: MatDialogRef<LocationCashClosingConfirmationComponent>
  ) { }

  public confirmCashClosing(): void {
    this.loading = !this.loading;
    this.locationInfoService.getBalancing().subscribe(
      (data: any) => {
        this.loading = !this.loading;
        this.dialogRef.close();
      },
    (error: any) => {
      this.loading = !this.loading;
    });
  }
}
