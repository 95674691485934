import { Inject, Injectable, Optional } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { ResponsesService } from './Responses.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { EnvironmentURL } from './Config';
import { JWTHandler } from './JWT.handler';

import * as Model from '../models/Model';

@Injectable()
export class CountriesService {
  protected basePath;
  public defaultHeaders: Headers = new Headers();
  

  constructor(
    protected http: Http,
    private responsesService: ResponsesService
  ) {
    let urlProvider = new EnvironmentURL();
    this.basePath = urlProvider.GetDomainURL();
    
  }

  public GetCountries(name?: string, currency?: string, isoCode?: string): Observable<Model.Country[]> {
    return this.GetCountriesHttp(name, currency, isoCode)
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  private GetCountriesHttp(name?: string, currency?: string, isoCode?: string): Observable<Response> {
    const path = this.basePath + `/countries`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (name !== undefined)
      queryParameters.set('name', <any>name);

    if (currency !== undefined)
      queryParameters.set('currency', <any>currency);

    if (isoCode !== undefined)
      queryParameters.set('isoCode', <any>isoCode);

    
    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }
}
