import { NgModule } from '@angular/core';
import { MatDialogModule, MatIconModule, MatButtonModule,
  MatCardModule, MatInputModule, MatSelectModule, MatOptionModule,
  MatTableModule, MatPaginatorModule, MatDialogRef, MAT_DIALOG_DATA
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AuthenticationGuard } from '../../../core/services/authentication.guard';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import * as Module from './Module';

import * as Service from '../../../core/services/Service';

@NgModule({
  declarations: [
    Module.TimerListComponent,
    Module.TimerActivationComponent,
    Module.TimerDeactivationComponent
  ],
  entryComponents: [
    Module.TimerActivationComponent,
    Module.TimerDeactivationComponent
  ],
  imports: [
    Ng2SmartTableModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatTableModule,
    MatPaginatorModule,
    BrowserModule,
    FormsModule,
    HttpModule,
    Module.TimerRouting
  ],
  exports: [
    Module.TimerListComponent,
    Module.TimerActivationComponent,
    Module.TimerDeactivationComponent
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    AuthenticationGuard,
    Service.TimersService
  ]
})
export class TimersModule { }
