import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LocationsService } from '../../../../core/services/Locations.service';

@Component({
  selector: 'app-user-activation',
  templateUrl: './activate.location.component.html',
  styleUrls: ['./activate.location.component.scss']

})

export class LocationActivationComponent {
  public loading = false;

  constructor(
    private locationsService: LocationsService,
    public dialogRef: MatDialogRef<LocationActivationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public ActivateLocation(): void {
    this.loading = true;
    this.locationsService.GetLocationCode(this.data.location.code)
      .subscribe(
      data => {
        this.loading = !this.loading;

        this.dialogRef.close(data);
      },
      error => {
        this.loading = false;
      });
  }
}
