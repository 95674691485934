import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthenticationGuard } from './core/services/authentication.guard';

const appRoutes: Routes = [
  { path: '', loadChildren: 'app/AppModules/LoginModule/login.module#LoginModule' },
  // { path: '', loadChildren: 'app/AppModules/HomeModule/home.module#HomeModule', canActivate: [AuthenticationGuard] },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full'
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }

