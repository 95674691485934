import { LocationsService } from './Locations.service';
import { Inject, Injectable, Optional } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { Router } from '@angular/router';
import { URLPath } from './Config';

import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import * as Model from '../models/Model';
import { JWTHandler } from './JWT.handler';
import { ResponsesService } from './Responses.service';

@Injectable()
export class AuthenticationService {
  protected basePath = URLPath;
  public defaultHeaders: Headers = new Headers();


  constructor(
    protected http: Http,
    private responsesService: ResponsesService
  ) { }

  public UserLogin(credentials: Model.Credentials) {
    return this.userLogInHttp(credentials)
      .map((response: Response) => {
        localStorage.setItem('user', JSON.stringify(response.json()));
        return this.responsesService.PostUserResponse(response, 'Bienvenido', '').json();
      });
  }

  public UserLogout(): Observable<any> {
    return this.userLogOutHttp()
      .map(response => {
        localStorage.removeItem('user');
        localStorage.removeItem('authorization');
        return this.responsesService.PostUserResponse(response, 'Éxito', 'Sesión cerrada.');
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.PostUserResponse(error, 'Conflicto', 'Sesión iniciada en otro navegador.'));
      });
  }

  public UserPasswordRecovery(email): Observable<any> {
    return this.userRecoveryPasswordHttp(email)
      .map(response => {
        return this.responsesService.PostUserResponse(response, 'Éxito', 'Correo enviado.');
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.PostUserResponse(error, 'Conflicto', 'Correo no existente.'));
      });
  }

  private userLogInHttp(credentials: Model.Credentials): Observable<Response> {
    const path = this.basePath + '/users/login';
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (credentials === null || credentials === undefined) throw new Error();

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: credentials == null ? '' : JSON.stringify(credentials)
    });

    return this.http.request(path, requestOptions);
  }

  private userRecoveryPasswordHttp(email): Observable<Response> {
    const path = this.basePath + '/templates/user_password';
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (email === null || email === undefined) throw new Error();

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: email == null ? '' : JSON.stringify(email)
    });

    return this.http.request(path, requestOptions);
  }

  private userLogOutHttp(): Observable<Response> {
    const path = this.basePath + '/users/logout';

    const headers = new Headers();


    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      headers: headers,
      method: RequestMethod.Post
    });

    return this.http.request(path, requestOptions);
  }
}
