import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from '../../../core/services/authentication.guard';

import { LocationCreationComponent } from './location-create/create.location.component';
import { LocationListComponent } from './location-list/list.location.component';
import { LocationViewComponent } from './location-view/view.location.component';

const locationsRoutes: Routes = [
  { path: 'locations', component: LocationListComponent, canActivate: [AuthenticationGuard] },
  { path: 'locations/create', component: LocationCreationComponent, canActivate: [AuthenticationGuard] },
  { path: 'location/:id', component: LocationViewComponent, canActivate: [AuthenticationGuard] }
];

@NgModule({
  imports: [
    RouterModule.forChild(locationsRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class LocationsRouting { }
