import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material';

import * as Model from '../../../../core/models/Model';
import * as Module from '../Module';
import * as Service from '../../../../core/services/Service';

@Component({
  selector: 'app-timer-list',
  templateUrl: './list.timers.component.html',
  styleUrls: ['./list.timers.component.scss']
})

export class TimerListComponent implements OnInit {
  public timerList: MatTableDataSource<Model.Timer>;
  public isLoadingTimers = false;
  public displayedColumns: string[] = ['name', 'station', 'status', 'validate', 'delete'];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  private _timers: Model.Timer[];
  public get timers(): Model.Timer[] {
    return this.timers;
  }
  public set timers(value: Model.Timer[]) {
    this.isLoadingTimers = true;
    this.timersService.getTimers(null, null, false).subscribe(
      (data: Model.Timer[]) => {
        this._timers = data;
        this.timerList = new MatTableDataSource(this._timers);
        this.timerList.paginator = this.paginator;
        this.isLoadingTimers = false;
        this.timerList.filterPredicate =
          (data: any, filter: string) => data.name.toLowerCase().trim().includes(filter.toString());
      });
  }
  constructor(
    private timersService: Service.TimersService,
    public dialog: MatDialog
  ) { }

  public ngOnInit(): void {
    this.timers = [];
  }

  public onSearch(query: string = '') {
    query = query.trim();
    query = query.toLowerCase();
    this.timerList.filter = query;
  }

  public activateTimer(timer: Model.Timer): void {
    const dialogRef = this.dialog.open(Module.TimerActivationComponent, {
      data: timer
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data !== null || data !== undefined) {
          this.timerList.data.find((timerToFind: Model.Timer) => {
            if (timerToFind.subject === data.subject) {
              timerToFind.acceptedAt = data.acceptedAt;
            }
            return timerToFind.subject === data.subject;
          });
          this.timerList.data = this.timerList.data;
        }
      },
      error => {
      });
  }

  public deleteTimer(timer: Model.Timer): void {
    const dialogRef = this.dialog.open(Module.TimerDeactivationComponent, {
      data: timer
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data !== null || data !== undefined) {
          this.timerList.data = this.timerList.data.filter((timerToExclude) => {
            return timerToExclude.subject !== data.subject;
          });
        }
      },
      error => {
      });
  }
}
