import { NgModule } from '@angular/core';
import { MatDialogModule, MatIconModule, MatButtonModule, MatCardModule, MatInputModule, MatSelectModule, MatOptionModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AuthenticationGuard } from '../../core/services/authentication.guard';

import * as Module from './Module';
import * as Service from '../../core/services/Service';

@NgModule({
  declarations: [
    Module.SwitcherComponent,
    Module.SwitcherViewComponent
  ],
  entryComponents: [
    Module.SwitcherViewComponent
  ],
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    BrowserModule,
    HttpModule,
  ],
  exports: [
    Module.SwitcherComponent,
    Module.SwitcherViewComponent
  ],
  providers: [
    AuthenticationGuard,
    Service.LocationsService
  ]
})
export class SwitcherModule { }
