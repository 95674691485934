import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthenticationGuard } from '../../../core/services/authentication.guard';

import { StationListComponent, StationViewComponent, StationCreationComponent } from './Module';

const usersRoutes: Routes = [
  { path: 'stations', component: StationListComponent, canActivate: [AuthenticationGuard] }
];

@NgModule({
  imports: [
    RouterModule.forChild(usersRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class StationsRouting { }
