import { ValidationService } from './../../../../../core/services/ValidationForms.service';
import { Location } from './../../../../../core/models/Location.model';
import { LocationsService } from './../../../../../core/services/Locations.service';
import { System } from './../../../../OnsiteModules/GamesModule/models/system.model';
import { TournamentBody } from './../models/tournamentBody.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { TournamentsService } from '../services/tournaments.service';
import { Tournament, TournamentProduct } from '../models';

@Component({
  selector: 'app-tournaments-edit',
  templateUrl: './tournaments-edit.component.html',
  styleUrls: ['./tournaments-edit.component.scss']
})
export class TournamentsEditComponent implements OnInit {
  public coverMessage = false;
  public bannerMessage = false;
  @Input() public isModal: boolean;
  @Input() public locations: Location[];
  @Input() public tournament: Tournament;
  public validForm = false;

  public loading = false;
  public formArray: FormArray;
  public tournamentBody: FormGroup;
  public tournamentDate: Date = new Date();
  public limitDate: Date = new Date();
  public todayDate: Date = new Date();
  public minTeams = 2;
  public scheduledAt: Date;

  public _scheduledHour: Date;
  public get scheduledHour(): Date {
    return this._scheduledHour;
  }
  public set scheduledHour(value: Date) {
    this.scheduledAt.setHours(value.getHours());
    this.scheduledAt.setMinutes(value.getMinutes());
    this._scheduledHour = value;
  }
  public imageLoaded: any = {
    cover: { loaded: false, file: '' },
    banner: { loaded: false, file: '' }
  };

  public fileLoaded: any = {
    rules: { loaded: false, file: '' },
    setup: { loaded: false, file: '' }
  };

  public get location(): Location {
    return this.locations.find((locationToFind: Location) => locationToFind.id === this.tournament.locationId);
  }

  public _systems: System[];
  public get systems(): System[] {
    return this._systems;
  }
  public set systems(systems: System[]) {
    if (this.location) {
      this.tournamentsService.getSystems(this.location.domain).subscribe((data: System[]) => {
        this._systems = data ? data : [];
      });
    }
  }

  public _tournamentProducts = [];
  public get tournamentProducts(): TournamentProduct[] {
    return this._tournamentProducts;
  }
  public set tournamentProducts(value: TournamentProduct[]) {
    this.tournamentsService.getTournamentProducts(this.location.id).subscribe(data => (this._tournamentProducts = data));
  }

  constructor(
    public tournamentsService: TournamentsService,
    public dialogRef: MatDialogRef<TournamentsEditComponent>,
    public formBuilder: FormBuilder,
    public locationsService: LocationsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.locations = data.locations;
      this.tournament = data.tournament;
    }

    this.formArray = new FormArray([
      new FormGroup({
        tournamentProductId: new FormControl(this.tournament.productId, [Validators.required]),
        name: new FormControl(this.tournament.name, [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(120)]),
        scheduledAt: new FormControl(this.scheduledAt, [Validators.required]),
        minTeams: new FormControl(this.tournament.minTeams, [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(3),
          ValidationService.minValue(2),
          ValidationService.integerValidator()]),
        maxTeams: new FormControl(this.tournament.maxTeams, [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(3),
          ValidationService.integerValidator()
        ]),
        systemId: new FormControl(this.tournament.systemId, [Validators.required])
      }),
      new FormGroup({
        cover: new FormControl('', [ValidationService.imageSizeValidator(512000)]),
        banner: new FormControl('', [ValidationService.imageSizeValidator(512000)]),
        rules: new FormControl('', [ValidationService.imageSizeValidator(1000000)]),
        setup: new FormControl('', [ValidationService.imageSizeValidator(1000000)])
      })
    ]);
    this.tournamentBody = new FormGroup({ formArray: this.formArray });
  }

  public ngOnInit(): void {
    this.systems = [];
    this.tournamentProducts = [];
    this.limitDate.setMonth(12);
    this.scheduledAt = new Date(this.tournament.scheduledAt * 1000);
    this.scheduledHour = new Date(this.tournament.scheduledAt * 1000);
  }

  public editTournament(): void {
    if(this.tournamentBody.valid) {

      const tournament: TournamentBody = Object.assign(this.formArray.value[0], this.formArray.value[1]);

      tournament.scheduledAt = new Date(this.formArray.at(0).get('scheduledAt').value).getTime() / 1000;
      this.tournamentsService.putTournament(this.tournament.id, tournament).subscribe(
        data => {
          this.dialogRef.close(data);
        },
        error => {
  
        });
    } else
      this.validForm = true;
  }

  public handleImageLoad(property: string): void {
    this.imageLoaded[property].loaded = true;
  }

  public handleInputChange(propertyName: string, e) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    this.formArray
      .at(1)
      .get(propertyName)
      .patchValue(e.target.files[0]);

    const pattern = /image-*/;
    const reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this, propertyName);
    reader.readAsDataURL(file);
  }

  public handleInputFileChange(propertyName: string, e) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    this.formArray
      .at(1)
      .get(propertyName)
      .patchValue(e.target.files[0]);

    const reader = new FileReader();
    reader.onload = this._handleFileReaderLoaded.bind(this, propertyName);
    reader.readAsDataURL(file);
  }

  public _handleReaderLoaded(property, e): void {
    const reader = e.target;
    this.imageLoaded[property].file = reader.result;
  }

  public _handleFileReaderLoaded(property, e): void {
    const reader = e.target;
    this.fileLoaded[property].file = reader.result;
  }
}
