import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import * as Model from '../models/index';
import { TournamentGamesService } from '../services/tournamentGames.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-tournament-games-create',
  templateUrl: './tournament-games-create.component.html',
  styleUrls: ['./tournament-games-create.component.css']
})
export class TournamentGamesCreateComponent {
  public loading = false;
  public gameBody: Model.GameBody = <Model.GameBody> { };
  public validForm = false;
  public tournamentBody: FormGroup;

  constructor(
    private tournamentGamesService: TournamentGamesService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<TournamentGamesCreateComponent>
  ) {
    this.tournamentBody = this.formBuilder.group({
      'name': ['', [
        Validators.required
      ]]
    });
  }

  public createTournamentGame(): void {
    if(this.tournamentBody.valid) {
      this.loading = !this.loading;
      this.gameBody.name = this.tournamentBody.value.name;
      this.tournamentGamesService.postTournamentGame(this.gameBody).subscribe(
        (data: Model.Game) => {
          this.loading != this.loading;
          this.dialogRef.close(data);
        },
        (error: Error) => {
          this.loading = !this.loading;
        });
    } else
      this.validForm = true;
  }
}
