import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Headers, Http, URLSearchParams } from '@angular/http';
import {
  RequestMethod,
  RequestOptions,
  RequestOptionsArgs
} from '@angular/http';

import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { URLPath } from '../../../../core/services/Config';
import { URLOnsite } from '../../../../core/services/Config';
import { ResponsesService } from '../../../../core/services/Responses.service';
import { JWTHandler } from '../../../../core/services/Service';
import { Testimonial } from '../models/testimonial.model';
import { TestimonialBody } from '../models/testimonialBody.model';

@Injectable()
export class TestimonialsService {
  protected basePath = '';
  public defaultHeaders: Headers = new Headers();

  constructor(
    protected http: Http,
    private responsesService: ResponsesService
  ) {
    this.basePath = URLPath;
  }

  public getTestimonials(): Observable<Testimonial[]> {
    return this.getTestimonialsWithHttpInfo()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(
          this.responsesService.GenericGetResponse(error)
        );
      });
  }

  public postTestimonial(testimonialBody: TestimonialBody): Observable<Testimonial> {
    return this.postTestimonialWithHttpInfo(testimonialBody)
      .map((response: Response) => {
        return this.responsesService
          .GenericPostResponse(response, 'Éxito', 'Testimonio creado.')
          .json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(
          this.responsesService.GenericPostResponse(error)
        );
      });
  }

  public putTestimonial(
    testimonialId: number,
    testimonialBody: TestimonialBody
  ): Observable<Testimonial> {
    return this.putTestimonialWithHttpInfo(testimonialId, testimonialBody)
      .map((response: Response) => {
        return this.responsesService
          .GenericPostResponse(response, 'Éxito', 'Testimonio editado.')
          .json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(
          this.responsesService.GenericPostResponse(error)
        );
      });
  }

  public deleteTestimonial(testimonialId: number): Observable<any> {
    return this.deleteTestimonialWithHttpInfo(testimonialId)
      .map((response: Response) => {
        return this.responsesService
          .GenericPostResponse(response, 'Éxito', 'Testimonio eliminado.')
          .json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(
          this.responsesService.GenericPostResponse(error)
        );
      });
  }

  private getTestimonialsWithHttpInfo(): Observable<Response> {
    const path = this.basePath + `/testimonials`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private postTestimonialWithHttpInfo(
    testimonialBody: TestimonialBody
  ): Observable<Response> {
    const path = this.basePath + `/testimonials`;

    const formData: FormData = new FormData();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    for (const property in testimonialBody) {
      if (property) {
        if (testimonialBody[property]) {
          formData.append(property, testimonialBody[property]);
        }
      }
    }

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers
    });
    return this.http.post(path, formData, requestOptions);
  }

  private putTestimonialWithHttpInfo(
    testimonialId: number,
    testimonialBody: TestimonialBody
  ): Observable<Response> {
    const path = this.basePath + `/testimonials/${testimonialId}`;

    const headers = new Headers(this.defaultHeaders.toJSON());
    const formData: FormData = new FormData();

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    for (const property in testimonialBody) {
      if (property) {
        if (testimonialBody[property]) {
          formData.append(property, testimonialBody[property]);
        }
      }
    }

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers
    });

    return this.http.put(path, formData, requestOptions);
  }

  private deleteTestimonialWithHttpInfo(testimonialId: number): Observable<Response> {
    const path = this.basePath + `/testimonials/${testimonialId}`;

    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Delete,
      headers: headers
    });

    return this.http.request(path, requestOptions);
  }
}
