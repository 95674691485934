import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { ValidationService } from '../../../../core/services/Service';
import { NewsFeed } from '../models/news-feed.model';
import { NewsFeedService } from './../services/news-feed.service';

@Component({
  selector: 'app-news-feed-edit',
  templateUrl: './news-feed-edit.component.html',
  styleUrls: ['./news-feed-edit.component.scss']
})
export class NewsFeedEditComponent {
  public newsFeedBody: FormGroup;
  public loading = false;
  public loaded = false;
  public imageLoaded = false;
  public imageSrc = '';
  public message = false;
  public validForm = false;

  constructor(
    private newsFeedService: NewsFeedService,
    public dialogRef: MatDialogRef<NewsFeedEditComponent>,
    @Inject(MAT_DIALOG_DATA) public newsFeed: NewsFeed
  ) {
    this.newsFeedBody = new FormGroup({
      'picture': new FormControl('', [
        ValidationService.imageSizeValidator(512000)
      ]),
      'title': new FormControl(this.newsFeed.title, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(255)
      ]),
      'url': new FormControl(this.newsFeed.url, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(255),
        ValidationService.urlValidator()
      ]),
      'description': new FormControl(this.newsFeed.description, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(255)
      ])
    });
  }

  public get picture(): AbstractControl { return this.newsFeedBody.get('picture'); }

  public editNewsFeed() {
    if(this.newsFeedBody.valid) {
      this.loading = true;
      this.newsFeedService.putNewsFeed(this.newsFeed.id, this.newsFeedBody.value).subscribe(
        data => {
          this.loading = false;
          this.dialogRef.close(data);
        },
        error => {
          this.loading = false;
        });
    } else
      this.validForm = true;
  }

  public handleImageLoad() {
    this.imageLoaded = true;
  }

  public handleInputChange(e) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    this.newsFeedBody.get('picture').setValue(e.target.files[0]);
    const reader = new FileReader();
    this.loaded = false;
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  public _handleReaderLoaded(e) {
    const reader = e.target;
    this.imageSrc = reader.result;
    this.loaded = true;

    const image = new Image();
    image.src = reader.result;
    image.onload = () => {
      if(image.width === 558 && image.height === 263){
        this.message = false;
      } else {
        this.message = true;
      }
    }
  }

}
