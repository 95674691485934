import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';

import { CarouselCreateComponent } from './../carousel-create/carousel-create.component';
import { CarouselDeleteComponent } from './../carousel-delete/carousel-delete.component';
import { Picture } from './../models/picture.model';
import { CarouselService } from './../services/carousel.service';
import { CarouselEditComponent } from 'app/AppModules/WebModules/CarouselModule/carousel-edit/carousel-edit.component';

@Component({
  selector: 'app-carousel-list',
  templateUrl: './carousel-list.component.html',
  styleUrls: ['./carousel-list.component.scss']
})
export class CarouselListComponent implements OnInit {
  @ViewChild(MatPaginator) public paginator: MatPaginator;

  private _pictures: Picture[];
  public pictureList: MatTableDataSource<Picture>;
  public displayedColumns: string[] = ['picture', 'url', 'edit', 'delete'];
  public localTime = null;

  public get pictures(): Picture[] {
    return this._pictures;
  }
  public set pictures(value: Picture[]) {
    this.carouselService.getPictures().subscribe((data: Picture[]) => {
      this._pictures = data;
      this.pictureList = new MatTableDataSource<Picture>(this._pictures);
      this.pictureList.paginator = this.paginator;
    });
  }

  constructor(
    private carouselService: CarouselService,
    private dialog: MatDialog
  ) { }

  public ngOnInit() {
    this.pictures = [];
  }

  public createPicture(): void {
    const dialogRef = this.dialog.open(CarouselCreateComponent, {
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((data: Picture) => {
      if (data) {
        this.pictures.push(data);
        this.pictureList = new MatTableDataSource<Picture>(this._pictures);
        this.pictureList.paginator = this.paginator;
      }
    });
  }

  public redirectToData(imageUrl: string): void {
    let url = '';
    if (!/^http[s]?:\/\//.test(imageUrl)) {
      url += 'http://';
    }

    url += imageUrl;
    window.open(url, '_blank');
  }
  public deletePicture(pictureId: number): void {
    const dialogRef = this.dialog.open(CarouselDeleteComponent, {
      disableClose: true,
      data: pictureId
    });
    dialogRef.afterClosed().subscribe((data: Picture) => {
      if (data) {
        this.pictures = this.pictures.filter(
          picture => picture.id !== pictureId
        );
      }
    });
  }

  public updatePicture(picture: Picture): void {
    const dialogRef = this.dialog.open(CarouselEditComponent, {
      disableClose: true,
      data: picture
    });
    dialogRef.afterClosed().subscribe((data: Picture) => {
      if (data) {
        this.pictures[this.pictures.findIndex(pic => pic.id == picture.id)] = data;
        
        this.pictureList = new MatTableDataSource<Picture>(this._pictures);
        this.pictureList.paginator = this.paginator;
      }
      this.localTime = new Date().getTime();
    });
  }
}
