import * as Model from './Model';

export class GameSystemBodyTable {

  public systemId: number;

  public systemName: string;

  public isActive: boolean;

  public isHighlight: boolean;

  private _stock: number;
  public get stock(): number {
    return this._stock;
  }
  public set stock(value: number) {
    this._stock = (this.isOnHardDrive) ? 1 : value;
  }

  public isOnHardDrive: boolean;

  private _pathToLauncher: string;
  public get pathToLauncher(): string {
    return this._pathToLauncher;
  }
  public set pathToLauncher(value: string) {
    this._pathToLauncher = (this.isOnHardDrive) ? value : null;
  }

  constructor(system: any, isActive: boolean) {
    this.systemId = (system.system) ? system.system.id : system.id;
    this.systemName = (system.system) ? system.system.name : system.name;
    this.isActive = isActive;
    this.isHighlight = (system.isHighlight) ? true : false;
    this.isOnHardDrive = (system.isOnHardDrive || (system.name === 'PC')) ? true : false;
    this.stock = (system.stock) ? system.stock : 0;
    this.pathToLauncher = (system.pathToLauncher) ? system.pathToLauncher : null;
  }
}
