import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { MatDialogModule, MatIconModule, MatButtonModule, MatCardModule, MatInputModule, MatSelectModule, MatOptionModule, MatProgressSpinnerModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ProductListComponent } from './products-list/list.products.component';
import { TimeProductsService } from './services/TimeProducts.service';

import { AuthenticationGuard } from '../../../core/services/authentication.guard';
import { ValidationService } from '../../../core/services/Service';
import { ControlMessagesComponent } from './message.products.component';
import { ProductsHomeComponent } from './products-home/products-home.component';
import { ProductsRouting } from './products.routing';
import { RechargesListComponent } from './recharges-list/recharges-list.component';
import { RechargesService } from './services/Recharges.service';

@NgModule({
  declarations: [
    ControlMessagesComponent,
    ProductsHomeComponent,
    RechargesListComponent,
    ProductListComponent
  ],
  imports: [
    Ng2SmartTableModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    BrowserModule,
    FormsModule,
    MatProgressSpinnerModule,
    HttpModule,
    ReactiveFormsModule,
    ProductsRouting
  ],
  exports: [
    ProductListComponent,
    RechargesListComponent,
    ProductsHomeComponent,
    ControlMessagesComponent
  ],
  providers: [
    AuthenticationGuard,
    ValidationService,
    TimeProductsService,
    RechargesService
  ]
})
export class ProductsModule { }
