import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import * as Service from '../../../../core/services/Service';
import * as Model from '../../../../core/models/Model';

@Component({
  selector: 'app-station-creation',
  templateUrl: './creation.stations.component.html',
  styleUrls: ['./creation.stations.component.scss']
})
export class StationCreationComponent implements OnInit {
  public stationBody: FormGroup;
  public loading = false;
  public validForm  = false;
  public formArray: FormArray;
  public systemArray: string[] = [];
  public categoryId: number;
  public get selectedCategory(): Model.Category {
    return this.categories.find((category) => category.id === this.categoryId);
  }
  public seats: number;
  private _categories: Model.Category[];
  public get categories(): Model.Category[] {
    return this._categories;
  }
  public set categories(value: Model.Category[]) {
    this.stationsService.getCategories().subscribe((data: Model.Category[]) => {
      if (data) this._categories = data;
    });
  }

  private _zones: Model.Zone[];
  public get zones(): Model.Zone[] {
    return this._zones;
  }
  public set zones(value: Model.Zone[]) {
    this.zonesService.getZones().subscribe((data: Model.Zone[]) => {
      if (data) this._zones = data;
    });
  }

  seats_options = [];

  constructor(
    private stationsService: Service.StationsService,
    private zonesService: Service.ZonesService,
    public dialogRef: MatDialogRef<StationCreationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formArray = new FormArray([
      new FormGroup({
        categoryId: new FormControl(this.categoryId, [Validators.required]),
        systemIds: new FormControl('', [Validators.required]),
        seats: new FormControl({value: '', disabled: true}, [Validators.required])
      }),
      new FormGroup({
        name: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z\\d]+$')]),
        zoneId: new FormControl('', [Validators.required]),
        screen: new FormControl('', [
        ]),
        observations: new FormControl('', [])
      })
    ]);

    this.stationBody = new FormGroup({ formArray: this.formArray });
  }

  public ngOnInit() {
    this.categories = [];
    this.zones = [];
  }

  public getSystemsByCategoryId(categoryId: number): Model.System[] {
    return this.categories.find((category: Model.Category) => {
      return category.id == categoryId;
    }).systems;
  }

  public onCheckboxChangeEvent(event: any): void {
    if (event.checked) {
      this.systemArray.push(event.source.value);
    } else {
      this.systemArray = this.systemArray.filter(systemId => systemId !== event.source.value);
    }
    this.formArray
      .at(0)
      .get('systemIds')
      .patchValue(this.systemArray);
  }

  public createStation(): void {
    this.validForm = true;
    if(this.stationBody.valid && this.screenValid()) {
      this.loading = !this.loading;
      const station = Object.assign(this.formArray.value[0], this.formArray.value[1]);
  
      for (let key in station) {
        if (station.hasOwnProperty(key)) {
          if (station[key] === '' || station === ' ' || station === undefined || station == null) {
            delete station[key];
          }
        }
      }
      if (station.observations === undefined) {
        station['observations'] = '';
      }
      if (station.screen === '' || !(this.categoryId && !this.selectedCategory.isPcOnly)) {
        delete station['screen'];
      }
      this.stationsService.postStation(station).subscribe(
        (data: Model.Zone) => {
          this.dialogRef.close(data);
          this.loading = !this.loading;
        },
        error => {
          this.loading = !this.loading;
        }
      );
    }
  }
  setSeatsOptions(category_id){
    for (var i = 0; i < this.categories.length; i++) {
      if (category_id === this.categories[i].id) {
        this.formArray.at(0).get('seats').setValue('')
        this.seats_options = [];
        for (var j = 0; j < this.categories[i].maximumCapacity; j++) {
          this.seats_options.push((j + 1));
        }
        this.formArray.at(0).get('seats').enable();
      }
    }
  }
  screenValid(){
    if (this.categoryId && !this.selectedCategory.isPcOnly) {
      return this.formArray.at(1).get('screen').value;
    }
    return true;
  }
}
