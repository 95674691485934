import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthenticationGuard } from '../../../core/services/authentication.guard';
import { ReportsMainComponent } from './Module';

const reportsRoute: Routes = [
  { path: 'reports', component: ReportsMainComponent, canActivate: [AuthenticationGuard] }
];

@NgModule({
  imports: [
    RouterModule.forChild(reportsRoute)
  ],
  exports: [
    RouterModule
  ]
})

export class ReportsRouting { }
