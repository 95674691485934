import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-avatar-creation-confirmation',
  templateUrl: './avatar-creation-confirmation.component.html',
  styleUrls: ['./avatar-creation-confirmation.component.css']
})
export class AvatarCreationConfirmationComponent {

  constructor(
    public dialogRef: MatDialogRef<AvatarCreationConfirmationComponent>
  ) { }

}
