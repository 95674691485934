import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import * as Model from '../models/index';
import { TournamentModesService } from '../services/tournamentModes.service';

@Component({
  selector: 'app-tournament-modes-create',
  templateUrl: './tournament-modes-create.component.html',
  styleUrls: ['./tournament-modes-create.component.css']
})
export class TournamentModesCreateComponent {
  public loading = false;
  public modeBody: Model.ModeBody = <Model.ModeBody>{};
  public validForm = false;
  public tournamentBody: FormGroup;

  constructor(
    private tournamentModesService: TournamentModesService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<TournamentModesCreateComponent>
  ) {
    this.tournamentBody = this.formBuilder.group({
      'name': ['', [
        Validators.required
      ]]
    });
  }

  public createTournamentMode(): void {
    if (this.tournamentBody.valid) {
      this.loading = !this.loading;
      this.modeBody.name = this.tournamentBody.value.name;
      this.tournamentModesService.postTournamentMode(this.modeBody).subscribe(
        (data: Model.Mode) => {
          this.loading = !this.loading;
          this.dialogRef.close(data);
        },
        (error: Error) => {
          this.loading = !this.loading;
        });
    } else
      this.validForm = true;
  }
}
