import { GamesBySystemService } from './../services/gamesBySystem.service';
import { System } from './../models/system.model';
import { MatDialog } from '@angular/material';
import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';

import { Subscription } from 'rxjs';
import * as Model from '../models/Model';
import { GamesService } from '../services/games.service';
import { GameCreateComponent } from '../game-create/game-create.component';

@Component({
  selector: 'app-game-list',
  templateUrl: './game-list.component.html',
  styleUrls: ['./game-list.component.scss']
})
export class GameListComponent implements OnInit {
  public gameList: MatTableDataSource<Model.Game>;
  public displayedColumns: string[] = ['cover', 'name', 'systems', 'esrb'];
  public localTime : Date;
  subscription: Subscription;

  public selected = false;
  public selectedGame: Model.Game;
  public selectedSystem: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  private _games: Model.Game[];
  public get games(): Model.Game[] {
    return this._games;
  }
  public set games(value: Model.Game[]) {
    this.gamesService.getGames().subscribe(
      data => {
        this._games = data;
        this.gameList = new MatTableDataSource<Model.Game>(this._games);
        this.gameList.paginator = this.paginator;
      });
  }

  private _systems: System[];
  public get systems(): System[] {
    return this._systems;
  }
  public set systems(value: System[]) {
    this.gamesBySystemService.getSystems().subscribe(
      (data: Model.System[]) => {
        if (data !== undefined || data !== null) {
          this._systems = data;
        }
      });
  }

  constructor(
    private gamesService: GamesService,
    private gamesBySystemService: GamesBySystemService,
    public dialog: MatDialog
  ) {
    this.subscription = this.gamesService.getTime().subscribe(localTime => this.localTime = localTime);
  }

  public ngOnInit() {
    this.games = [];
    this.systems = [];
  }

  public onSelect(game: Model.Game) {
    this.selected = true;
    this.selectedGame = game;
    const table = document.querySelector('.tableGent');
    table.classList.add('tableMin');
    const detail = document.querySelector('.detail');
    detail.classList.add('visible');
    detail.classList.remove('hidden');
  }

  public createGame(): void {
    const dialogRef = this.dialog.open(GameCreateComponent, {
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.games.push(data);
          this.gameList = new MatTableDataSource<Model.Game>(this.games);
        }
      }
    );
  }

  public onSearch(query: string = ''): void {
    this.gameList.filterPredicate = (data:
      {name: string}, filterValue: string) =>
      data.name.trim().toLowerCase().indexOf(filterValue) !== -1;

        this.gameList.filter = query.trim().toLowerCase();

  }

  public onSearchBySystem(query: string): void {
    this.gameList.filterPredicate = (data: Model.Game, filter: string) => {
      const availableSystems = data.gameSystems.filter(gameSystem => gameSystem.system.id ===  Number(filter));
      return availableSystems.length > 0;
    }
    this.gameList.filter = query;
  }
}
