import { MatTabsModule } from '@angular/material/tabs';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TournamentsSearchComponent } from './tournaments-search/tournaments-search.component';
import { TournamentsHistoricalComponent } from './tournaments-historical/tournaments-historical.component';
import {
  MatDialogModule,
  MatDatepickerModule,
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatInputModule,
  MatSelectModule,
  MatOptionModule,
  MatNativeDateModule,
  MatTableModule,
  MatPaginatorModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TournamentMainComponent } from './tournament-main/tournament-main.component';
import { TournamentViewComponent } from './tournaments-historical/tournament-view/tournament-view.component';
import { TournamentViewWinnersComponent } from './tournaments-historical/tournament-view-winners/tournament-view-winners.component';
import { TournamentHandlerService } from './services/tournamentHandler.service';
import { TournamentsService } from '../TournamentsModule/services/tournaments.service';
import { TournamentGroupTeamsComponent } from './tournaments-historical/tournament-group-teams/tournament-group-teams.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatDatepickerModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatTabsModule,
    MatOptionModule,
    MatNativeDateModule,
    MatTableModule,
    MatPaginatorModule
  ],
  declarations: [
    TournamentsSearchComponent,
    TournamentViewWinnersComponent,
    TournamentViewComponent,
    TournamentMainComponent,
    TournamentsHistoricalComponent,
    TournamentViewWinnersComponent,
    TournamentGroupTeamsComponent
  ],
  exports: [
    TournamentsSearchComponent,
    TournamentViewWinnersComponent,
    TournamentMainComponent,
    TournamentViewComponent,
    TournamentsHistoricalComponent,
    TournamentGroupTeamsComponent
  ],
  entryComponents: [TournamentGroupTeamsComponent],
  providers: [TournamentHandlerService, TournamentsService]
})
export class TournamentReportsModule {}
