import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import {
  LocationCashClosingConfirmationComponent
} from './location-cash-closing-confirmation/location-cash-closing-confirmation.component';

@Component({
  selector: 'app-location-cash-closing',
  templateUrl: './location-cash-closing.component.html',
  styleUrls: ['./location-cash-closing.component.scss']
})
export class LocationCashClosingComponent {

  constructor(
    public dialog: MatDialog
  ) { }

  public launchCashClosingConfirmation(): void {
    const dialogRef = this.dialog.open(LocationCashClosingConfirmationComponent);
  }

}
