import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';

import * as Model from '../../../../core/models/Model';
import * as Service from '../../../../core/services/Service';
import * as Module from '../Module';

@Component({
  selector: 'app-station-list',
  templateUrl: './list.stations.component.html',
  styleUrls: ['./list.stations.component.scss']
})
export class StationListComponent implements OnInit {
  public source: LocalDataSource;
  public categories: string[];
  public currentStation: Model.Station;
  public event: any;
  public isLoadingData: boolean = false;
  public selected: boolean = false;
  public settings = {
    actions: false,
    columns: {
      name: {
        title: 'Nombre',
        filter: false
      },
      screen: {
        title: 'Pantalla',
        filter: false
      },
      category: {
        title: 'Categoría',
        filter: false
      },
      zone: {
        title: 'Zona',
        filter: false
      },
      status: {
        title: 'Estatus',
        filter: false
      }
    }
  };

  private _stations: Model.Station[];
  public get stations(): Model.Station[] {
    return this._stations;
  }
  public set stations(value: Model.Station[]) {
    this.isLoadingData = true;
    this.source
      .empty()
      .then()
      .catch();
    this.stationsService.getStationsMatches().subscribe((data: Model.Station[]) => {
      this._stations = data;
      for (let i = 0; i < data.length; i++) {
        this.source
          .add({
            stationId: data[i].id,
            name: data[i].name,
            screen: data[i].screen ? data[i].screen : 'No aplica',
            category: data[i].category ? data[i].category.name : '',
            zone: data[i].zone ? data[i].zone.name : '',
            status: data[i].match && data[i].match.isTimeRunning ? 'Activo' : 'Inactivo'
          })
          .then()
          .catch();
      }
      this.source.refresh();
      this.categories = this.ClearCategories(data);
      this.isLoadingData = false;
    });
  }

  constructor(private router: Router, private stationsService: Service.StationsService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.source = new LocalDataSource();
    this.stations = [];
  }

  public onSearch(query) {
    this.source.setFilter(
      [
        {
          field: 'name',
          search: query
        }
      ],
      true
    );
  }

  public onSearchCategory(query) {
    this.source.addFilter(
      {
        field: 'category',
        search: query
      },
      true
    );
  }

  public onSelect(event) {
    this.event = event.data;
    this.selected = true;
    this.currentStation = this.stations.find(stationToFind => stationToFind.id === event.data.stationId);
    const table = document.querySelector('.tableGent');
    table.classList.add('tableMin');
    const detail = document.querySelector('.detail');
    detail.classList.add('visible');
    detail.classList.remove('hidden');
  }

  public CreateStation() {
    const dialogRef = this.dialog.open(Module.StationCreationComponent, {
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.stations.push(data);
        this.source
          .add({
            stationId: data.id,
            name: data.name,
            screen: data.name,
            category: data.category ? data.category.name : '',
            zone: data.zone ? data.zone.name : '',
            status: 'Inactivo'
          })
          .then()
          .catch();
        this.source.refresh();
      }
    });
  }

  private ClearCategories(stationsList: Model.Station[]): string[] {
    let categoriesList: string[];
    categoriesList = stationsList.map(station => station.category.name);
    categoriesList.sort();
    let toDelete = categoriesList.filter(function(elem, index, self) {
      return index == self.indexOf(elem);
    });
    return toDelete;
  }
}
