import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as Model from '../models/Model';

@Injectable()
export class AuthenticationGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('user')) {
      const token: Model.User = JSON.parse(localStorage.getItem('user'));
      const validate = token.accessKey.expiresAt < Date.now();
      if (validate)
        return true;
    }
    // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    this.router.navigate(['/login']);
    return false;
  }

  getAuthenticationValues(): string {
    let authorization: string = localStorage.getItem('authorizaion');
    if (authorization !== undefined || authorization !== null) {
      return 'https://local.offsite.gamersarena.com.mx';
    }
  }

}