import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';

import { Country, Location } from '../../../../core/models/Model';
import { CountriesService } from '../../../../core/services/Countries.service';
import { LocationsService } from '../../../../core/services/Locations.service';
import { LocationCreationComponent } from '../location-create/create.location.component';

@Component({
  selector: 'app-locations-list',
  templateUrl: './list.location.component.html',
  styleUrls: ['./list.location.component.scss']
})

export class LocationListComponent implements OnInit {
  public locations: Location[];
  private event: any;
  public currentLocation: Location;
  private createLocation: boolean = false;
  public source: LocalDataSource;
  public countries: Country[];
  public cityList: String[] = [];

  settings = {
    actions: false,
    columns: {
      name: {
        title: 'Sucursal',
        filter: false
      },
      country: {
        title: 'País',
        filter: false
      },
      city: {
        title: 'Ciudad',
        filter: false
      },
      status: {
        title: 'Estatus',
        filter: false
      }
    }
  };

  constructor(
    private router: Router,
    private locationsService: LocationsService,
    private countriesService: CountriesService,
    private dialog: MatDialog
  ) { }

  public onSearch(query: string = '') {
    this.source.setFilter([
      {
        field: 'name',
        search: query
      }
    ], true);
  }

  public onSearchCountry(query) {
    this.source.addFilter(
      {
        field: 'country',
        search: query
      }
      , true);
  }

  public onSearchCity(query) {
    this.source.addFilter(
      {
        field: 'city',
        search: query
      }
      , true);
  }

  private getLocations(): void {
    this.locationsService.GetLocations().subscribe(
      locationsFromHttp => {
        this.locations = locationsFromHttp;
        for (let i = 0; i < locationsFromHttp.length; i++) {
          this.source.add({
            id: locationsFromHttp[i].id,
            name: locationsFromHttp[i].name,
            country: locationsFromHttp[i].country.name,
            city: locationsFromHttp[i].locationInfo.city,
            status: (locationsFromHttp[i].acceptedAt) ? 'Validada' : 'Por validar'
          });
          this.cityList.push(locationsFromHttp[i].locationInfo.city);
        }
        this.cityList = this.cityList.filter((x, i, a) => a.indexOf(x) === i);
        this.source.refresh();
      },
      error => {

      });
  }

  private getCountries(): void {
    this.countriesService.GetCountries().subscribe(
      data => {
        this.countries = data;
      },
      error => {

      });
  }

  public ngOnInit(): void {
    this.source = new LocalDataSource();
    this.getCountries();
    this.getLocations();
  }


  public onSelect(event: any) {
    this.event = event;
    this.currentLocation = this.locations.find(locationToFind => locationToFind.id === event.data.id);
    const table = document.querySelector('.tableGent');
    table.classList.add('tableMin');
    const detail = document.querySelector('.detail');
    detail.classList.add('visible');
    detail.classList.remove('hidden');
  }

  public CreateLocation() {
    const dialogRef = this.dialog.open(LocationCreationComponent, {
      data: this.countries,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.locations.push(data);
          this.source.add({
            id: data.id,
            name: data.name,
            country: data.country.name,
            city: data.locationInfo.city,
            status: (data.acceptedAt) ? 'Validada' : 'Por Validar'
          }).then().catch();
          this.source.refresh();

          this.cityList.push(data.locationInfo.city);
          this.cityList = this.cityList.filter((x, i, a) => a.indexOf(x) === i);
        }
      }
    );
  }


}
