import { Component, OnInit, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { ValidationService } from '../../../../core/services/Service';
import { Picture } from '../models/picture.model';
import { CarouselService } from '../services/carousel.service';

@Component({
  selector: 'app-carousel-edit',
  templateUrl: './carousel-edit.component.html',
  styleUrls: ['./carousel-edit.component.scss']
})
export class CarouselEditComponent {
  public pictureBody: FormGroup;
  public loading = false;
  public loaded = false;
  public imageLoaded = false;
  public imageSrc = '';
  public message = false;
  public validForm = false;
  public date_aux = new Date();
  public now = this.date_aux.getTime();

  constructor(
    private carouselService: CarouselService,
    public dialogRef: MatDialogRef<CarouselEditComponent>,
    @Inject(MAT_DIALOG_DATA) public carousel: Picture
  ) {
    this.pictureBody = new FormGroup({
      'url': new FormControl(this.carousel.url, [
        ValidationService.urlValidator()
      ]),
      'picture': new FormControl('', [
        ValidationService.imageSizeValidator(512000)
      ])
    });
  }

  public get picture(): AbstractControl { return this.pictureBody.get('picture'); }
  public get url(): AbstractControl { return this.pictureBody.get('url'); }

  public updatePicture() {
    if(this.pictureBody.valid) {
      this.loading = true;
      this.carouselService.putPicture(this.carousel.id, this.pictureBody.value).subscribe(
        data => {
          this.loading = false;
          if (data) {
            this.dialogRef.close(data);
          }
        },
        error => {
          this.loading = false;
        });
    } else 
    this.validForm = true;
  }

  public handleImageLoad() {
    this.imageLoaded = true;
  }

  public handleInputChange(e) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    this.picture.setValue(e.target.files[0]);

    const reader = new FileReader();

    this.loaded = false;
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  public _handleReaderLoaded(e) {
    const reader = e.target;
    this.imageSrc = reader.result;
    this.loaded = true;

    const image = new Image();
    image.src = reader.result;
    image.onload = () => {
      if(image.width === 960 && image.height === 540){
        this.message = false;
      } else {
        this.message = true;
      }
    }
  }
}
