import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PhoneCodes } from '../../../../core/files/PhoneCodes';
import { LocationBody, Country } from '../../../../core/models/Model';
import { LocationsService } from '../../../../core/services/Locations.service';
import { ValidationService } from '../../../../core/services/ValidationForms.service';
import * as moment_ from 'moment';
import "moment-timezone";

@Component({
  selector: 'app-location-create',
  templateUrl: './create.location.component.html',
  styleUrls: ['./create.location.component.scss']
})

export class LocationCreationComponent implements OnInit {
  private model: LocationBody = {} as LocationBody;
  public loading = false;
  public countries: Country[];

  public locationBody: FormGroup;
  public locationBodySubmitted = false;
  private phoneCodes = PhoneCodes;
  public filteredCodes: any;
  public filteredCodes1: any;
  public filteredCodes2: any;
  private moment = moment_;
  public timezones = this.moment.tz.names();
  public filteredTimezones: any;

  constructor(
    private locationsService: LocationsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<LocationCreationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.locationBody = this.formBuilder.group({
      'name': ['', [
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern(/^[A-Za-z\d ]*$/)
      ]],
      'prefix': ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(2),
        Validators.pattern(/^[A-Za-z\d]*$/)
      ]],
      'domain': ['', [
        Validators.required,
        Validators.maxLength(150),
        Validators.pattern(/^[a-zA-Z\d][a-zA-Z\d\-_]*[a-zA-Z\d]$/)
      ]],
      'address': ['', [
        Validators.required,
        Validators.maxLength(100)
      ]],
      'city': ['', [
        Validators.required,
        Validators.maxLength(100)
      ]],
      'state': ['', [
        Validators.required,
        Validators.maxLength(100)
      ]],
      'countryId': ['', [
        Validators.required
      ]],
      'wsCinemaId': ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(3),
        Validators.pattern(/^\d+$/)
      ]],
      'email': ['', [
        Validators.required,
        Validators.pattern(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)
      ]],
      'timezone': ['', [
        Validators.required
      ]],
      'invoiceCode': ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(3),
        Validators.pattern(/^[A-Za-z\d]+$/)
      ]],
      'lada1': ['', [
        Validators.required
      ]],
      'phone1': ['', [
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(10)
      ]],
      'lada2': ['', [
      ]],
      'phone2': ['', [
        Validators.maxLength(10),
        Validators.minLength(10)
      ]],
      'lada3': ['', [
      ]],
      'phone3': ['', [
        Validators.maxLength(10),
        Validators.minLength(10)
      ]]
    });

    this.filteredCodes = this.locationBody.controls.lada1.valueChanges
      .startWith(null)
      .map((country: any) => {
        return this.filterCodes(country);
      });

    this.filteredCodes1 = this.locationBody.controls.lada2.valueChanges
      .startWith(null)
      .map((country: any) => {
        return this.filterCodes(country);
      });

    this.filteredCodes2 = this.locationBody.controls.lada3.valueChanges
      .startWith(null)
      .map((country: any) => {
        return this.filterCodes(country);
      });
    this.filteredTimezones = this.locationBody.controls.timezone.valueChanges
      .startWith(null)
      .map((timezone: string) => this.filterTimezones(timezone));
    }

  public ngOnInit() {
    this.countries = this.data;
  }

  public createLocation(): void {
    this.locationBodySubmitted = true;
    if (this.locationBody.valid) {
      this.loading = true;
      this.locationsService.PostLocations(this.locationBody.value).subscribe(
        data => {
          this.loading = false;
          this.dialogRef.close(data);
        },
        error => {
          this.loading = false;
        }
      );
    }
  }

  shouldShowError(control_name, control_error?){
    return !this.isControlValid(control_name, control_error) && this.shouldControlErrorBeVisible(control_name);
  }

  isControlValid(control_name, validator_name?){
    if (!!validator_name) {
      return !this.locationBody.controls[control_name].hasError(validator_name);
    }else{
      return this.locationBody.controls[control_name].valid;
    }
  }

  shouldControlErrorBeVisible(control_name){
    return this.locationBodySubmitted || this.locationBody.controls[control_name].touched;
  }

  public filterCodes(countryName: string): any {
    if (countryName != null || countryName != undefined) {
      return this.phoneCodes
        .filter((country: any) => {
          return country.name.toLowerCase().indexOf(countryName.toLowerCase()) === 0;
        });
    } else {
      return this.phoneCodes;
    }
  }

  public filterTimezones(timezone: string): any {
    if (timezone != null || timezone != undefined) {
      return this.timezones
        .filter((filteredTimezone: string) => {
          return filteredTimezone.toLowerCase().indexOf(timezone.toLowerCase()) === 0;
        });
    } else {
      return this.timezones;
    }
  }
}
