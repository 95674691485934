import { LocationsService } from './../../../core/services/Locations.service';
import { NgModule } from '@angular/core';
import { MatDialogModule, MatIconModule, MatButtonModule, MatCardModule, MatInputModule, MatSelectModule, MatOptionModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AuthenticationGuard } from '../../../core/services/authentication.guard';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import * as Module from './Module';
import * as Service from '../../../core/services/Service';
import { LocationCashClosingComponent } from './location-cash-closing/location-cash-closing.component';
import { LocationCashClosingConfirmationComponent } from './location-cash-closing/location-cash-closing-confirmation/location-cash-closing-confirmation.component';

@NgModule({
  entryComponents: [
    LocationCashClosingConfirmationComponent
  ],
  declarations: [
    Module.LocationInfoRechargesListComponent,
    Module.LocationInfoProductListComponent,
    Module.LocationInfoTimetable,
    Module.LocationInfoComponent,
    Module.LocationInfoBodyComponent,
    LocationCashClosingComponent,
    LocationCashClosingConfirmationComponent

  ],
  imports: [
    Ng2SmartTableModule,
    Module.LocationsRouting,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    BrowserModule,
    FormsModule,
    HttpModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  exports: [
    Module.LocationInfoRechargesListComponent,
    Module.LocationInfoProductListComponent,
    Module.LocationInfoTimetable,
    Module.LocationInfoComponent,
    Module.LocationInfoBodyComponent
  ],
  providers: [
    Service.LocationInfoService,
    Service.LocationsService,
    AuthenticationGuard
  ]
})
export class LocationInfoModule { }