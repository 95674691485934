import { Component, Inject } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import * as Service from '../../../../core/services/Service';
import * as Model from '../../../../core/models/Model';
import * as Module from '../Module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-kiosk-deactivation',
  templateUrl: './deactivate.kiosks.component.html',
  styleUrls: ['./deactivate.kiosks.component.scss']

})

export class KioskDeactivationComponent {
  loading = false;

  constructor(
    private router: Router,
    private kiosksService: Service.KiosksService,
    private alertService: Service.AlertsService,
    public dialogRef: MatDialogRef<KioskDeactivationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public DeactivateKiosk(): void {
    this.loading = true;
    this.kiosksService.deleteKiosk(this.data.subject).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close(data);
      },
      error => {
        this.loading = false;
        
      });

  }
}
