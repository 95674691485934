import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import * as Service from '../../../core/services/Service';

@Component({
  selector: 'app-password-recovery-alert',
  templateUrl: './password-recovery-alert.component.html',
  styleUrls: ['./password-recovery-alert.component.scss']
})
export class PasswordRecoveryAlertComponent {;
  public loading = false;
  email;

  constructor(
    private authenticationService: Service.AuthenticationService,
    public dialogRef: MatDialogRef<PasswordRecoveryAlertComponent>,) {}

  sendPasswordRecovery() {
    this.loading = true;
    this.authenticationService.UserPasswordRecovery({email: this.email}).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close();
      }
    );
  }
}
