import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Headers, Http, URLSearchParams } from '@angular/http';
import {
  RequestMethod,
  RequestOptions,
  RequestOptionsArgs
} from '@angular/http';

import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { URLPath } from '../../../../core/services/Config';
import { URLOnsite } from '../../../../core/services/Config';
import { ResponsesService } from '../../../../core/services/Responses.service';
import { JWTHandler } from '../../../../core/services/Service';
import { NewsFeedBody } from '../models/news-feed-body.model';
import { NewsFeed } from '../models/news-feed.model';

@Injectable()
export class NewsFeedService {
  protected basePath = URLOnsite;
  public defaultHeaders: Headers = new Headers();

  constructor(
    protected http: Http,
    private responsesService: ResponsesService
  ) {
    this.basePath = URLPath;
  }

  public getNewsFeed(): Observable<NewsFeed[]> {
    return this.getNewsFeedWithHttpInfo()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(
          this.responsesService.GenericGetResponse(error)
        );
      });
  }

  public postNewsFeed(newsFeedBody: NewsFeedBody): Observable<NewsFeed> {
    return this.postNewsFeedWithHttpInfo(newsFeedBody)
      .map((response: Response) => {
        return this.responsesService
          .GenericPostResponse(response, 'Éxito', 'Noticia creada.')
          .json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(
          this.responsesService.GenericPostResponse(error)
        );
      });
  }

  public putNewsFeed(
    newsFeedId: number,
    newsFeedBody: NewsFeedBody
  ): Observable<NewsFeed> {
    return this.putNewsFeedWithHttpInfo(newsFeedId, newsFeedBody)
      .map((response: Response) => {
        return this.responsesService
          .GenericPostResponse(response, 'Éxito', 'Noticia editada.')
          .json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(
          this.responsesService.GenericPostResponse(error)
        );
      });
  }

  public deleteNewsFeed(id: number): Observable<any> {
    return this.deleteNewsFeedWithHttpInfo(id)
      .map((response: Response) => {
        return this.responsesService
          .GenericPostResponse(response, 'Éxito', 'Noticia eliminada.')
          .json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(
          this.responsesService.GenericPostResponse(error)
        );
      });
  }

  private getNewsFeedWithHttpInfo(): Observable<Response> {
    const path = this.basePath + `/news`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private postNewsFeedWithHttpInfo(
    newsFeedBody: NewsFeedBody
  ): Observable<Response> {
    const path = this.basePath + `/news`;

    const formData: FormData = new FormData();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    for (const property in newsFeedBody) {
      if (property) {
        if (newsFeedBody[property]) {
          formData.append(property, newsFeedBody[property]);
        }
      }
    }

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers
    });
    return this.http.post(path, formData, requestOptions);
  }

  private putNewsFeedWithHttpInfo(
    newsFeedId: number,
    newsFeedBody: NewsFeedBody
  ): Observable<Response> {
    const path = this.basePath + `/news/${newsFeedId}`;

    const headers = new Headers(this.defaultHeaders.toJSON());
    const formData: FormData = new FormData();

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    for (const property in newsFeedBody) {
      if (property) {
        if (newsFeedBody[property]) {
          formData.append(property, newsFeedBody[property]);
        }
      }
    }

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers
    });

    return this.http.put(path, formData, requestOptions);
  }

  private deleteNewsFeedWithHttpInfo(newsFeedId: number): Observable<Response> {
    const path = this.basePath + `/news/${newsFeedId}`;

    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Delete,
      headers: headers
    });

    return this.http.request(path, requestOptions);
  }
}
