import { Injectable } from '@angular/core';
import { Headers, Http, URLSearchParams } from '@angular/http';
import {
  RequestMethod,
  RequestOptions,
  RequestOptionsArgs
} from '@angular/http';
import { Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/map';

import { URLPath } from '../../../../core/services/Config';
import {
  JWTHandler,
  ResponsesService
} from '../../../../core/services/Service';

import { Picture } from '../models/picture.model';
import { PictureBody } from './../models/pictureBody.model';

@Injectable()
export class CarouselService {
  protected basePath;
  public defaultHeaders: Headers = new Headers();

  constructor(
    protected http: Http,
    private responsesService: ResponsesService
  ) {
    this.basePath = URLPath;
  }

  public getPictures(): Observable<Picture[]> {
    return this.getPicturesWithHttpInfo()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(
          this.responsesService.GenericGetResponse(error)
        );
      });
  }

  public postPicture(avatar: PictureBody): Observable<Picture> {
    return this.postPictureWithHttpInfo(avatar)
      .map((response: Response) => {
        return this.responsesService
          .GenericPostResponse(response, 'Éxito', 'Imagen creada.')
          .json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(
          this.responsesService.GenericPostResponse(error)
        );
      });
  }

  public deletePicture(pictureId: number): Observable<any> {
    return this.deletePictureWithHttpInfo(pictureId)
      .map((response: Response) => {
        return this.responsesService
          .GenericPostResponse(response, 'Éxito', 'Imagen eliminada.')
          .json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(
          this.responsesService.GenericPostResponse(error)
        );
      });
  }

  public putPicture(pictureId: number, pictureBody: PictureBody): Observable<any> {
    return this.putPictureWithHttpInfo(pictureId, pictureBody)
      .map((response: Response) => {
        return this.responsesService
          .GenericPostResponse(response, 'Éxito', 'Información actualizada.')
          .json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(
          this.responsesService.GenericPostResponse(error)
        );
      });
  }

  private getPicturesWithHttpInfo(): Observable<Response> {
    const path = this.basePath + `/carousel`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });
    return this.http.request(path, requestOptions);
  }

  private postPictureWithHttpInfo(
    pictureBody: PictureBody
  ): Observable<Response> {
    const path = this.basePath + `/carousel`;

    const queryParameters = new URLSearchParams();
    let formData: FormData = new FormData();
    const headers = new Headers(this.defaultHeaders.toJSON());
    let formParams = new URLSearchParams();

    if (pictureBody === null || pictureBody === undefined) throw new Error();

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    formData.append('picture', pictureBody.picture);
    formData.append('url', pictureBody.url);

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers
    });

    return this.http.post(path, formData, requestOptions);
  }

  private putPictureWithHttpInfo(pictureId: number,
    pictureBody: PictureBody
  ): Observable<Response> {
    const path = this.basePath + `/carousel/${pictureId}`;

    const queryParameters = new URLSearchParams();
    let formData: FormData = new FormData();
    const headers = new Headers(this.defaultHeaders.toJSON());
    let formParams = new URLSearchParams();

    if (pictureBody === null || pictureBody === undefined) {
      throw new Error();
    }

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    formData.append('picture', pictureBody.picture);
    formData.append('url', pictureBody.url);

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      headers: headers
    });

    return this.http.put(path, formData, requestOptions);
  }

  private deletePictureWithHttpInfo(pictureId: number): Observable<Response> {
    const path = this.basePath + `/carousel/${pictureId}`;

    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Delete,
      headers: headers
    });

    return this.http.request(path, requestOptions);
  }
}
