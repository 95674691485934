import { ValidationService } from './../../../../core/services/ValidationForms.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialog } from '@angular/material';

import * as Model from '../models/Model';

import { GamesService } from '../services/games.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';

@Component({
  selector: 'app-game-edit',
  templateUrl: './game-edit.component.html',
  styleUrls: ['./game-edit.component.css']
})
export class GameEditComponent implements OnInit {
  public gameBody: FormGroup;
  public formArray: FormArray;
  public loading = false;
  public highlightM = false;
  public coverM = false;
  public screenshot0M = false;
  public screenshot1M = false;
  public screenshot2M = false;
  public validForm  = false;

  public imageLoaded: any = {
    cover: { loaded: false, file: '' },
    highlight: { loaded: false, file: '' },
    screenshot0: { loaded: false, file: '' },
    screenshot1: { loaded: false, file: '' },
    screenshot2: { loaded: false, file: '' }
  };

  public gameImages: Model.GameImages = {};

  private _tags: Model.GameTag[];
  public get tags(): Model.GameTag[] {
    return this._tags;
  }
  public set tags(value: Model.GameTag[]) {
    this.gamesService.getTags().subscribe((data: Model.GameTag[]) => (this._tags = data));
  }

  public tagIds: Model.System[];

  constructor(
    private gamesService: GamesService,
    public dialogRef: MatDialogRef<GameEditComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public game: Model.Game
  ) {
    this.tagIds =  game.tags;
    this.formArray = new FormArray([
      new FormGroup({
        description: new FormControl(this.game.description, [Validators.required]),
        tagIds: new FormControl(this.tagIds, [Validators.required])
      }),
      new FormGroup({
        cover: new FormControl('', [
          ValidationService.imageSizeValidator(512000)]),
        highlight: new FormControl('', [
          ValidationService.imageSizeValidator(512000)]),
        screenshot0: new FormControl('', [
          ValidationService.imageSizeValidator(512000)]),
        screenshot1: new FormControl('', [
          ValidationService.imageSizeValidator(512000)]),
        screenshot2: new FormControl('', [
          ValidationService.imageSizeValidator(512000)])
      })
    ]);
    this.gameBody = new FormGroup({ formArray: this.formArray });
  }

  public get cover(): AbstractControl { return this.formArray.at(1).get('cover'); }
  public get highlight(): AbstractControl { return this.formArray.at(1).get('highlight'); }
  public get screenshot0(): AbstractControl { return this.formArray.at(1).get('screenshot0'); }
  public get screenshot1(): AbstractControl { return this.formArray.at(1).get('screenshot1'); }
  public get screenshot2(): AbstractControl { return this.formArray.at(1).get('screenshot2'); }


  public ngOnInit() {
    this.tags = [];
  }
  public editGame(): void {
    if(this.gameBody.valid) {
      this.loading = true;
      const game: Model.GameBody = Object.assign(this.formArray.value[0], this.formArray.value[1]);
      this.gamesService.putGame(this.game.id, game, this.tags).subscribe(
        (data: Model.Game) => {
          this.loading = false;
          if (data) {
            this.dialogRef.close(data);
          }
        },
        error => {
          this.loading = false;
        }
      );
    } else 
      this.validForm = true;
  }

  public onAdd(tag) {
    if (tag.id === undefined) {
      this.gamesService.postTag(tag.value).subscribe(
        (data: Model.GameTag) => {
          this.tags.push(data);
        },
        (error) => { });
    }
  }

  public onAdding(tagToAdd: any): Observable<any> {
    return Observable
    .of(tagToAdd)
    .filter(() => (tagToAdd !== undefined || tagToAdd !== null ? true : false));
  }

  public handleImageLoad(property: string) {
    this.imageLoaded[property].loaded = true;
  }

  public handleInputChange(propertyName: string, e) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this, propertyName);
    reader.readAsDataURL(file);
    this.formArray
      .at(1)
      .get(propertyName)
      .patchValue(file);
  }

  public _handleReaderLoaded(property, e) {
    const reader = e.target;
    this.imageLoaded[property].file = reader.result;

    const image = new Image();
    image.src = reader.result;
    image.onload = () => {
      switch(property){
        case 'highlight': {
          if(image.width === 1060 && image.height === 520) {
            this.highlightM = false;
            } else {
            this.highlightM = true;
            }
          break;
          }
          case 'cover': {
          if(image.width === 330 && image.height === 380) {
            this.coverM = false;
            } else {
            this.coverM = true;
            }
          break;
          }
          case 'screenshot0': {
          if(image.width === 1640 && image.height === 870) {
            this.screenshot0M = false;
            } else {
            this.screenshot0M = true;
            }
          break;
          }
          case 'screenshot1': {
          if(image.width === 1640 && image.height === 870) {
            this.screenshot1M = false;
            } else {
            this.screenshot1M = true;
            }
          break;
          }
          case 'screenshot2': {
          if(image.width === 1640 && image.height === 870) {
            this.screenshot2M = false;
            } else {
            this.screenshot2M = true;
            }
          break;
          }
      }
    }
  }
}
