import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import * as Service from '../../../../../app/core/services/Service';
import * as Model from '../../../../../app/core/models/Model';

@Component({
  selector: 'app-applications-creation',
  templateUrl: './applications-creation.component.html',
  styleUrls: ['./applications-creation.component.css']
})
export class ApplicationsCreationComponent implements OnInit {
  public applicationBody: FormGroup;
  public loading: boolean = false;
  public validForm  = false;

  constructor(
    private formBuilder: FormBuilder,
    private applicationsService: Service.ApplicationsService,
    public dialogRef: MatDialogRef<ApplicationsCreationComponent>
  ) {
    this.applicationBody = this.formBuilder.group({
      'name': ['', [
        Validators.required
      ]],
      'pathToLauncher': ['', [
        Validators.required
      ]],
      'pathToIcon': ['', [
        Validators.required
      ]]
    });
   }

  public ngOnInit() {

  }

  public createApplication(): void {
    if(this.applicationBody.valid) {
      this.applicationsService.postApplication(this.applicationBody.value).subscribe(
        data => {
          this.loading = !this.loading;
          this.dialogRef.close(data);
        },
        error => {
          this.loading = !this.loading;
        }
      );
    } else
      this.validForm = true;
  }
}
