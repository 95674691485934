import { Component, Inject } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import * as Service from '../../../../core/services/Service';
import * as Model from '../../../../core/models/Model';
import * as Module from '../Module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-pc-activation',
  templateUrl: './activate.pc.component.html',
  styleUrls: ['./activate.pc.component.scss']

})

export class PCActivationComponent {
  loading = false;

  constructor(
    private timersService: Service.TimersService,
    public dialogRef: MatDialogRef<PCActivationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public ActivateTimer(): void {
    this.loading = true;
    this.timersService.putTimer(this.data.subject).subscribe(
      data => {
        this.loading = !this.loading;
        this.dialogRef.close(data);
      },
      error => {
        
        this.loading = false;
      });
  }
}
