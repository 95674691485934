import { Component, Inject } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import * as Service from '../../../../core/services/Service';
import * as Model from '../../../../core/models/Model';
import * as Module from '../Module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-assistant-deactivation',
  templateUrl: './deactivate.assistants.component.html',
  styleUrls: ['./deactivate.assistants.component.scss']

})

export class AssistantDeactivationComponent {
  loading = false;

  constructor(
    private router: Router,
    private assistantsService: Service.AssistantsService,
    private alertService: Service.AlertsService,
    public dialogRef: MatDialogRef<AssistantDeactivationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public DeactivateAssistant(): void {
    this.loading = true;
    this.assistantsService.deleteAssistant(this.data.assistantId).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close(data);
      },
      error => {
        this.loading = false;
        
      });

  }
}
