import { Location } from './../../../../../core/models/Location.model';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { TournamentsService } from '../services/tournaments.service';
import { Tournament } from './../models/tournament.model';
import { TournamentsEditComponent } from './../tournaments-edit/tournaments-edit.component';
import { LocationsService } from "./../../../../../core/services/Locations.service";

@Component({
  selector: 'app-tournaments-view',
  templateUrl: './tournaments-view.component.html',
  styleUrls: ['./tournaments-view.component.scss']
})
export class TournamentsViewComponent implements OnInit {
  @Input() public locations: Location[];
  @Input() public tournament: Tournament;
  @Input() public tournaments: Tournament[];
  @Output() public tournamentsChange = new EventEmitter<Tournament[]>();

  constructor(private tournamentsService: TournamentsService, public dialog: MatDialog, public locationsService: LocationsService) { }

  public ngOnInit(): void {
  }

  public publishTournament(tournamentId: number): void {
    this.tournamentsService.publishTournament(tournamentId).subscribe((data: Tournament) => {
      this.tournament = data;
      this.tournamentsChange.emit(this.tournaments);
    });
  }

  public closeTournamentDetails(): void {
    const table = document.querySelector('.tableGent');
    table.classList.remove('tableMin');
    const detail = document.querySelector('.detail');
    detail.classList.remove('visible');
    detail.classList.add('hidden');
  }

  public editTournament(): void {
    const dialogRef = this.dialog.open(TournamentsEditComponent, {
      data: {
        tournament: this.tournament,
        locations: this.locations
      }
    });
    dialogRef.afterClosed().subscribe((data: Tournament) => {
      if (data) {
        this.tournament = data;
        this.tournaments.forEach((tournamentToFind: Tournament) => {
          if (tournamentToFind.id === this.tournament.id) {
            tournamentToFind = this.tournament;
          }
        });

        this.tournamentsChange.emit(this.tournaments);
      }
    });
  }
  getLocationName(location_id){
    for (var i = 0; i < this.locations.length; i++) {
      if (this.locations[i].id === location_id) {
        return this.locations[i].name;
      }
    }
    return '';
  }
}
