import { AssistantsRecoveryComponent } from './Module';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const assistantRoute: Routes = [
  { path: 'assistants/:token', component: AssistantsRecoveryComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(assistantRoute)
  ],
  exports: [
    RouterModule
  ]
})

export class AssistantRouting { }
