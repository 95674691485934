import { NgModule } from '@angular/core';
import { MatDialogModule, MatProgressSpinnerModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AuthenticationGuard } from '../../../core/services/authentication.guard';
import { MatIconModule, MatButtonModule, MatCardModule, MatInputModule, MatSelectModule, MatOptionModule } from '@angular/material';

import { AvatarCreationComponent } from './avatar-creation/creation.avatars.component';
import { AvatarListComponent } from './avatar-list/list.avatars.component';
import { AvatarFilterPipe } from './filter.avatars.pipe';
import { AvatarsService } from './services/Avatars.service';
import { AvatarsRouting } from './avatars.routing';
import { AvatarCreationConfirmationComponent } from './avatar-creation-confirmation/avatar-creation-confirmation.component';

@NgModule({
  declarations: [
    AvatarListComponent,
    AvatarCreationComponent,
    AvatarFilterPipe,
    AvatarCreationConfirmationComponent
  ],
  entryComponents: [
    AvatarCreationComponent,
    AvatarCreationConfirmationComponent
  ],
  imports: [
    BrowserModule,
    MatDialogModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    ReactiveFormsModule,
    HttpModule,
    AvatarsRouting
  ],
  exports: [
    AvatarListComponent,
    AvatarCreationComponent
  ],
  providers: [
    AuthenticationGuard,
    AvatarsService
  ]
})
export class AvatarsModule { }
