import { Inject, Injectable, Optional } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { JWTHandler } from './JWT.handler';
import { URLPath, EnvironmentURL } from './Config';
import { ResponsesService } from './Responses.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import * as Model from '../models/Model';

@Injectable()
export class UsersService {
  protected basePath;
  public offsitePath: string;
  public defaultHeaders: Headers = new Headers();
  

  constructor(protected http: Http, private responsesService: ResponsesService) {
    let urlProvider = new EnvironmentURL();

    this.offsitePath = URLPath;
    this.basePath = urlProvider.GetDomainURL();
    
  }

  public SuspendUser(id: string): Observable<Model.User> {
    return this.suspendUsersHttp(id)
      .map((response: Response) => {
        return this.responsesService.GenericResponse(response, 'Usuario eliminado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericResponse(error));
      });
  }

  public GetUsers(role?: string, location?: string, email?: string): Observable<Model.User[]> {
    return this.getUsersHttp(role, location, email)
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public GetUserKeys(email: string): Observable<Model.UserKey[]> {
    return this.getUsersKeysHttp(email)
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }
  public CreateUser(userBody: any): Observable<Model.User> {
    const userPhone = userBody.phone;
    userBody.phone = userPhone.prefix + userPhone.phone;
    let user: Model.UserBody = userBody;
    return this.postUsersHttp(user)
      .map((response: Response) => {
        return this.responsesService.PostUserResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.PostUserResponse(error));
      });
  }

  public SetUserPassword(userPassword: Model.UserPassword, token: string): Observable<{}> {
    return this.postUserPasswordHttp(userPassword, token)
      .map((response: Response) => {
        return this.responsesService.GenericResponse(response, 'Contraseña actualizada.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.passwordSetResponse(error));
      });
  }

  public ModifyUser(id: string, userUpdate: Model.UserUpdate): Observable<Model.User> {
    return this.putUsersHttp(id, userUpdate)
      .map((response: Response) => {
        return this.responsesService.GenericResponse(response, 'Usuario actualizado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.PostUserResponse(error));
      });
  }

  public GetNewUserPassword(): Observable<{}> {
    return this.userGetPasswordHttp()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  private suspendUsersHttp(id: string): Observable<Response> {
    const path = this.basePath + '/users/' + id;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    
    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Delete,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private getUsersHttp(role?: string, location?: string, email?: string): Observable<Response> {
    const path = this.basePath + '/users';

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (role !== undefined) queryParameters.set('role', <any>role);

    if (location !== undefined) queryParameters.set('location_id', <any>location);

    if (email !== undefined) queryParameters.set('email', <any>email);

    
    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private getUsersKeysHttp(email?: string): Observable<Response> {
    const path = this.basePath + `/users/access_keys`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (email !== undefined) queryParameters.set('email', <any>email);

    
    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private postUsersHttp(userBody: Model.UserBody): Observable<Response> {
    const path = this.basePath + `/users`;
    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (userBody === null || userBody === undefined) throw new Error();

    
    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: userBody == null ? '' : JSON.stringify(userBody),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private postUserPasswordHttp(userPassword: Model.UserPassword, token: string): Observable<Response> {
    const path = this.offsitePath + `/users/password`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (userPassword === null || userPassword === undefined) throw new Error();

    
    headers.append('Authorization', 'JWT ' + token);

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: userPassword == null ? '' : JSON.stringify(userPassword),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private putUsersHttp(id: string, userUpdate: Model.UserUpdate): Observable<Response> {
    const path = this.basePath + `/users/${id}`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (id === null || id === undefined) throw new Error();

    if (userUpdate === null || userUpdate === undefined) throw new Error();

    
    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      body: userUpdate == null ? '' : JSON.stringify(userUpdate),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private userGetPasswordHttp(): Observable<Response> {
    const path = this.basePath + `/users/password`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    
    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }
}
