import { Inject, Injectable, Optional } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { ResponsesService } from './Responses.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { EnvironmentURL, URLPath } from './Config';
import * as Model from '../models/Model';
import { JWTHandler } from './JWT.handler';

@Injectable()
export class LocationInfoService {
  protected basePath;
  protected offsitePath;
  public defaultHeaders: Headers = new Headers();
  

  constructor(protected http: Http, private responsesService: ResponsesService) {
    let urlProvider = new EnvironmentURL();
    this.offsitePath = URLPath;
    this.basePath = urlProvider.GetDomainURL();
    
  }

  public getRechargeProducts(forceUpdate?: boolean, firstTime?: boolean): Observable<Model.RechargeInfo[]> {
    return this.getRechargeProductsHttp(forceUpdate)
      .map((response: Response) => {
        if(firstTime)
        return this.responsesService.GenericGetResponse(response).json();
        else
          return this.responsesService.GenericResponse(response, 'Productos de recarga actualizados correctamente.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public getTimeTable(): Observable<Model.TimeTable> {
    return this.getTimeTableHttp()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public putTimeTable(time: Model.TimeTable): Observable<Model.TimeTable> {
    return this.putTimeTableHttp(time)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Horas de apertura y cierre actualizadas.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public getTimeProducts(forceUpdate?: boolean, firstTime?: boolean): Observable<any[]> {
    return this.getTimeProductsHttp(forceUpdate)
      .map((response: Response) => {
        if(firstTime)
          return this.responsesService.GenericGetResponse(response).json();
        else
          return this.responsesService.GenericResponse(response, 'Productos de tiempo actualizados correctamente.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public getBalancing(): Observable<any[]> {
    return this.getBalancingHttp()
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Corte de caja realizado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  private getRechargeProductsHttp(forceUpdate?: boolean): Observable<Response> {
    const path = this.offsitePath + `/recharges`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (forceUpdate !== undefined && forceUpdate) queryParameters.set('force_update', <any>forceUpdate);

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private getTimeProductsHttp(forceUpdate?: boolean): Observable<Response> {
    const path = this.basePath + `/time_products`;
    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (forceUpdate !== undefined && forceUpdate) queryParameters.set('force_update', <any>forceUpdate);

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private putTimeTableHttp(time: Model.TimeTable): Observable<Response> {
    const path = this.basePath + `/timetable`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      body: time == null || time == undefined ? '' : JSON.stringify(time),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private getTimeTableHttp(): Observable<Response> {
    const path = this.basePath + `/timetable`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  public getLocationDetails(): Observable<Model.LocationHardware> {
    return this.getLocationDetailsWithHttpInfo().map((response: Response) => {
      if (response.status === 204) {
        return undefined;
      } else {
        return response.json();
      }
    });
  }

  public getLocationDetailsWithHttpInfo(): Observable<Response> {
    const path = this.basePath + `/location_details`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  public getBalancingHttp(): Observable<Response> {
    const path = this.basePath + `/balancing`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }
}
