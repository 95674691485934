import { Inject, Injectable, Optional } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { ResponsesService } from './Responses.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { EnvironmentURL } from './Config';

import * as Model from '../models/Model';
import { JWTHandler } from './JWT.handler';

@Injectable()
export class KiosksService {
  protected basePath;
  public defaultHeaders: Headers = new Headers();
  

  constructor(
    protected http: Http,
    private responsesService: ResponsesService
  ) {
    let urlProvider = new EnvironmentURL();
    this.basePath = urlProvider.GetDomainURL();
    
  }

  public deleteKiosk(subject: string): Observable<Model.Kiosk> {
    return this.deleteKioskWithHttpInfo(subject)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Kiosko eliminado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public getKiosks(name?: string, subject?: string, acceptancePending?: boolean): Observable<Model.Kiosk[]> {
    return this.getKiosksWithHttpInfo(name, subject, acceptancePending)
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public postKiosks(kioskName: string): Observable<Model.KioskAuth> {
    return this.postKiosksWithHttpInfo(kioskName)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Kiosko creado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public putKiosk(subject: string): Observable<Model.Kiosk> {
    return this.putKioskWithHttpInfo(subject)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Kiosko activado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public deleteKioskWithHttpInfo(subject: string): Observable<Response> {
    const path = this.basePath + `/kiosks/${subject}`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (subject === null || subject === undefined)
      throw new Error();

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Delete,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  public getKiosksWithHttpInfo(name?: string, subject?: string, acceptancePending?: boolean): Observable<Response> {
    const path = this.basePath + `/kiosks`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (name !== undefined)
      queryParameters.set('name', <any>name);

    if (subject !== undefined)
      queryParameters.set('subject', <any>subject);

    if (acceptancePending !== undefined)
      queryParameters.set('acceptance_pending', <any>acceptancePending);

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  public postKiosksWithHttpInfo(kioskName: string): Observable<Response> {
    if (kioskName === null || kioskName === undefined)
      throw new Error();
    const path = this.basePath + `/kiosks`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: kioskName == null ? '' : JSON.stringify(kioskName),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  public putKioskWithHttpInfo(subject: string): Observable<Response> {
    const path = this.basePath + `/kiosks/${subject}`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (subject === null || subject === undefined)
      throw new Error();

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      search: queryParameters
    });

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    return this.http.request(path, requestOptions);
  }

}
