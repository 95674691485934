import { TournamentModesService } from '../../TournamentModesModule/services/tournamentModes.service';
import { TournamentGamesService } from '../../TournamentGamesModule/services/tournamentGames.service';
import { TournamentTieBreakerCreateComponent } from './tournament-tie-breaker-create/tournament-tie-breaker-create.component';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { TournamentTemplatesService } from '../services/tournamentTemplates.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Game } from '../../TournamentGamesModule/models';
import { Mode } from '../../TournamentModesModule/models';
import { Component, OnInit, Inject } from '@angular/core';
import { map, startWith } from 'rxjs/operators';
import * as Model from '../models/index';
import { Observable } from 'rxjs';
import { ValidationService } from 'app/core/services/Service';

@Component({
  selector: 'app-tournament-create',
  templateUrl: './tournament-create.component.html',
  styleUrls: ['./tournament-create.component.css']
})
export class TournamentCreateComponent implements OnInit {
  public tournamentBody: FormGroup;
  public loading = false;
  public search = '';
  public criteriaIds: Model.Criteria[] = [];
  public validForm = false;

  private _tournamentCriteria: Model.Criteria[];
  public get tournamentCriteria(): Model.Criteria[] {
    return this._tournamentCriteria;
  }
  public set tournamentCriteria(criteria: Model.Criteria[]) {
    this.tournamentTemplatesService.getCriteria().subscribe(
      (data: Model.Criteria[]) => this._tournamentCriteria = data
    );
  }

  public filteredTournamentGames: Observable<Game[]>;
  public tournamentGamesControl: FormControl;

  private _tournamentGames: Game[];
  public get tournamentGames(): Game[] {
    return this._tournamentGames;
  }
  public set tournamentGames(games: Game[]) {
    this._tournamentGames = this.data.tournamentGames;
  }

  public filteredTournamentModes: Observable<Mode[]>;
  public tournamentModesControl: FormControl;

  private _tournamentMode: Mode[];
  public get tournamentModes(): Mode[] {
    return this._tournamentMode;
  }
  public set tournamentModes(tournamentModes: Mode[]) {
    this._tournamentMode = this.data.tournamentModes;
  }

  constructor(
    public dialogRef: MatDialogRef<TournamentCreateComponent>,
    private formBuilder: FormBuilder,
    private tournamentTemplatesService: TournamentTemplatesService,
    private tournamentModesService: TournamentModesService,
    private tournamentGamesService: TournamentGamesService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialog: MatDialog
  ) {
    this.tournamentGamesControl = new FormControl();
    this.tournamentModesControl = new FormControl();

    this.tournamentBody = this.formBuilder.group({
      name: ['', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(60)
      ]],
      minPlayersPerTeam: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(2),
        ValidationService.integerValidator()
      ]],
      maxPlayersPerTeam: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(2),
        ValidationService.integerValidator()
      ]],
      gameId: this.tournamentGamesControl,
      modeId: this.tournamentModesControl,
      criteriaIds: ['', [
      ]]
    });
  }

  public autocompleteFilter(filterParam: any, list: any[]): any {
    if (filterParam && !Object) {
      return list.filter((tournamentGame: any) => tournamentGame.name.toLowerCase().indexOf(filterParam.toLowerCase()) === 0);
    } else {
      return list;
    }
  }

  public ngOnInit(): void {
    this.tournamentCriteria = [];
    this.tournamentModes = [];
    this.tournamentGames = [];
  }

  public removeCriteria(criteria: Model.Criteria): void {
    this.criteriaIds = this.criteriaIds.filter((filteredCriteria: Model.Criteria) => {
      return filteredCriteria.id !== criteria.id;
    });
  }

  public createTournament(): void {
    if (this.tournamentBody.valid) {
      const tournamentBody: Model.TournamentTemplateBody = this.tournamentBody.value;
      tournamentBody.criteriaIds = this.criteriaIds.map(criteria => {
        return criteria.id;
      });
      this.loading = !this.loading;
      this.tournamentTemplatesService.postTournamentTemplate(tournamentBody).subscribe(
        (data: Model.TournamentTemplate) => {
          this.loading = !this.loading;
          this.dialogRef.close(data);
        },
        (error: Error) => {
          this.loading = !this.loading;
        }
      );
    } else
      this.validForm = true;
  }

  public onSearch(query: string = '') {
    query = query.trim();
    query = query.toLowerCase();
    this.tournamentCriteria.filter((filteredCriteria: Model.Criteria) => {
      return query === filteredCriteria.name;
    });
  }

  public createCriteria(): void {
    const dialogRef = this.dialog.open(TournamentTieBreakerCreateComponent);
    dialogRef.afterClosed().subscribe((data: Model.Criteria) => {
      if (data) {
        this.tournamentCriteria.push(data);
        this.criteriaIds.push(data);
      }
    });
  }

  isCriteriaSelected(criteria_id){
    for (var i = 0; i < this.criteriaIds.length; i++) {
      if (this.criteriaIds[i].id === criteria_id) {
        return true;
      }
    }
    return false;
  }

  addCriteria(event){
    if (event.checked) {
      this.criteriaIds.push(event.source.value)
    } else {
      this.removeCriteria(event.source.value);
    }
  }
}
