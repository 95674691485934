import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-reports-games-by-system-view',
  templateUrl: './reports-games-by-system-view.component.html',
  styleUrls: ['./reports-games-by-system-view.component.scss']
})
export class ReportsGamesBySystemViewComponent {
  @Input() report: any = null;
  @Output() close_detail = new EventEmitter();
  constructor() {}
  getDateTimeFormat(time){
    return this.getDateFormat(time) + ' ' + this.getTimeFormat(time);
  }
  getTimeFormat(time){
    var date = new Date((+time) * 1000);
    var hours = date.getHours();
    var minutes = "0" + date.getMinutes();
    var seconds = "0" + date.getSeconds();
    var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    return formattedTime;
  }
  getDateFormat(time){
    var currentDate = new Date((+time) * 1000);
    var date = currentDate.getDate();
    var month = currentDate.getMonth();
    var year = currentDate.getFullYear();
    var dateString = date + "/" + (month + 1) + "/" + year;
    return dateString;
  }
  closeDetail(){
    this.close_detail.emit();
  }
}
