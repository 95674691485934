import { TournamentModesService } from './services/tournamentModes.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDialogModule,
  MatDialogRef,
  MatIconModule,
  MatProgressSpinnerModule,
  MatButtonModule,
  MatCardModule,
  MatInputModule,
  MAT_DIALOG_DATA,
  MatPaginatorModule
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { TournamentModesEditComponent } from './tournament-modes-edit/tournament-modes-edit.component';
import { TournamentModesCreateComponent } from './tournament-modes-create/tournament-modes-create.component';
import { TournamentModesListComponent } from './tournament-modes-list/tournament-modes-list.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatTableModule,
    BrowserModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    MatPaginatorModule
  ],
  declarations: [TournamentModesEditComponent, TournamentModesCreateComponent, TournamentModesListComponent],
  exports: [TournamentModesEditComponent, TournamentModesCreateComponent, TournamentModesListComponent],
  entryComponents: [TournamentModesEditComponent, TournamentModesCreateComponent],
  providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }, { provide: MatDialogRef, useValue: {} }, TournamentModesService]
})
export class TournamentModesModule { }
