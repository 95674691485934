import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthenticationGuard } from '../../../core/services/authentication.guard';
import { ZonesListComponent } from './Module';

const zonesRoute: Routes = [
  { path: 'zones', component: ZonesListComponent, canActivate: [AuthenticationGuard] }
];

@NgModule({
  imports: [
    RouterModule.forChild(zonesRoute)
  ],
  exports: [
    RouterModule
  ]
})

export class ZonesRouting { }
