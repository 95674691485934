import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

import * as Model from '../models/index';
import { TournamentModesService } from '../services/tournamentModes.service';

@Component({
  selector: 'app-tournament-modes-edit',
  templateUrl: './tournament-modes-edit.component.html',
  styleUrls: ['./tournament-modes-edit.component.css']
})
export class TournamentModesEditComponent {
  public loading = false;
  @Input() public modeBody: Model.Mode;
  public formArray: FormArray;
  public tournamentBody: FormGroup;

  constructor(
    private tournamentModesService: TournamentModesService,
    public dialogRef: MatDialogRef<TournamentModesEditComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    if (data) {
      this.modeBody = data;
    }
    this.tournamentBody = new FormGroup({
      'name': new FormControl(this.modeBody.name, [
        Validators.required
      ])
    });
  }

  public editTournamentMode(): void {
    this.loading = !this.loading;
    this.tournamentModesService.putTournamentMode(this.modeBody).subscribe(
      (data: Model.Mode) => {
        this.loading != this.loading;
        this.dialogRef.close(data);
      },
      (error: Error) => {
        this.loading = !this.loading;
      });
  }

}
