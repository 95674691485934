import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { TestimonialsService } from '../services/testimonials.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Testimonial } from '../models/testimonial.model';
import { Validators } from '@angular/forms';
import { ValidationService } from 'app/core/services/Service';

@Component({
  selector: 'app-testimonials-edit',
  templateUrl: './testimonials-edit.component.html',
  styleUrls: ['./testimonials-edit.component.scss']
})
export class TestimonialsEditComponent {
  public testimonialBody: FormGroup;
  public loading = false;
  public loaded = false;
  public imageLoaded = false;
  public imageSrc = '';
  public message = false;
  public validForm = false;

  constructor(
    private testimonialsService: TestimonialsService,
    public dialogRef: MatDialogRef<TestimonialsEditComponent>,
    @Inject(MAT_DIALOG_DATA) public testimonial: Testimonial
  ) {
    this.testimonialBody = new FormGroup({
      'content': new FormControl(testimonial.content, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(255)
      ]),
      'by': new FormControl(testimonial.by, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(255)
      ]),
      'picture': new FormControl('', [
        ValidationService.imageSizeValidator(512000)
      ])
    });
  }

  public get picture(): AbstractControl { return this.testimonialBody.get('picture'); }

  public editTestimonial() {
    if (this.testimonialBody.valid) {
      this.loading = true;
      this.testimonialsService.putTestimonial(this.testimonial.id, this.testimonialBody.value).subscribe(
        data => {
          this.loading = false;
          this.dialogRef.close(data);
        },
        error => {
          this.loading = false;
        });
    } else
      this.validForm = true;
  }

  public handleImageLoad() {
    this.imageLoaded = true;
  }

  public handleInputChange(e) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    this.testimonialBody.get('picture').setValue(e.target.files[0]);
    const reader = new FileReader();
    this.loaded = false;
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  public _handleReaderLoaded(e) {
    const reader = e.target;
    this.imageSrc = reader.result;
    this.loaded = true;

    const image = new Image();
    image.src = reader.result;
    image.onload = () => {
      if (image.width === 480 && image.height === 250) {
        this.message = false;
      } else {
        this.message = true;
      }
    }
  }

}
