import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import * as Model from '../../../../core/models/Model';
import { CountriesService } from '../../../../core/services/Service';

@Component({
  selector: 'app-country-list',
  templateUrl: './list.countries.component.html',
  styleUrls: ['./list.countries.component.scss']
})

export class CountryListComponent implements OnInit {
  public source: LocalDataSource;

  private _countries: Model.Country[];
  public get countries(): Model.Country[] {
    return this._countries;
  }
  public set countries(value: Model.Country[]) {
    this.countriesService.GetCountries().subscribe(
      countriesFromHttp => {
        this._countries = countriesFromHttp;
        this._countries.forEach((country) =>
          this.source.add({
            isoCode: country.isoCode,
            name: country.name,
            currency: country.currency
          })
        );
        this.source.refresh();
      });
  }
  public settings = {
    actions: false,
    columns: {
      isoCode: {
        title: 'Abreviatura',
        filter: false
      },
      name: {
        title: 'País',
        filter: false
      },
      currency: {
        title: 'Divisa',
        filter: false
      }
    }
  };

  constructor(
    private countriesService: CountriesService,
  ) { }

  public ngOnInit(): void {
    this.source = new LocalDataSource();
    this.countries = [];
  }

  public onSearch(query: string = ''): void {
    this.source.setFilter([
      {
        field: 'name',
        search: query
      },
      {
        field: 'isoCode',
        search: query
      }
    ], true);
  }


}
