import { NgModule } from '@angular/core';

import { AvatarsModule } from './AvatarsModule/avatars.module';
import { CountriesModule } from './CountriesModule/countries.module';
import { LocationsModule } from './LocationsModule/locations.module';
import { ProductsModule } from './ProductsModule/products.module';
import { UsersModule } from './UsersModule/users.module';

@NgModule({
  imports: [
    AvatarsModule,
    CountriesModule,
    LocationsModule,
    UsersModule,
    ProductsModule
  ],
  exports: [
    AvatarsModule,
    CountriesModule,
    LocationsModule,
    UsersModule,
    ProductsModule
  ]
})
export class OffsiteModule { }
