import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import * as Model from '../../../core/models/Model';
import { LocationsService } from '../../../core/services/Locations.service';

@Component({
  selector: 'app-view-switcher',
  templateUrl: './view.switcher.component.html',
  styleUrls: ['./view.switcher.component.scss']
})
export class SwitcherViewComponent implements OnInit {
  public _locations: Model.Location[];
  public get locations(): Model.Location[] {
    return this._locations;
  }
  public set locations(value: Model.Location[]) {
    this.locationsService.GetLocations().subscribe(
      data => {
        this._locations = data;
      });
  }

  public isOnsiteSelected = false;

  constructor(
    public dialogRef: MatDialogRef<SwitcherViewComponent>,
    public locationsService: LocationsService
  ) { }

  public ngOnInit() {
    this.locations = [];
  }
  public LaunchOffsiteAdmin(): void {

    const authorization: Model.AuthAccessURL = {
      userAuthorization: 'super',
      locationName: '',
      locationId: 0,
      locationDomain: ''
    } as Model.AuthAccessURL;

    localStorage.setItem('authorization', JSON.stringify(authorization));
    this.dialogRef.close();
    window.location.reload();
  }

  public LaunchTournamentAdmin(): void {

    const authorization: Model.AuthAccessURL = {
      userAuthorization: 'tournament',
      locationName: '',
      locationId: 0,
      locationDomain: ''
    } as Model.AuthAccessURL;
    localStorage.setItem('authorization', JSON.stringify(authorization));
    this.dialogRef.close();
    window.location.reload();
  }

  public LaunchOnsiteAdmin(selectedLocation: Model.Location): void {

    const authorization: Model.AuthAccessURL = {
      userAuthorization: 'onsite',
      locationName: (selectedLocation.name) ? selectedLocation.name : '',
      locationId: (selectedLocation.id) ? selectedLocation.id : '',
      locationDomain: (selectedLocation.domain) ? selectedLocation.domain : ''
    } as Model.AuthAccessURL;

    localStorage.setItem('authorization', JSON.stringify(authorization));
    this.dialogRef.close();
    window.location.reload();
  }


}
