import { Component, OnInit } from '@angular/core';

import * as Model from '../../../../core/models/Model';
import * as Module from '../Module';
import * as Service from '../../../../core/services/Service';

@Component({
  selector: 'app-locationInfo-info',
  templateUrl: './info.locationInfo.component.html',
  styleUrls: ['./info.locationInfo.component.scss']
})


export class LocationInfoComponent implements OnInit {
  public location: Model.Location;
  public locationHardware: Model.LocationHardware;

  constructor(
    private locationsService: Service.LocationsService,
    private locationInfoService: Service.LocationInfoService
  ) { }

  ngOnInit() {
    this.locationInfoService.getLocationDetails().subscribe(
      data => {
        this.locationHardware = data;
      },
      error => {
      }
    );
    this.GetLocationInfoFromOffsite();
  }

  public GetLocationInfoFromOffsite() {
    const authorization: Model.AuthAccessURL = JSON.parse(localStorage.getItem('authorization'));
    this.locationsService.GetLocations(authorization.locationName).subscribe(
      data => {
        this.location = data[0];
      },
      error => {
      }
    );
  }
}
