import { ValidationService } from './../../../../core/services/ValidationForms.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LocationWebInfo } from './../models/location-web-info.model';
import { FormArray, FormGroup, FormControl, Validators, FormControlName } from '@angular/forms';
import { LocationWebService } from './../services/location-web.service';
import { Component, Inject } from '@angular/core';
import { PhoneCodes } from '../../../../core/files/PhoneCodes';

@Component({
  selector: 'app-location-edit',
  templateUrl: './location-edit.component.html',
  styleUrls: ['./location-edit.component.scss']
})
export class LocationEditComponent {
  public imageCount = 1;
  public locationBody: FormGroup;
  public formArray: FormArray;
  public loading = false;
  public validForm = false;
  public galleryList = [
    'gallery0',
    'gallery1',
    'gallery2',
    'gallery3',
    'gallery4',
    'gallery5',
    'gallery6',
    'gallery7'
  ];
  public mapMessage = false;
  public messageArray = [false, false, false, false, false, false, false, false];

  public imageLoaded: any = {
    map: { loaded: false, file: '' },
    gallery0: { loaded: false, file: '' },
    gallery1: { loaded: false, file: '' },
    gallery2: { loaded: false, file: '' },
    gallery3: { loaded: false, file: '' },
    gallery4: { loaded: false, file: '' },
    gallery5: { loaded: false, file: '' },
    gallery6: { loaded: false, file: '' },
    gallery7: { loaded: false, file: '' }
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public location: LocationWebInfo,
    private locationWebService: LocationWebService,
    public dialogRef: MatDialogRef<LocationEditComponent>
  ) {
    this.formArray = new FormArray([
      new FormGroup({
        notes: new FormControl((this.location.locationInfo.notes) ? this.location.locationInfo.notes : '', [
          Validators.maxLength(100)
        ]),
        opening: new FormControl(this.location.locationInfo.opening, [
          Validators.maxLength(20)
        ]),
        phone1: new FormControl(this.location.locationInfo.phone1, [
          Validators.required,
          Validators.maxLength(13),
          Validators.minLength(10)
        ]),
        phone2: new FormControl(this.location.locationInfo.phone2, [
          Validators.maxLength(13),
          Validators.minLength(10)
        ]),
        phone3: new FormControl(this.location.locationInfo.phone3, [
          Validators.maxLength(13),
          Validators.minLength(10)
        ])
      }),
      new FormGroup({
        map: new FormControl('', [
          ValidationService.imageSizeValidator(512000)
        ]),
        mapUrl: new FormControl(this.location.map.url, 
          [ValidationService.urlValidator()])
      }),
      new FormGroup({
        gallery0: new FormControl('', [
          ValidationService.imageSizeValidator(512000)
        ]),
        gallery1: new FormControl('', [
          ValidationService.imageSizeValidator(512000)
        ]),
        gallery2: new FormControl('', [
          ValidationService.imageSizeValidator(512000)
        ]),
        gallery3: new FormControl('', [
          ValidationService.imageSizeValidator(512000)
        ]),
        gallery4: new FormControl('', [
          ValidationService.imageSizeValidator(512000)
        ]),
        gallery5: new FormControl('', [
          ValidationService.imageSizeValidator(512000)
        ]),
        gallery6: new FormControl('', [
          ValidationService.imageSizeValidator(512000)
        ]),
        gallery7: new FormControl('', [
          ValidationService.imageSizeValidator(512000)
        ]),
      })
    ]);
    this.locationBody = new FormGroup({ formArray: this.formArray });
  }

  public handleImageLoad(property: string) {
    this.imageLoaded[property].loaded = true;
  }
  
  public handleInputChange(propertyName: string, arrayPosition: number, e) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this, propertyName);
    reader.readAsDataURL(file);
    this.formArray
      .at(arrayPosition)
      .get(propertyName)
      .patchValue(file);
  }

  public _handleReaderLoaded(property, e) {
    const reader = e.target;
    this.imageLoaded[property].file = reader.result;

    const image = new Image();
    image.src = reader.result;
    image.onload = () => {
      if(property === 'map'){
        if(image.width === 290 && image.height === 105){
          this.mapMessage = false;
        } else {
          this.mapMessage = true;
        }
      } else {
        let pos = this.galleryList.findIndex(gallery => gallery == property);
        if(image.width === 960 && image.height === 540){
          this.messageArray[pos] = false;
        } else {
          this.messageArray[pos] = true;
        }
      }
    }
  }

  public editLocation(): void {
    if(this.locationBody.valid) {
      this.loading = true;
      const locationBody: any = Object.assign(this.formArray.value[0], this.formArray.value[1], this.formArray.value[2]);
      this.locationWebService.putWebLocation(this.location.id, locationBody).subscribe(
        (data: LocationWebInfo) => {
          this.loading = false;
          if (data) {
            this.dialogRef.close(data);
          }
        },
        (error: Error) => {
          this.loading = false;
        }
      );
    } else
      this.validForm = true;
  }

  public addImageContainer(){
    const container = document.querySelector('.image'+ this.imageCount);
    container.classList.remove('hidden');
    container.classList.add('visible');
    this.imageCount++;
    if(this.imageCount >= 8){
      const button = document.querySelector('.addImages');
      button.classList.add('hidden');
    }
  }
}
