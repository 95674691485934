import * as Model from '../models/Model';
import { environment } from './../../../environments/environment';

export const URLPath = environment.prefix + 'offsite.gamersarena.com.mx';

export const URLOnsite = environment.prefix;

export const URLTournament = environment.prefix + 'tournament.gamersarena.com.mx';

export class EnvironmentURL {

  public GetDomainURL(): string {
    const authorization: Model.AuthAccessURL = JSON.parse(localStorage.getItem('authorization'));
    if (authorization) {
      if (authorization.userAuthorization === 'super') {
        return URLPath;
      } else if (authorization.userAuthorization === 'onsite') {
        return 'https://staging.' + authorization.locationDomain;
      }
    }
    return URLPath;
  }
}
