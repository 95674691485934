import { TimeProductsService } from './../services/TimeProducts.service';
import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { TimeProduct } from '../models/TimeProduct.model';

@Component({
  selector: 'app-products-list',
  templateUrl: './list.products.component.html',
  styleUrls: ['./list.products.component.scss']
})
export class ProductListComponent implements OnInit {
  public source: LocalDataSource;
  private timeProducts: TimeProduct[];
  public isLoadingTimeProducts = false;
  public countries = [];
  public settings = {
    mode: 'external',
    actions: {
      add: false,
      delete: false,
      edit: false,
      position: 'right',
      columnTitle: ''
    },
    columns: {
      location: {
        title: 'Sucursal',
        filter: false
      },
      name: {
        title: 'Tiempo',
        filter: false
      },
      category: {
        title: 'Categoría',
        filter: false
      },
      price: {
        title: 'Costo',
        filter: false
      }
    }
  };

  constructor(
    private timeProductsService: TimeProductsService
  ) { }


  public ngOnInit(): void {
    this.source = new LocalDataSource();
    this.getTimeProducts();
  }

  public getTimeProducts(): void {
    this.isLoadingTimeProducts = true;
    this.timeProductsService.getTimeProducts().subscribe(
      data => {
        this.timeProducts = data;
        for (let product = 0; product < data.length; product++) {
          for (let productCost = 0; productCost < data[product].cost.length; productCost++) {
            this.source
              .add({
                productId: data[product].id,
                producCostId: data[product].cost[productCost].id,
                name: this.timeToString(data[product].minutes),
                type: 'Tiempo',
                location: data[product].cost[productCost].location.name,
                country: data[product].cost[productCost].country,
                category: data[product].category,
                price: (data[product].cost[productCost].coins > 1 ) ? data[product].cost[productCost].coins + ' Ares' : data[product].cost[productCost].coins + ' Are',
              })
              .catch();
            this.countries.push(data[product].cost[productCost].country);
          }
        }
        this.countries = this.countries.filter((x, i, a) => a.indexOf(x) === i);
        this.source.refresh();
        this.isLoadingTimeProducts = false;
      },
      error => {
        this.isLoadingTimeProducts = false;
      }
    );
  }

  private timeToString(time: number) {
    let text: string;
    switch (time) {
      case 30:
        text = '30 min';
        break;
      case 60:
        text = '1 hora';
        break;
      case 180:
        text = '3 horas';
        break;
      case 1440:
        text = '1 día';
        break;
      default:
        text = String(time);
        break;
    }
    return text;
  }
}
