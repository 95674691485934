import { Mode } from './../../TournamentModesModule/models/mode.model';
import { Game } from './../../TournamentGamesModule/models/game.model';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TournamentEditComponent } from '../tournament-edit/tournament-edit.component';
import { TournamentTemplate } from '../models';

@Component({
  selector: 'app-tournament-view',
  templateUrl: './tournament-view.component.html',
  styleUrls: ['./tournament-view.component.css']
})
export class TournamentViewComponent {
  @Input() tournamentTemplate: TournamentTemplate;
  @Input() tournamentTemplates: TournamentTemplate[];
  @Input() tournamentGames: Game[];
  @Input() tournamentModes: Mode[];

  constructor(private dialog: MatDialog) {
  }

  public editTournamentTemplate(): void {
    const dialogRef = this.dialog.open(TournamentEditComponent, {
      data: {
        tournamentTemplate: this.tournamentTemplate,
        tournamentGames: this.tournamentGames,
        tournamentModes: this.tournamentModes
      }
    });
    dialogRef.afterClosed().subscribe((data: TournamentTemplate) => {
      if (data) {
        this.tournamentTemplate = data;
        this.tournamentTemplates.find((template: TournamentTemplate) => {
          if (template.id === data.id) {
            template = data;
          }
          return template.id === data.id;
        });
      }
    });
  }

  public closeTournamentTemplateDetails(): void {
    const table = document.querySelector('.tableGent');
    table.classList.remove('tableMin');
    const detail = document.querySelector('.detail');
    detail.classList.remove('visible');
    detail.classList.add('hidden');
  }
}
