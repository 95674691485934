import { Inject, Injectable, Optional } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { ResponsesService } from './Responses.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { EnvironmentURL, URLPath } from './Config';

import * as Model from '../models/Model';
import { JWTHandler } from './JWT.handler';

@Injectable()
export class AssistantsService {
  protected basePath;
  public defaultHeaders: Headers = new Headers();
  

  constructor(
    private responsesService: ResponsesService,
    protected http: Http
  ) {
    let urlProvider = URLPath;
    this.basePath = urlProvider;
    
  }

  public deleteAssistant(id: string): Observable<Model.Assistant> {
    return this.deleteAssistantWithHttpInfo(id)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Usuario asistente eliminado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public getAssistants(): Observable<Model.Assistant[]> {
    return this.getAssistantsWithHttpInfo()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public postAssistant(assistantInfo: any, phoneCode: string): Observable<Model.Assistant> {
    let assistantBody: Model.AssistantBody = assistantInfo;
    let assistant_aux = {
      email: assistantInfo.email,
      lastName: assistantInfo.lastName,
      name: assistantInfo.name,
      phone: phoneCode + ' ' + assistantBody.phone,
      position: assistantInfo.position,
      username: assistantInfo.username
    };
    return this.postAssistantWithHttpInfo(assistant_aux)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Usuario asistente creado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public postAssistantPassword(assistantPassword: Model.AssistantPassword, token: string): Observable<Model.Assistant> {
    return this.postAssistantPasswordWithHttpInfo(assistantPassword, token)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Usuario asistente creado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.passwordSetResponse(error));
      });
  }

  public putAssistant(id: string, assistantUpdate: Model.AssistantBody): Observable<Model.Assistant> {
    // assistantUpdate.phone = phoneCode + ' ' +  assistantUpdate.phone;
    return this.putAssistantWithHttpInfo(id, assistantUpdate)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Usuario asistente editado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  private deleteAssistantWithHttpInfo(id: string): Observable<Response> {
    const path = this.basePath + `/assistants/${id}`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (id === null || id === undefined)
      throw new Error();

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Delete,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private getAssistantsWithHttpInfo(): Observable<Response> {
    const path = this.basePath + `/assistants`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private postAssistantWithHttpInfo(assistantInfo: Model.AssistantBody): Observable<Response> {
    const path = this.basePath + `/assistants`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (assistantInfo === null || assistantInfo === undefined) {
      throw new Error('Required parameter assistantInfo was null or undefined when calling postAssistant.');
    }

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: assistantInfo == null ? '' : JSON.stringify(assistantInfo),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private postAssistantPasswordWithHttpInfo(assistantInfo: Model.AssistantPassword, token: string): Observable<Response> {
    const path = this.basePath + `/assistants/password`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());


    headers.append('Authorization', 'JWT ' + token);
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: assistantInfo == null ? '' : JSON.stringify(assistantInfo),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private putAssistantWithHttpInfo(id: string, assistantUpdate: Model.AssistantUpdate): Observable<Response> {
    const path = this.basePath + `/assistants/${id}`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (id === null || id === undefined) {
      throw new Error();
    }
    if (assistantUpdate === null || assistantUpdate === undefined) {
      throw new Error();
    }

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      body: assistantUpdate == null ? '' : JSON.stringify(assistantUpdate),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

}
