import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Headers, Http, URLSearchParams } from '@angular/http';
import {
  RequestMethod,
  RequestOptions,
  RequestOptionsArgs
} from '@angular/http';

import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { URLPath } from '../../../../core/services/Config';
import { URLOnsite } from '../../../../core/services/Config';
import { ResponsesService } from '../../../../core/services/Responses.service';
import { JWTHandler } from '../../../../core/services/Service';

import { LocationWebInfo } from '../models/location-web-info.model';
import { LocationWebInfoBody } from '../models/location-web-info-body.model';

@Injectable()
export class LocationWebService {
  protected basePath = URLOnsite;
  public defaultHeaders: Headers = new Headers();

  constructor(
    protected http: Http,
    private responsesService: ResponsesService
  ) {
    this.basePath = URLPath;
  }

  public getWebLocations(): Observable<LocationWebInfo[]> {
    return this.getWebLocationsithHttpInfo()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(
          this.responsesService.GenericGetResponse(error)
        );
      });
  }

  public putWebLocation(
    locationId: number,
    locationInfoBody: LocationWebInfoBody
  ): Observable<LocationWebInfo> {
    return this.putWebLocationWithHttpInfo(locationId, locationInfoBody)
      .map((response: Response) => {
        return this.responsesService
          .GenericPostResponse(response, 'Éxito', 'Sucursal actualizada.')
          .json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(
          this.responsesService.GenericPostResponse(error)
        );
      });
  }


  private getWebLocationsithHttpInfo(): Observable<Response> {
    const path = this.basePath + `/locations/info`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private putWebLocationWithHttpInfo(
    locationId: number,
    locationInfoBody: LocationWebInfoBody
  ): Observable<Response> {
    const path = this.basePath + `/locations/${locationId}/info`;

    const headers = new Headers(this.defaultHeaders.toJSON());
    const formData: FormData = new FormData();

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    for (const property in locationInfoBody) {
      if (property.includes('gallery')) {
        if (locationInfoBody[property]) {
          formData.append('gallery', locationInfoBody[property]);
        }
      } else if (property) {
        if (locationInfoBody[property]) {
          formData.append(property, locationInfoBody[property]);
        }
      }
    }

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers
    });

    return this.http.put(path, formData, requestOptions);
  }
}
