import base64url from 'base64url';
/// <reference path="../../../../node_modules/@types/crypto-js/index.d.ts" />
import * as crypto from 'crypto-js';

export class JWTHandler {
  private static encodeToBase64URL(encodedBase64: string): string {
    encodedBase64 = base64url.fromBase64(encodedBase64);
    return encodedBase64;
  }

  public static generateJWT(): string {

    const user = JSON.parse(localStorage.getItem('user'));

    const header = {
      'alg': 'HS256',
      'typ': 'JWT'
    };

    const payload = {
      'sub': user.accessKey.subject,
      'iat': Number((Math.floor(Date.now() / 1000) - 30))
    };

    const encodedHeader = this.generateBase64EncodedString(JSON.stringify(header));
    const encodedPayload = this.generateBase64EncodedString(JSON.stringify(payload));

    const token = this.encodeToBase64URL(encodedHeader + '.' + encodedPayload);

    const signature = crypto.HmacSHA256(token, user.accessKey.secret);
    const signed = this.encodeToBase64URL(crypto.enc.Base64.stringify(signature));
    const JWT = token + '.' + signed;

    return JWT;
  }

  private static generateBase64EncodedString(encodedString): string {
    const stringified = crypto.enc.Utf8.parse(encodedString);
    return crypto.enc.Base64.stringify(stringified);
  }
}
