import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Component, EventEmitter, ViewChild, Output, OnInit } from '@angular/core';

import * as Module from '../Module';
import * as Model from '../../../../core/models/Model';
import * as Service from '../../../../core/services/Service';

@Component({
  selector: 'app-kiosks-list',
  templateUrl: './list.kiosks.component.html',
  styleUrls: ['./list.kiosks.component.scss']
})

export class KioskListComponent implements OnInit {
  public isLoadingKiosks = false;
  public kioskList: MatTableDataSource<Model.Kiosk>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public displayedColumns: string[] = ['name', 'status', 'validate', 'delete'];

  private _kiosks: Model.Kiosk[];
  public get kiosks(): Model.Kiosk[] {
    return this._kiosks;
  }
  public set kiosks(value: Model.Kiosk[]) {
    this.isLoadingKiosks = true;
    this.kiosksService.getKiosks().subscribe(
      (data: Model.Kiosk[]) => {
        this._kiosks = data;
        this.kioskList = new MatTableDataSource(this._kiosks);
        this.kioskList.paginator = this.paginator;
        this.isLoadingKiosks = false;
      });
  }

  constructor(
    private router: Router,
    private kiosksService: Service.KiosksService,
    public dialog: MatDialog
  ) { }

  public ngOnInit(): void {
    this.kiosks = [];
  }

  public onSearch(query: string = '') {
    query = query.trim();
    query = query.toLowerCase();
    this.kioskList.filter = query;
  }

  public activateKiosk(kiosk: Model.Kiosk): void {
    const dialogRef = this.dialog.open(Module.KioskActivationComponent, {
      data: kiosk
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data !== null || data !== undefined) {
          this.kioskList.data.find((kioskToFind) => {
            if (kioskToFind.subject === data.subject) {
              kioskToFind.acceptedAt = data.acceptedAt;
            }
            return kioskToFind.subject === data.subject;
          });
          this.kioskList.data = this.kioskList.data;
        }
      },
      error => {
      });
  }

  public deactivateKiosk(kiosk: Model.Kiosk): void {
    const dialogRef = this.dialog.open(Module.KioskDeactivationComponent, {
      data: kiosk
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data !== null || data !== undefined) {
          this.kioskList.data = this.kioskList.data.filter((kiosk) => {
            return kiosk.subject !== data.subject;
          });
        }
      },
      error => {
      });
  }
}
