import { Inject, Injectable, Optional } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { ResponsesService } from '../../../../core/services/Responses.service';
import { EnvironmentURL } from '../../../../core/services/Config';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { URLOnsite } from '../../../../core/services/Config';

import * as Model from '../models/Model';
import { JWTHandler } from '../../../../core/services/Service';

@Injectable()
export class GamesBySystemService {
  protected basePath = URLOnsite;
  public defaultHeaders: Headers = new Headers();


  constructor(
    protected http: Http,
    private responsesService: ResponsesService
  ) {
    const urlProvider = new EnvironmentURL();
    this.basePath = urlProvider.GetDomainURL();

  }

  public getSystems(): Observable<Model.System[]> {
    return this.getSystemsWithHttpInfo()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public getGamesBySystem(systemName?: string, excludeSystem?: string): Observable<Model.GameBySystem[]> {
    return this.getGamesBySystemWithHttpInfo(systemName, excludeSystem)
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }


  public postGameBySystem(id: number, gameSystemBody: Model.GameSystemBody[]): Observable<Model.GameSystem[]> {
    return this.postGameBySystemWithHttpInfo(id, gameSystemBody)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Juego por consola creado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public putGameBySystem(id: number, gameSystemBody: Model.GameSystemBody[]): Observable<Model.GameSystem[]> {
    return this.putGameBySystemWithHttpInfo(id, gameSystemBody)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Juego por consola editado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  private getSystemsWithHttpInfo(): Observable<Response> {
    const path = this.basePath + `/systems`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private getGamesBySystemWithHttpInfo(systemName?: string, excludeSystem?: string): Observable<Response> {
    const path = this.basePath + `/games/by_system`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());


    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private postGameBySystemWithHttpInfo(id: number, gameSystemBody: Model.GameSystemBody[]): Observable<Response> {
    const path = this.basePath + `/games/${id}/game_systems`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (id === null || id === undefined)
      throw new Error('Required parameter id was null or undefined when calling postGameBySystem.');

    if (gameSystemBody === null || gameSystemBody === undefined)
      throw new Error('Required parameter gameSystemBody was null or undefined when calling postGameBySystem.');

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: gameSystemBody == null ? '' : JSON.stringify(gameSystemBody),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private putGameBySystemWithHttpInfo(id: number, gameSystemBody: Model.GameSystemBody[]): Observable<Response> {
    const path = this.basePath + `/games/${id}/game_systems`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (id === null || id === undefined)
      throw new Error();

    if (gameSystemBody === null || gameSystemBody === undefined)
      throw new Error();

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Patch,
      headers: headers,
      body: gameSystemBody == null ? '' : JSON.stringify(gameSystemBody),
      search: queryParameters
    });
    return this.http.request(path, requestOptions);
  }
}
