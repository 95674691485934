import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { HomeRouting, HomeComponent } from './Module';
import { MatCardModule, MatButtonModule, MatIconModule, MatSelectModule, MatDialogModule, MatMenuModule, MatProgressSpinnerModule } from '@angular/material';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NotificationsService } from 'angular2-notifications';
import { LoginModule } from '../LoginModule/login.module';
import { SwitcherModule } from '../LocationSwitcherModule/switcher.module';
import { OnsiteModule } from '../OnsiteModules/onsite.module';
import { OffsiteModule } from '../OffsiteModules/offsite.module';
import { WebModule } from './../WebModules/web.module';
import { TournamentModule } from '../TournamentModules/tournament.module';
import { ResponsesService } from '../../core/services/Responses.service';


@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    OnsiteModule,
    OffsiteModule,
    TournamentModule,
    WebModule,
    LoginModule,
    SwitcherModule,
    HomeRouting,

    BrowserModule,
    SimpleNotificationsModule,
    HttpModule,
    FormsModule,
    MatDialogModule,
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule
  ],
  exports: [
    HomeComponent
  ],
  providers: [
    NotificationsService,
    ResponsesService
  ]
})
export class HomeModule { }
