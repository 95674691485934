import { Component, Inject } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import * as Service from '../../../../core/services/Service';
import * as Model from '../../../../core/models/Model';
import * as Module from '../Module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-user-deactivation',
  templateUrl: './deactivate.stations.component.html',
  styleUrls: ['./deactivate.stations.component.scss']

})

export class StationDeactivationComponent {
  loading = false;

  constructor(
    private router: Router,
    private stationsService: Service.StationsService,
    public dialogRef: MatDialogRef<StationDeactivationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Model.Station
  ) { }

  public DeactivateStation(): void {
    this.loading = true;
    this.stationsService.putStationStandby(this.data.name).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close(data);
      },
      error => {
        this.loading = false;

      });

  }
}
