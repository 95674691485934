import { MatDialog } from '@angular/material';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';

import * as Module from '../Module';
import * as Model from '../../../../core/models/Model';
import * as Service from '../../../../core/services/Service';

@Component({
  selector: 'app-pc-list',
  templateUrl: './list.pc.component.html',
  styleUrls: ['./list.pc.component.scss']
})

export class PCListComponent implements OnInit {
  public pcTimerList: MatTableDataSource<Model.Timer>;
  public isLoadingPcs = false;
  public displayedColumns: string[] = ['name', 'status', 'validate', 'delete'];
  @ViewChild(MatPaginator) public paginator: MatPaginator;

  private _pcTimers: Model.Timer[];
  public get pcTimers(): Model.Timer[] {
    return this._pcTimers;
  }
  public set pcTimers(value: Model.Timer[]) {
    this.isLoadingPcs = true;
    this.timersService.getTimers(null, null, true).subscribe(
      (data: Model.Timer[]) => {
        this._pcTimers = data;
        this.pcTimerList = new MatTableDataSource(this._pcTimers);
        this.pcTimerList.paginator = this.paginator;
        this.isLoadingPcs = false;
      });
  }

  constructor(
    private timersService: Service.TimersService,
    public dialog: MatDialog
  ) { }

  public ngOnInit(): void {
    this.pcTimers = [];
  }

  public onSearch(query: string = '') {
    query = query.trim();
    query = query.toLowerCase();
    this.pcTimerList.filter = query;
  }

  public activateTimer(timer: Model.Timer): void {
    const dialogRef = this.dialog.open(Module.PCActivationComponent, {
      data: timer
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data !== null || data !== undefined) {
          this.pcTimerList.data.find((pcTimerToFind) => {
            if (pcTimerToFind.subject === data.subject) {
              pcTimerToFind.acceptedAt = data.acceptedAt;
            }
            return pcTimerToFind.subject === data.subject;
          });
          this.pcTimerList.data = this.pcTimerList.data;
        }
      },
      error => {
      });
  }

  public deleteTimer(timer: Model.Timer): void {
    const dialogRef = this.dialog.open(Module.PCDeactivationComponent, {
      data: timer
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data !== null || data !== undefined) {
          this.pcTimerList.data = this.pcTimerList.data.filter((pcTimer: Model.Timer) => {
            return pcTimer.subject !== data.subject;
          });
        }
      },
      error => {
      });
  }
}
