import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class ResponsesService {
  constructor(
    private notificationsService: NotificationsService,
    private router: Router
  ) { }

  public PostUserResponse(response: any, notificationTitle?: string, notificationBody?: string): Response {
    switch (response.status) {
      case 200:
        this.Notificate(
          'success',
          (notificationTitle !== undefined) ? notificationTitle : 'Éxito',
          (notificationBody !== undefined) ? notificationBody : 'Usuario creado');
        break;
      case 201:
        this.Notificate(
          'success',
          (notificationTitle !== undefined) ? notificationTitle : 'Éxito',
          (notificationBody !== undefined) ? notificationBody : 'Usuario creado');
        break;
      case 400:
        this.Notificate(
          'error',
          (notificationTitle !== undefined) ? notificationTitle : response._body.message,
          (notificationBody !== undefined) ? notificationBody : JSON.stringify(response._body.error.errors));
        break;
      case 401:
        this.router.navigate(['/login']);
        this.Notificate(
          'error',
          (notificationTitle !== undefined) ? notificationTitle : response._body.message,
          (notificationBody !== undefined) ? notificationBody : response._body.detailedInfo);
        break;
      case 409:
        this.Notificate(
          'error',
          'Usuario duplicado',
          'Correo previamente registrado');
        break;
      case 422:
        this.Notificate(
          'error',
          'Campos incorrectos',
          JSON.stringify(response._body.error.errors)
        );
        break;
      case 504:
        this.Notificate('error', 'Conexión a servidor', 'Timeout');
        break;
      default:
        this.Notificate('error', response._body.message, (response._body.detailedInfo.email) ? 'Email "' + response._body.detailedInfo.email + '" not found.' : response._body.detailedInfo);
        break;
    }

    return response;
  }

  public GenericResponse(response: any, message?: string): Response {

    switch (response.status) {
      case 200:
        this.Notificate('success', 'Éxito', (message) ? message : ' ');
        break;
      case 401:
        this.router.navigate(['/login']);
        break;
      default:
        this.Notificate('error', response._body.message, JSON.stringify(response._body.detailedInfo));
        break;
    }

    return response;
  }

  public GenericGetResponse(response: any): Response {
    switch (response.status) {
      case 404:
        this.Notificate('error', 'Error', 'Ruta no encontrada');
        break;
      case 401:
        this.router.navigate(['/login']);
        break;
      case 403:
        this.Notificate('error', 'Error', 'Acción no permitida en horario laboral');
        break;
      case 504:
        this.Notificate('error', 'Conexión a servidor', 'Timeout');
        break;
      case 400:
        if (response._body) {
          if (response._body.errors[0].message) {
            this.Notificate('error', response._body.errors[0].message, '');
          }
        }
        break;
    }

    return response;
  }

  public Notificate(notificationType: string, title: string, body: string) {
    this.notificationsService['html'](this.CustomHTMLNotificationGenerator(notificationType, title, body), notificationType,
      {
        showProgressBar: false,
        pauseOnHover: false,
        clickToClose: true
      }
    );
  }

  private CustomHTMLNotificationGenerator(notificationType: string, title: string, body: string): string {
    return '<section class="alert ' + notificationType + '">' +
      '<h1>' + title + '</h1>' +
      '<p>' + body + '</p>' +
      '<div>CERRAR</div>' +
      '</section>';
  }

  public GenericPostResponse(response: any, notificationTitle?: string, notificationBody?: string): Response {
    switch (response.status) {
      case 200:
        this.Notificate(
          'success',
          (notificationTitle !== undefined) ? notificationTitle : 'Éxito',
          (notificationBody !== undefined) ? notificationBody : 'Usuario creado');
        break;
      case 201:
        this.Notificate(
          'success',
          (notificationTitle !== undefined) ? notificationTitle : 'Éxito',
          (notificationBody !== undefined) ? notificationBody : 'Usuario creado');
        break;
      case 204:
        this.Notificate(
          'success',
          (notificationTitle !== undefined) ? notificationTitle : 'Éxito',
          (notificationBody !== undefined) ? notificationBody : 'Solicitud procesada correctamente.');
        break;
      case 400:
        let responseMessage: string;
        if (response._body.errors > 0) {
          responseMessage = JSON.stringify(response._body.errors);
        } else if (response._body.error) {
          responseMessage = JSON.stringify(response._body.error);
        } else {
          responseMessage = '';
        }
        this.Notificate(
          'error',
          (notificationTitle !== undefined) ? notificationTitle : response._body.message,
          (notificationBody !== undefined) ? notificationBody : responseMessage);
        break;
      case 401:
        this.router.navigate(['/login']);
        this.Notificate(
          'error',
          (notificationTitle !== undefined) ? notificationTitle : response._body.message,
          (notificationBody !== undefined) ? notificationBody : response._body.errors);
        break;
      case 403:
        this.Notificate(
          'error',
          (notificationTitle !== undefined) ? notificationTitle : response._body.message,
          (notificationBody !== undefined) ? notificationBody : response._body.errors);
        break;
      case 404:
        this.Notificate('error', 'Error', 'Ruta no encontrada');
        break;
      case 409:
        this.Notificate(
          'error',
          (notificationTitle !== undefined) ? notificationTitle : response._body.message,
          (notificationBody !== undefined) ? notificationBody : response._body.errors);
        break;
      case 422:
        this.Notificate(
          'error',
          'Campos incorrectos',
          JSON.stringify(response._body.errors)
        );
        break;
      case 504:
        this.Notificate('error', 'Conexión a servidor', 'Timeout');
        break;
      default:
        this.Notificate('error', response._body.message, JSON.stringify(response._body.error.erros));
        break;
    }

    return response;
  }

  public passwordSetResponse(response: any, notificationTitle?: string, notificationBody?: string): Response {

    switch (response.status) {
      case 200:
        this.Notificate(
          'success',
          (notificationTitle !== undefined) ? notificationTitle : 'Éxito',
          (notificationBody !== undefined) ? notificationBody : 'Contraseña actualizada');
        break;
      case 201:
        this.Notificate(
          'success',
          (notificationTitle !== undefined) ? notificationTitle : 'Éxito',
          (notificationBody !== undefined) ? notificationBody : 'Usuario creado');
        break;
      case 204:
        this.Notificate(
          'success',
          (notificationTitle !== undefined) ? notificationTitle : 'Éxito',
          (notificationBody !== undefined) ? notificationBody : 'Solicitud procesada correctamente.');
        break;
      case 400:
        this.Notificate(
          'error',
          (notificationTitle !== undefined) ? notificationTitle : response._body.message,
          (notificationBody !== undefined) ? notificationBody : JSON.stringify(response._body.error.errors));
        break;
      case 401:
        this.Notificate(
          'error',
          (notificationTitle !== undefined) ? notificationTitle : response._body.message,
          (notificationBody !== undefined) ? notificationBody : JSON.stringify(response._body.errors));
        break;
      case 403:
        this.Notificate(
          'error',
          (notificationTitle !== undefined) ? notificationTitle : response._body.message,
          (notificationBody !== undefined) ? notificationBody : JSON.stringify(response._body.errors));
        break;
      case 422:
        this.Notificate(
          'error',
          'Campos incorrectos',
          JSON.stringify(response._body.error.errors)
        );
        break;
      case 504:
        this.Notificate('error', 'Conexión a servidor', 'Timeout');
        break;
      default:
        this.Notificate('error', response._body.message, JSON.stringify(response._body.errors));
        break;
    }

    return response;
  }

}
