import { Inject, Injectable, Optional } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { ResponsesService } from '../../../../../core/services/Responses.service';
import { EnvironmentURL } from '../../../../../core/services/Config';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { URLOnsite, URLPath } from '../../../../../core/services/Config';

import * as Model from '../models/index';
import { JWTHandler } from '../../../../../core/services/Service';

@Injectable()
export class TournamentTemplatesService {
  protected offsitePath = URLPath;

  public defaultHeaders: Headers = new Headers();
  

  constructor(protected http: Http, private responsesService: ResponsesService) {
    const urlProvider = new EnvironmentURL();
    
  }

  public getTournamentTemplates(): Observable<Model.TournamentTemplate[]> {
    return this.getTournamentsWithHttpInfo()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public postTournamentTemplate(tournamentBody: Model.TournamentTemplateBody): Observable<Model.TournamentTemplate> {
    return this.postTournamentTemplateWithHttpInfo(tournamentBody)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Plantilla de torneo creada.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public editTournamentTemplate(tournamentBody: Model.TournamentTemplateBody, template_id): Observable<Model.TournamentTemplate> {
    return this.editTournamentTemplateWithHttpInfo(tournamentBody, template_id)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Plantilla de torneo editada.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public putTournamentTemplate(tournamentBody: Model.TournamentTemplateBody): Observable<Model.TournamentTemplate> {
    return this.putTournamentTemplateWithHttpInfo(tournamentBody)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Plantilla de torneo actualizada.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public deleteTournamentTemplate(tournamentId: number): Observable<Model.TournamentTemplate> {
    return this.deleteTournamentTemplateWithHttpInfo(tournamentId)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Plantilla de torneo eliminada.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public getCriteria(): Observable<Model.Criteria[]> {
    return this.getCriteriaWithHttpInfo()
      .map((response: any) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public postCriteria(criteriaBody: Model.CriteriaBody): Observable<Model.Criteria> {
    return this.postCriteriaWithHttpInfo(criteriaBody)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Criterio de desempate creado.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  private getTournamentsWithHttpInfo(): Observable<Response> {
    const path = this.offsitePath + `/tournament_templates`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }



  private postTournamentTemplateWithHttpInfo(tournamentBody: Model.TournamentTemplateBody): Observable<Response> {
    tournamentBody.maxPlayersPerTeam = +tournamentBody.maxPlayersPerTeam;
    tournamentBody.minPlayersPerTeam = +tournamentBody.minPlayersPerTeam;
    const path = this.offsitePath + `/tournament_templates`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (tournamentBody === null || tournamentBody === undefined) {
      throw new Error('Required parameter tournamentBody was null or undefined when calling postTournamentBody.');
    }

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: name == null ? '' : JSON.stringify(tournamentBody),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private editTournamentTemplateWithHttpInfo(tournamentBody: Model.TournamentTemplateBody, template_id): Observable<Response> {
    tournamentBody.maxPlayersPerTeam = +tournamentBody.maxPlayersPerTeam;
    tournamentBody.minPlayersPerTeam = +tournamentBody.minPlayersPerTeam;
    const path = this.offsitePath + `/tournament_templates/` + template_id;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (tournamentBody === null || tournamentBody === undefined) {
      throw new Error('Required parameter tournamentBody was null or undefined when calling postTournamentBody.');
    }

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      body: name == null ? '' : JSON.stringify(tournamentBody),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private putTournamentTemplateWithHttpInfo(tournamentBody: Model.TournamentTemplateBody): Observable<Response> {
    const path = this.offsitePath + `/tournament_templates`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (tournamentBody === null || tournamentBody === undefined) {
      throw new Error('Required parameter tournamentBody was null or undefined when calling postTournamentBody.');
    }

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      body: name == null ? '' : JSON.stringify(tournamentBody),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private deleteTournamentTemplateWithHttpInfo(tournamentId: number): Observable<Response> {
    const path = this.offsitePath + `/tournament_templates/` + tournamentId;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (tournamentId === null || tournamentId === undefined) {
      throw new Error('Required parameter tournamentBody was null or undefined when calling postTournamentBody.');
    }

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Delete,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private getCriteriaWithHttpInfo(): Observable<Response> {
    const path = this.offsitePath + `/criteria`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  private postCriteriaWithHttpInfo(tieBreakerBody: Model.CriteriaBody): Observable<Response> {
    const path = this.offsitePath + `/criteria`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    if (tieBreakerBody === null || tieBreakerBody === undefined) {
      throw new Error('Required parameter criteria was null or undefined when calling postTournamentBody.');
    }

    headers.append('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: name == null ? '' : JSON.stringify(tieBreakerBody),
      search: queryParameters
    });
    return this.http.request(path, requestOptions);
  }
}
