import { TournamentGroupTeamsComponent } from './../tournament-group-teams/tournament-group-teams.component';
import { MatDialog } from '@angular/material';
import { Component, Input, OnInit } from '@angular/core';
import { Tournament } from '../../../TournamentsModule/models';
import { TournamentHandlerService } from '../../services/tournamentHandler.service';

@Component({
  selector: 'app-tournament-statistics-view',
  templateUrl: './tournament-view.component.html',
  styleUrls: ['./tournament-view.component.css']
})
export class TournamentViewComponent implements OnInit {
  @Input() public tournament: any;

  private _selectedTournamentStatistics: any;
  public get selectedTournamentStatistics(): any {
    return this._selectedTournamentStatistics;
  }
  public set selectedTournamentStatistics(value: any) {
    this.tournamentHandlerService.getTournamentStatistics(this.tournament.id).subscribe(
      data => { this._selectedTournamentStatistics = data; }
    );
  }

  constructor(
    private tournamentHandlerService: TournamentHandlerService,
    private dialog: MatDialog,
  ) { }

  public ngOnInit() {
    this.selectedTournamentStatistics = [];
  }

  public showGroupPlayers(group: any): void {
    this.dialog.open(TournamentGroupTeamsComponent, { data: group });
  }

}
