import { Component, OnInit, Inject } from '@angular/core';
import { TestimonialsService } from 'app/AppModules/WebModules/TestimonialsModule/services/testimonials.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Testimonial } from 'app/AppModules/WebModules/TestimonialsModule/models/testimonial.model';

@Component({
  selector: 'app-testimonials-delete',
  templateUrl: './testimonials-delete.component.html',
  styleUrls: ['./testimonials-delete.component.css']
})
export class TestimonialsDeleteComponent {
  public loading = false;

  constructor(
    private testimonialsService: TestimonialsService,
    public dialogRef: MatDialogRef<TestimonialsDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public testimonial: Testimonial
  ) { }

  public deleteTestimonial(): void {
    this.loading = true;
    this.testimonialsService.deleteTestimonial(this.testimonial.id).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close({});
      },
      error => {
        this.loading = false;
      }
    );
  }
}
