import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthenticationGuard } from '../../../core/services/authentication.guard';
import { KioskListComponent } from './Module';

const kioskRoutes: Routes = [
  { path: 'kiosks', component: KioskListComponent, canActivate: [AuthenticationGuard] }
];

@NgModule({
  imports: [
    RouterModule.forChild(kioskRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class KioskRouting { }
