import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  MAT_DIALOG_DATA,
  MatButtonModule,
  MatCardModule,
  MatDialogRef,
  MatIconModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatTableModule,
} from '@angular/material';

import { NewsFeedCreateComponent } from './news-feed-create/news-feed-create.component';
import { NewsFeedDeleteComponent } from './news-feed-delete/news-feed-delete.component';
import { NewsFeedEditComponent } from './news-feed-edit/news-feed-edit.component';
import { NewsFeedListComponent } from './news-feed-list/news-feed-list.component';
import { NewsFeedService } from './services/news-feed.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule
  ],
  declarations: [
    NewsFeedCreateComponent,
    NewsFeedEditComponent,
    NewsFeedListComponent,
    NewsFeedDeleteComponent
  ],
  exports: [
    NewsFeedCreateComponent,
    NewsFeedEditComponent,
    NewsFeedListComponent,
    NewsFeedDeleteComponent
  ],
  entryComponents: [
    NewsFeedCreateComponent,
    NewsFeedEditComponent,
    NewsFeedDeleteComponent
  ],
  providers: [
    NewsFeedService,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ]
})
export class NewsFeedModule { }
