import { MatPaginator, MatTableDataSource, MatDialogRef } from '@angular/material';
import { TournamentGamesCreateComponent } from './../tournament-games-create/tournament-games-create.component';
import { TournamentGamesService } from '../services/tournamentGames.service';
import * as Model from '../models/index';
import { TournamentGamesEditComponent } from '../tournament-games-edit/tournament-games-edit.component';
import { ViewChild, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-tournament-games-list',
  templateUrl: './tournament-games-list.component.html',
  styleUrls: ['./tournament-games-list.component.css']
})
export class TournamentGamesListComponent implements OnInit {
  public tournamentGameList: MatTableDataSource<Model.Game>;
  public displayedColumns: string[] = ['name', 'edit'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public _tournamentGames: Model.Game[];
  public get tournamentGames(): Model.Game[] {
    return this._tournamentGames;
  }
  public set tournamentGames(games: Model.Game[]) {
    this.tournamentGamesService.getTournamentGames().subscribe((data: Model.Game[]) => {
      this._tournamentGames = data;
      this.tournamentGameList = new MatTableDataSource(data);
      this.tournamentGameList.paginator = this.paginator;
    });
  }

  constructor(private dialog: MatDialog, private tournamentGamesService: TournamentGamesService) {}

  public ngOnInit(): void {
    this.tournamentGames = [];
  }

  public onSearch(query: string = '') {
    query = query.trim();
    query = query.toLowerCase();
    this.tournamentGameList.filter = query;
  }

  public createTournamentGame(): void {
    const dialogRef = this.dialog.open(TournamentGamesCreateComponent);
    dialogRef.afterClosed().subscribe((data: Model.Game) => {
      if (data) {
        this.tournamentGames.push(data);
        this.tournamentGameList = new MatTableDataSource(this.tournamentGames);
        this.tournamentGameList.paginator = this.paginator;
      }
    });
  }

  public editTournamentGame(tournamentGame: Model.Game): void {
    const dialogRef = this.dialog.open(TournamentGamesEditComponent, {
      data: tournamentGame
    });
    dialogRef.afterClosed().subscribe((data: Model.Game) => {
      if (data) {
        this.tournamentGames.find((gameToFind: Model.Game) => {
          if (gameToFind.id === data.id) {
            gameToFind.name = data.name;
          }
          return gameToFind.id === data.id;
        });

        this.tournamentGameList = new MatTableDataSource(this.tournamentGames);
        this.tournamentGameList.paginator = this.paginator;
      }
    });
  }
}
