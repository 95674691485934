import { NgModule } from '@angular/core';
import {
  MatDialogModule, MatIconModule, MatButtonModule, MatCardModule,
  MatInputModule, MatSelectModule, MatOptionModule,
  MatDialogRef, MAT_DIALOG_DATA, MatPaginatorModule
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AuthenticationGuard } from '../../../core/services/authentication.guard';
import { MatTableModule } from '@angular/material/table';

import * as Module from './Module';

import * as Service from '../../../core/services/Service';

@NgModule({
  declarations: [
    Module.KioskListComponent,
    Module.KioskActivationComponent,
    Module.KioskDeactivationComponent
  ],
  entryComponents: [
    Module.KioskActivationComponent,
    Module.KioskDeactivationComponent
  ],
  imports: [
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatOptionModule,
    BrowserModule,
    FormsModule,
    HttpModule,
    Module.KioskRouting
  ],
  exports: [
    Module.KioskListComponent,
    Module.KioskActivationComponent,
    Module.KioskDeactivationComponent
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    AuthenticationGuard,
    Service.KiosksService
  ]
})
export class KiosksModule { }