import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import * as Service from '../../../../core/services/Service';
import * as Model from '../../../../core/models/Model';

@Component({
  selector: 'app-applications-delete',
  templateUrl: './applications-delete.component.html',
  styleUrls: ['./applications-delete.component.css']
})
export class ApplicationsDeleteComponent implements OnInit {
  public application: Model.Application;
  public loading: boolean = false;
  constructor(
    private applicationsService: Service.ApplicationsService,
    public dialogRef: MatDialogRef<ApplicationsDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.application = data;
  }

  public ngOnInit() {
  }

  public deleteApplication(): void {
    this.loading = !this.loading;
    this.applicationsService.deleteApplication(String(this.application.id)).subscribe(
      data => {
        this.loading = !this.loading;
        this.dialogRef.close({});
      },
      error => {
        this.loading = !this.loading;
        this.dialogRef.close();
      }
    );
  }

}
