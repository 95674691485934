import { NgModule } from '@angular/core';
import * as Module from './Module';

@NgModule({
  imports: [
    Module.KiosksModule,
    Module.TimersModule,
    Module.ZonesModule,
    Module.PCModule,
    Module.StationsModule,
    Module.AssistantsModule,
    Module.LocationInfoModule,
    Module.ApplicationsModule,
    Module.ReportsModule,
    Module.GamesModule
  ],
  exports: [
    Module.KiosksModule,
    Module.TimersModule,
    Module.ZonesModule,
    Module.PCModule,
    Module.StationsModule,
    Module.AssistantsModule,
    Module.LocationInfoModule,
    Module.ApplicationsModule,
    Module.ReportsModule,
    Module.GamesModule
  ]
})
export class OnsiteModule { }
