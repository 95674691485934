import { NgModule } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatStepperIntl, MatMenuModule, MatIconModule, MatButtonModule, MatCardModule, MatInputModule, MatSelectModule, MatOptionModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AuthenticationGuard } from '../../../core/services/authentication.guard';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import * as Module from './Module';
import * as Service from '../../../core/services/Service';

@NgModule({
  declarations: [
    Module.StationListComponent,
    Module.StationViewComponent,
    Module.StationCreationComponent,
    Module.StationEditionComponent,
    Module.StationDeactivationComponent,
    Module.StationDeleteComponent
  ],
  entryComponents: [
    Module.StationEditionComponent,
    Module.StationCreationComponent,
    Module.StationDeactivationComponent,
    Module.StationDeleteComponent
  ],
  imports: [
    Ng2SmartTableModule,
    MatDialogModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatStepperModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatOptionModule,
    BrowserModule,
    FormsModule,
    HttpModule,
    ReactiveFormsModule,
    Module.StationsRouting
  ],
  exports: [
    Module.StationListComponent,
    Module.StationViewComponent,
    Module.StationCreationComponent,
    Module.StationEditionComponent,
    Module.StationDeactivationComponent,
    Module.StationDeleteComponent
  ],
  providers: [
    MatStepperIntl,
    AuthenticationGuard,
    Service.StationsService,
    Service.ZonesService,

  ]
})
export class StationsModule { }
