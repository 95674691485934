import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { MatDialog } from '@angular/material';

import * as Model from '../../../../core/models/Model';
import * as Service from '../../../../core/services/Service';
import * as Module from '../Module';

@Component({
  selector: 'app-assistant-list',
  templateUrl: './list.assistants.component.html',
  styleUrls: ['./list.assistants.component.scss']
})

export class AssistantListComponent implements OnInit {
  public source: LocalDataSource;
  private assistants: Model.Assistant[];

  constructor(
    private router: Router,
    private assistantsService: Service.AssistantsService,
    public dialog: MatDialog
  ) { }

  settings = {
    mode: 'external',
    actions: {
      add: false,
      delete: true,
      edit: true,
      position: 'right',
      columnTitle: '',
    },
    edit: {
      editButtonContent: "<i class='material-icons'>create</i>",
    },
    delete: {
      deleteButtonContent: "<i class='material-icons clearBtn'>clear</i>",
    },
    columns: {
      name: {
        title: 'Nombre',
        filter: false
      },
      username: {
        title: 'Username',
        filter: false
      },
      phone: {
        title: 'Teléfono',
        filter: false
      },
      email: {
        title: 'Email',
        filter: false
      },
      position: {
        title: 'Cargo',
        filter: false
      }
    }
  };

  private GetAvaliableAssistants(): void {
    this.source.empty().catch();
    this.assistantsService.getAssistants().subscribe(
      data => {
        this.assistants = data;
        for (let assistant = 0; assistant < data.length; assistant++)
          this.source.add({
            assistantId: data[assistant].id,
            name: data[assistant].profile.name + ' ' + data[assistant].profile.lastName,
            username: data[assistant].username,
            phone: data[assistant].profile.phone,
            email: data[assistant].email,
            position: data[assistant].position
          }).catch();
        this.source.refresh();
      },
      error => {

      }
    );
  }

  public ngOnInit(): void {
    this.source = new LocalDataSource();
    this.GetAvaliableAssistants();
  }

  public onSearch(query: string = '') {
    this.source.setFilter([
      {
        field: 'name',
        search: query
      }
    ], true);
  }

  public EditAssistant(event): void {

    const dialogRef = this.dialog.open(Module.AssistantEditComponent, {
      data: event.data,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data != null || data != undefined)
          this.source.update(event.data, { phone: data.profile.phone }).then().catch();
      },
      error => {


      });
  }

  public DeleteAssistant(event): void {
    const dialogRef = this.dialog.open(Module.AssistantDeactivationComponent, {
      data: event.data
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data != null || data != undefined)
          this.source.remove(event.data).then().catch();
      },
      error => {


      });
  }

  public CreateAssistant(): void {
    const dialogRef = this.dialog.open(Module.AssistantCreationComponent, {
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data != null || data != undefined) {
          this.assistants.push(data);
          this.source.add({
            assistantId: data.id,
            name: data.profile.name + ' ' + data.profile.lastName,
            username: data.username,
            phone: data.profile.phone,
            email: data.email,
            position: data.position
          }).catch();
          this.source.refresh();
        }
      },
      error => {


      });
  }
}
