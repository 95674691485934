import { Component, Inject } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import * as Service from '../../../../core/services/Service';
import * as Model from '../../../../core/models/Model';
import * as Module from '../Module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-kiosk-activation',
  templateUrl: './activate.kiosks.component.html',
  styleUrls: ['./activate.kiosks.component.scss']

})

export class KioskActivationComponent {
  loading = false;

  constructor(
    private kiosksService: Service.KiosksService,
    public dialogRef: MatDialogRef<KioskActivationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public ActivateKiosk(): void {
    this.loading = true;
    this.kiosksService.putKiosk(this.data.subject).subscribe(
      data => {
        this.loading = !this.loading;
        this.dialogRef.close(data);
      },
      error => {

        this.loading = false;
      });
  }
}
