import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material';

import { SwitcherViewComponent } from './switcher-view/view.switcher.component';

@Component({
  selector: 'app-switcher',
  templateUrl: './main.switcher.component.html',
  styleUrls: ['./main.switcher.component.scss']
})

export class SwitcherComponent {
  @Input() public validate = false;

  constructor(
    public dialog: MatDialog
  ) { }

  public LaunchLocationSelector(): void {
    this.dialog.open(SwitcherViewComponent);
  }
}
