import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-location-deactivation',
  templateUrl: './deactivate.location.component.html',
  styleUrls: ['./deactivate.location.component.scss']
})

export class LocationDeactivationComponent {
  constructor(
    public dialogRef: MatDialogRef<LocationDeactivationComponent>

  ) { }

}
