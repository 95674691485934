import { Inject, Component, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import * as Service from '../../../../core/services/Service';
import * as Model from '../../../../core/models/Model';
import * as Module from '../Module';

@Component({
  selector: 'app-user-edition',
  templateUrl: './edit.stations.component.html',
  styleUrls: ['./edit.stations.component.scss']
})

export class StationEditionComponent {
  public stationBody: FormGroup;
  public validForm = false;
  public station: Model.StationBody = {} as Model.StationBody;
  public zones: Model.Zone[];
  public categories: Model.Category[];
  public loading = false;
  systems = [];
  selected_systems = [];
  has_screen = false;

  constructor(
    private stationsService: Service.StationsService,
    private zonesService: Service.ZonesService,
    public dialogRef: MatDialogRef<StationEditionComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.station = data;
    this.stationBody = this.formBuilder.group({
      'name': [this.station.name, [
        Validators.required,
        Validators.pattern('^[A-Za-z\\d]+$')
      ]],
      'screen': [this.station.screen, [
      ]],
      'categoryId': [this.station.category.id, [
        Validators.required
      ]],
      'zoneId': [this.station.zone.id, [
        Validators.required
      ]],
      'seats': [this.station.seats, [
        Validators.required
      ]],
      'observations': [this.station.observations, [
      ]]
    });
    if (data.category.id === 1) {
      this.stationBody.controls.categoryId.disable();
    }
  }

  public ngOnInit(): void {
    this.stationsService.getCategories().subscribe(
      data => {
        if (data != undefined || data != null)
          this.categories = data;
          for (var i = 0; i < this.categories.length; i++) {
            if (this.categories[i].id === this.stationBody.value.categoryId) {
              this.systems = this.categories[i].systems;
              if (this.station.screen) {
                this.has_screen = true;
              }else {
                this.has_screen = false;
              }
            }
          }
          for (var i = 0; i < this.station.systems.length; i++) {
            this.selected_systems.push(this.station.systems[i].id);
          }
      }
    );

    this.zonesService.getZones().subscribe(
      data => {
        if (data != undefined || data != null)
          this.zones = data;
      }
    );
    this.station = this.data;
  }

  public EditStation(): void {
    this.validForm = true;
    if (this.stationBody.valid && this.screenValid()) {
      this.loading = true;
      this.stationsService.putStation(String(this.data.id), this.stationBody.value, this.selected_systems).subscribe(
        data => {
          this.loading = false;
          this.dialogRef.close(data);
        },
        error => {

          this.loading = false;
        });
    }
  }

  setSystems(categoryId){
    for (var i = 0; i < this.categories.length; i++) {
      if (categoryId === this.categories[i].id) {
        this.systems = this.categories[i].systems;
        this.has_screen = !this.categories[i].isPcOnly;
      }
    }
    this.selected_systems = [];
  }

  isSystemChecked(system_id){
    return this.selected_systems.includes(system_id);
  }

  onCheckboxChangeEvent(event){
    let system_checked_id = event.source.value;
    if (event.checked) {
      this.selected_systems.push(system_checked_id);
    }else {
      for (var i = 0; i < this.selected_systems.length; i++) {
        if (this.selected_systems[i] === system_checked_id) {
          this.selected_systems.splice(i,1);
        }
      }
    }
  }

  screenValid(){
    if (this.has_screen) {
      return this.stationBody.controls.screen.value;
    }
    return true;
  }
}