import { Inject, Injectable, Optional } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { JWTHandler } from './JWT.handler';
import { ResponsesService } from './Responses.service';

import { EnvironmentURL } from './Config';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import * as Model from '../models/Model';

@Injectable()
export class StationsService {

  protected basePath;
  protected baseOffsite;
  public defaultHeaders: Headers = new Headers();

  constructor(
    protected http: Http,
    private responsesService: ResponsesService
  ) {
    let urlProvider = new EnvironmentURL();
    this.basePath = urlProvider.GetDomainURL();
    this.baseOffsite = urlProvider.GetDomainURL();

  }
  public getCategories(): Observable<Model.Category[]> {
    return this.getCategoriesWithHttpInfo()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public deleteStation(id: string): Observable<Model.Station> {
    return this.deleteStationWithHttpInfo(id)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Estación eliminada.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public getStationsMatches(stationName?: string): Observable<Model.Station[]> {
    return this.getStationsMatchesWithHttpInfo(stationName)
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public postStation(stationBody: Model.StationBody): Observable<Model.Station> {
    return this.postStationWithHttpInfo(stationBody)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Estación creada.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public postStationStandby(stationName: string): Observable<{}> {
    return this.postStationStandbyWithHttpInfo(stationName)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Estación suspendida.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public putStation(id: string, stationUpdate, systems): Observable<Model.CheckInsLastMatch> {
    return this.putStationWithHttpInfo(id, stationUpdate, systems)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Estación actualizada.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public putStationMatch(id: string, matchStation: Model.MatchStation): Observable<Model.CheckInsLastMatch> {
    return this.putStationMatchWithHttpInfo(id, matchStation)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Estación actualizada.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public putStationStandby(stationName: string): Observable<{}> {
    return this.putStationStandbyWithHttpInfo(stationName)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Estación suspendida.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public getCategoriesWithHttpInfo(): Observable<Response> {
    const path = this.baseOffsite + `/categories`;
    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);

  }
  public deleteStationWithHttpInfo(id: string): Observable<Response> {
    const path = this.basePath + `/stations/${id}`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());
    if (id === null || id === undefined)
      throw new Error();

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Delete,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  public getStationsMatchesWithHttpInfo(stationName?: string): Observable<Response> {
    const path = this.basePath + `/stations/matches`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  public postStationWithHttpInfo(stationBody: Model.StationBody): Observable<Response> {
    const path = this.basePath + `/stations`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());
    if (stationBody === null || stationBody === undefined) {
      throw new Error();
    }
    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: stationBody == null ? '' : JSON.stringify(stationBody),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  public postStationStandbyWithHttpInfo(stationName: string): Observable<Response> {
    const path = this.basePath + `/stations/standby_status`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());
    if (stationName === null || stationName === undefined)
      throw new Error();

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: stationName == null ? '' : JSON.stringify(stationName),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  public putStationWithHttpInfo(id: string, stationUpdate, systems): Observable<Response> {
    const path = this.basePath + `/stations/${id}`;

    stationUpdate['systemIds'] = systems;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());
    if (id === null || id === undefined)
      throw new Error();
    if (stationUpdate === null || stationUpdate === undefined)
      throw new Error();
    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      body: stationUpdate == null ? '' : JSON.stringify(stationUpdate),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  public putStationMatchWithHttpInfo(id: string, matchStation: Model.MatchStation): Observable<Response> {
    const path = this.basePath + `/stations/${id}/matches`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());
    if (id === null || id === undefined) {
      throw new Error();
    }
    if (matchStation === null || matchStation === undefined) {
      throw new Error();
    }
    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      body: matchStation == null ? '' : JSON.stringify(matchStation),
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  public putStationStandbyWithHttpInfo(stationName: string): Observable<Response> {
    const path = this.basePath + `/stations/standby_status`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());
    if (stationName === null || stationName === undefined) {
      throw new Error();
    }
    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());

    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Put,
      headers: headers,
      body: stationName == null ? '' : { 'name': stationName },
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }
}
