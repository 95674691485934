import { NgModule } from '@angular/core';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { MatDialogModule, MatMenuModule, MatIconModule, MatButtonModule, MatCardModule, MatInputModule, MatSelectModule, MatOptionModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';

import * as Module from './Module';
import * as Service from '../../../core/services/Service'
import { AuthenticationGuard } from 'app/core/services/authentication.guard';

@NgModule({
  declarations: [
    Module.ApplicationsCreationComponent,
    Module.ApplicationsListComponent,
    Module.ApplicationsDeleteComponent,
    Module.ApplicationsEditComponent,
    Module.ApplicationsViewComponent
  ],
  entryComponents: [
    Module.ApplicationsCreationComponent,
    Module.ApplicationsDeleteComponent,
    Module.ApplicationsEditComponent
  ],
  imports: [
    Ng2SmartTableModule,
    MatDialogModule,
    MatIconModule, 
    MatButtonModule, 
    MatCardModule, 
    MatInputModule, 
    MatSelectModule, 
    MatOptionModule,
    MatMenuModule, 
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    Module.ApplicationsRouting,
  ],
  exports: [
    Module.ApplicationsCreationComponent,
    Module.ApplicationsListComponent,
    Module.ApplicationsDeleteComponent,
    Module.ApplicationsEditComponent,
    Module.ApplicationsViewComponent
  ],
  providers: [
    AuthenticationGuard,
    Service.ApplicationsService
  ]
})
export class ApplicationsModule { }
