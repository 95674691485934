import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { Http } from '@angular/http';
import { NotificationsService } from 'angular2-notifications';

import * as Model from '../../../../core/models/Model';
import * as Service from '../../../../core/services/Service';
import * as Module from '../Module';

@Component({
  selector: 'app-locationInfo-rechargeList',
  templateUrl: './rechargesList.locationInfo.component.html',
  styleUrls: ['./rechargesList.locationInfo.component.scss']
})

export class LocationInfoRechargesListComponent implements OnInit {
  public firstTime = true;
  public source: LocalDataSource;
  private recharges: Model.RechargeInfo[];
  isLoadingRecharges: boolean = false;
  settings = {
    mode: 'external',
    actions: {
      add: false,
      delete: false,
      edit: false,
      position: 'right',
      columnTitle: '',
    },
    columns: {
      name: {
        title: 'Ares',
        filter: false
      },
      price: {
        title: 'Costo',
        filter: false
      }
    }
  };

  constructor(
    private locationInfo: Service.LocationInfoService,
    private notificationsService: NotificationsService
  ) { }

  public getRecharges(forceUpdate?: boolean): void {
    this.isLoadingRecharges = true;
    this.locationInfo.getTimeTable().subscribe(
      response => {
        let opening = response.opening.toString();
        let closing = response.closing.toString();
        if (!(parseInt(opening) <= parseInt(this.nowMilitarFormat()) && parseInt(this.nowMilitarFormat()) <= parseInt(closing)) || !forceUpdate) {
          this.isLoadingRecharges = true;
          this.source.empty().catch();
          this.locationInfo.getRechargeProducts(forceUpdate, this.firstTime).subscribe(
            data => {
              this.recharges = data;
              for (let recharge = 0; recharge < data.length; recharge++) {
                this.source.add({
                  name: data[recharge].coins + ' Ares',
                  price: (data[recharge].cost[0].amountCents / 100) + ' ' + data[recharge].cost[0].isoCode
                }).catch();
              }
              this.source.refresh();
              this.firstTime = false;
              this.isLoadingRecharges = false;
            },
            error => {
              this.firstTime = false;
              this.isLoadingRecharges = false;
            }
          );
          this.firstTime = false;
          this.isLoadingRecharges = false;
        } else {
          this.isLoadingRecharges = false;
          this.Notificate(
            'error',
            'Error',
            'Acción no permitida en horario laboral');
        }
      },
      error =>{
        this.isLoadingRecharges = false;
      }
    );
  }

  ngOnInit(): void {
    this.source = new LocalDataSource();
    this.getRecharges();
  }

  nowMilitarFormat() {
    let now = new Date();
   return ('0' + now.getHours()).slice(-2) +
    ('0' + now.getMinutes()).slice(-2);
  }

  public Notificate(notificationType: string, title: string, body: string) {
    this.notificationsService['html'](this.CustomHTMLNotificationGenerator(notificationType, title, body), notificationType,
      {
        showProgressBar: false,
        pauseOnHover: false,
        clickToClose: true
      }
    );
  }

  private CustomHTMLNotificationGenerator(notificationType: string, title: string, body: string): string {
    return '<section class="alert ' + notificationType + '">' +
      '<h1>' + title + '</h1>' +
      '<p>' + body + '</p>' +
      '<div>CERRAR</div>' +
      '</section>';
  }
}
