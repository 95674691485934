import { Inject, Injectable, Optional } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType } from '@angular/http';
import { ResponsesService } from './Responses.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { EnvironmentURL } from './Config';
import * as Model from '../models/Model';
import { JWTHandler } from './JWT.handler';

@Injectable()
export class ZonesService {
  protected basePath;
  public defaultHeaders: Headers = new Headers();
  

  constructor(
    private responsesService: ResponsesService,
    protected http: Http
  ) {
    let urlProvider = new EnvironmentURL();
    this.basePath = urlProvider.GetDomainURL();
    
  }

  public deleteZones(id: string): Observable<Model.Zone> {
    return this.deleteZonesWithHttpInfo(id)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Zona eliminada.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public getZones(): Observable<Model.Zone[]> {
    return this.getZonesWithHttpInfo()
      .map((response: Response) => {
        return this.responsesService.GenericGetResponse(response).json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericGetResponse(error));
      });
  }

  public postZones(zoneBody: string): Observable<Model.Zone> {
    return this.postZonesWithHttpInfo(zoneBody)
      .map((response: Response) => {
        return this.responsesService.GenericPostResponse(response, 'Éxito', 'Zona creada.').json();
      })
      .catch((error: any) => {
        error._body = JSON.parse(error._body).error;
        return Observable.throw(this.responsesService.GenericPostResponse(error));
      });
  }

  public deleteZonesWithHttpInfo(id: string): Observable<Response> {
    const path = this.basePath + "/zones/" + id + "";

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Delete,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  public getZonesWithHttpInfo(): Observable<Response> {
    const path = this.basePath + `/zones`;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }

  public postZonesWithHttpInfo(zoneName: string): Observable<Response> {

    let zone: Model.Zone = <Model.Zone>{};

    const path = this.basePath + `/zones`;

    zone.name = zoneName;

    const queryParameters = new URLSearchParams();
    const headers = new Headers(this.defaultHeaders.toJSON());

    headers.set('Authorization', 'JWT ' + JWTHandler.generateJWT());
    headers.set('Content-Type', 'application/json');

    const requestOptions: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Post,
      headers: headers,
      body: zone,
      search: queryParameters
    });

    return this.http.request(path, requestOptions);
  }
}
