import { MatTableDataSource } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import * as Model from '../models/Model';
import { GamesBySystemService } from '../services/gamesBySystem.service';
import { GameSystemBodyTable } from '../models/Model';

@Component({
  selector: 'app-game-by-system-create',
  templateUrl: './game-by-system-create.component.html',
  styleUrls: ['./game-by-system-create.component.scss']
})
export class GameBySystemCreateComponent implements OnInit {
  public systemList: MatTableDataSource<GameSystemBodyTable>;

  private onReadySystemIds: number[];
  private onNotReadySystemIds: number[];

  private _systems: Model.GameSystemBodyTable[];
  public get systems(): Model.GameSystemBodyTable[] {
    return this._systems;
  }
  public set systems(value: Model.GameSystemBodyTable[]) {
    this._systems = new Array<Model.GameSystemBodyTable>();
    this.gamesBySystemService.getSystems().subscribe(
      (data: Model.System[]) => {
        if (data !== undefined || data !== null) {
          this.onReadySystemIds = [];
          this.onNotReadySystemIds = [];
          if (this.game.gameSystems) {
            this.onReadySystemIds = this.game.gameSystems.map((gameSystem: Model.GameSystem) => {
              this._systems.push(new Model.GameSystemBodyTable(gameSystem, true));
              return gameSystem.system.id;
            });
          }
          data.forEach((system: Model.System) => {
            if (!this.onReadySystemIds.includes(system.id)) {
              this.onNotReadySystemIds.push(system.id);
              this._systems.push(new Model.GameSystemBodyTable(system, false));
            }
          });
          this.systemList = new MatTableDataSource(this._systems);
        }
      });
  }

  public displayedColumns: string[] = ['active', 'platform', 'stock', 'pathToLauncher', 'highlight'];

  constructor(
    private gamesBySystemService: GamesBySystemService,
    public dialogRef: MatDialogRef<GameBySystemCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public game: Model.Game
  ) { }

  public assingGameSystems(): void {
    let activeGameSystems: any[];
    activeGameSystems = this.systemList.data.filter((gameSystem: Model.GameSystemBodyTable) => {
      return gameSystem.isActive;
    }).map((gameSystem: Model.GameSystemBodyTable) => {
      return {
        systemId: gameSystem.systemId,
        stock: (gameSystem.isOnHardDrive) ? 0 : gameSystem.stock,
        isHighlight: gameSystem.isHighlight,
        isOnHardDrive: gameSystem.isOnHardDrive,
        pathToLauncher: gameSystem.pathToLauncher
      };
    });

    const op: any[] = [];

    const activeGameSystemIds: number[] = [];

    activeGameSystems.forEach(active => {
      activeGameSystemIds.push(active.systemId);
      if (this.onReadySystemIds.includes(active.systemId)) {
        op.push({
          op: 'replace',
          path: active.systemId,
          value: active
        });
      } else if (this.onNotReadySystemIds.includes(active.systemId)) {
        op.push({
          op: 'add',
          path: active.systemId,
          value: active
        });
      }
    });
    this.onReadySystemIds.forEach((availableSystemId) => {
      if (!activeGameSystemIds.includes(availableSystemId)) {
        const removedSystem = this.systems.find((systemToFind) => {
          return systemToFind.systemId === availableSystemId;
        });
        op.push({
          op: 'remove',
          path: removedSystem.systemId,
          value: removedSystem
        });
      }
    });
    this.gamesBySystemService.putGameBySystem(this.game.id, op).subscribe(
      (data: Model.GameSystem[]) => {
        this.game.gameSystems = data;
        this.dialogRef.close(this.game);
      },
      error => {
        this.dialogRef.close();
      }
    );
  }

  public ngOnInit() {
    this.systems = [];
  }

}
